import React, { useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import RoomExtra from "./RoomExtra";
import { FiChevronDown } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import devices, { sliderData } from "../data";

import bed from "../images/bed.svg";
import SecureLS from "secure-ls";
import { useSelector } from "react-redux";

const ls = new SecureLS();

export default function RoomItem({ setShowSummary, oneRoom }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [t, i18next] = useTranslation("common");
  const hotelData = useSelector((state) => state);

  let dataOneHotel = {};
  const findImageRoom = () => {
    dataOneHotel = hotelData.hotel.room_groups.find(
      (room) =>
        room.name_struct.main_name == oneRoom[0].room_data_trans.main_name
    );
  };
  findImageRoom();
  const changeIndexHandler = (mode) => {
    if (mode === "prev") {
      if (activeIndex > 0) {
        setActiveIndex(activeIndex - 1);
      }
    } else {
      if (activeIndex < dataOneHotel.images.length - 1) {
        setActiveIndex(activeIndex + 1);
      }
    }
  };
  return (
    <div className="room-item mt-4">
      <div className="title">
        <div>
          <img src={bed} alt="bed" />
          <h3>
            {oneRoom[0].room_data_trans.bedding_type
              ? oneRoom[0].room_data_trans.bedding_type
              : t("missingData", {
                framework: "React",
              })}{" "}
          </h3>
        </div>
        <h3>{oneRoom[0].room_name}</h3>
      </div>
      <div className="row">
        <div
          className={ls.get("lang") == "he" ? "col-5 mt-2" : "col-5 me-2 mt-2 "}
        >
          <div className="room-images">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators"></div>
              <div className="carousel-inner">
                {dataOneHotel.images
                  ? dataOneHotel.images.map((image, index) => (
                    <div
                      key={`carImagesRoom-${index}`}
                      className={
                        activeIndex === index
                          ? "carousel-item active"
                          : "carousel-item"
                      }
                    >
                      <img
                        src={
                          image.replace("{size}", "640x400")
                        }
                        className="d-block w-100"
                        alt="img 1"
                      />
                    </div>
                  ))
                  : sliderData.map((image, index) => (
                    <div
                      key={`carImagesRoomNo-${index}`}
                      className={
                        activeIndex === index
                          ? "carousel-item active"
                          : "carousel-item"
                      }
                    >
                      <img
                        src={image.img}
                        className="d-block w-100"
                        alt="Room photo"
                      />
                    </div>
                  ))}
              </div>
              {activeIndex > 0 && (
                <button
                  className="carousel-control-prev"
                  onClick={() => changeIndexHandler("prev")}
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
              )}
              {activeIndex < dataOneHotel.images.length - 1 && (
                <button
                  className="carousel-control-next"
                  onClick={() => changeIndexHandler("next")}
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col room-devices">
          <h3>{t("rooms.hotelFacilities.title", { framework: "React" })}</h3>
          <div className="devices-grid row mt-2">
            {oneRoom[0].amenities_data.map((device, index) => (
              <div key={`${index}-id-device`} className="col-3 mt-2">
                {/* <img src={device.icon} alt="" className={"me-1 ms-1"} /> */}
                <h4 className="me-2">{device}</h4>
              </div>
            ))}
          </div>
        </div>
      </div>

      {!showAll &&
        oneRoom.map((room, index) =>
          index <= 2 ? (
            <RoomExtra
              key={`oneRoom-${index}`}
              inSale={true}
              setShowSummary={setShowSummary}
              room={room}
            />
          ) : null
        )}
      {showAll && oneRoom.length > 3 && (
        <>
          {oneRoom.map((room, index) => (
            <RoomExtra
              key={`oneRoomAll-${index}`}
              inSale={true}
              setShowSummary={setShowSummary}
              room={room}
            />
          ))}
        </>
      )}
      {oneRoom.length >= 4 && (
        <div className="more-rooms" onClick={() => setShowAll(!showAll)}>
          <h4>
            {!showAll
              ? t("rooms.hotelFacilities.showMore.open", { framework: "React" })
              : t("rooms.hotelFacilities.showMore.close", {
                framework: "React",
              })}
          </h4>
          {showAll ? (
            <FiChevronUp size="24" color="#085F7B" />
          ) : (
            <FiChevronDown size="24" color="#085F7B" />
          )}
        </div>
      )}
    </div>
  );
}
