import React from "react";
import SwitchIcon from "./SwitchIcon";
import { FiChevronDown } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";

export default function LocationAccordion({
  children,
  title,
  isOpen,
  setIsOpen,
}) {
  const contentClass = isOpen
    ? "content accordion-collapse show"
    : " accordion-collapse content collapse";
  const itemClass = isOpen ? "item active" : "item";
  return (
    <div className="location-accordion">
      <div className={itemClass}>
        <SwitchIcon
          value={isOpen}
          setValue={setIsOpen}
          trueIcon={
            <FiChevronUp color="#FFFBFB" size="20" onClick={setIsOpen} />
          }
          falseIcon={
            <FiChevronDown color="#085F7B" size="20" onClick={setIsOpen} />
          }
          title=""
        />
        <h4>{title}</h4>
      </div>
      <div className={contentClass}>{children}</div>
    </div>
  );
}
