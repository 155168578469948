import React, { useState } from "react";
import cn from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-dates/lib/css/_datepicker.css";
import "moment/locale/he";
import { DatePicker } from "antd";
import "antd/dist/antd.css";

import { InputNumber } from "../DataPicker/InputNumber";

import styles from "../DataPicker/styles.module.scss";
import { useSelector } from "react-redux";

export const DatePickerHotel = () => {
  const [t, i18next] = useTranslation("common");
  const data = useSelector((state) => state);

  const onChangeDatePicker = (date, dateString) => {

  };

  return (
    <div className={styles.datePicker}>
      <div className={styles.timeLeftVacationHeader}>
        <h2>
          {t("hotelPage.arrival.title1CheckInOut", { framework: "React" })}
        </h2>
        <div className={cn(styles.displayRows, styles.datePickerChoose)}>
          <DatePicker selected={data.searchParameters.checkin} onChange={onChangeDatePicker} className={""} />
          <div className={styles.arrowInput}>
            <i class="fas fa-arrows-alt-h" style={{ color: '#a54646' }}></i>
          </div>
          <DatePicker onChange={onChangeDatePicker} className={""} />
        </div>
        <h2>
          {t("hotelPage.arrival.titleRoomDetails", { framework: "React" })}
        </h2>
        <div className={styles.displayRows}>
          <InputNumber type={'bed'} />
          <div className={styles.arrowInput}>
            <i class="fas fa-arrows-alt-h" style={{ color: '#a54646' }}></i>
          </div>
          <InputNumber type={'person'} />
        </div>
      </div>
    </div>
  );
};

export default DatePickerHotel;
