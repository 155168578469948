import { HotelPage } from "../../components/HotelPage";

export const HotelScreen = () => {
  return (
    <div className={"hotelPageContainer"}>
      <div className={"container"}>
        <HotelPage />
      </div>
    </div>
  );
};
