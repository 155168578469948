import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OrderInfo from "../components/OrderInfo";
import { FaUserAlt } from "react-icons/fa";
import { useSelector } from "react-redux";

import RoomDetails from "../components/RoomDetails";
import OrderPrice from "../components/OrderPrice";
import HotelMessage from "../components/HotelMessage";
import CouponDiscount from "../components/CouponDiscount";
import CheckoutInfo from "../components/CheckoutInfo";
import HotelArrival from "../components/HotelArrival";

import switchOn from "../images/switch-on.svg";
import switchOff from "../images/switch-off.svg";
import SwitchItem from "../components/SwitchItem";
import SecureLS from "secure-ls";
import { getAllHotels, getOneHotel } from "../actions/hotels";
import { Spin } from "antd";
import { addHotelToOrder } from "../actions/book";

const ls = new SecureLS();

export default function BookScreen() {
  const [accepted, setAccepted] = useState(false);
  const user = useSelector((state) => state.user);
  const [t, i18n] = useTranslation("common");
  const hotelData = useSelector((state) => state);
  const { id } = useParams()


  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true)
    if (!hotelData.hotels.length) {
      await getAllHotels(ls.get('searchParameters'))
    }
    const response = await getOneHotel(
      hotelData.hotel.id
    );

    setLoading(false)

  }
  useEffect(() => {
    if (!hotelData.hotel.name) {
      getData()
      addHotelToOrder(ls.get('rooms'));
    }
  }, [hotelData.hotel.name])
  return (
    <>{!loading && hotelData.hotels.length > 0 ?
      <>
        <div>
          <div className="secondary-nav">
            <div className="container">
              {
                ls.get('lang') == 'he' ?
                  <>

                    <Link className="link" to={`/`}>
                      {t("pagesSite.home", { framework: "React" })}
                    </Link>
                    <p>/</p>
                    <Link className="link" to={`/rooms/${id}`}>
                      {hotelData.hotel.name ? hotelData.hotel.name : t("missingData", {
                        framework: "React",
                      })}
                    </Link>
                    <p>/</p>
                    <Link className="link" to="/filter">
                      {hotelData.searchParameters.query}
                    </Link>
                    <p>/</p>
                   
                    <h5>{t("pagesSite.orderDetails", { framework: "React" })} </h5>
                  </> : <>

                    <Link className="link" to={`/`}>
                      {t("pagesSite.home", { framework: "React" })}
                    </Link>
                    <p>/</p>
                    <Link className="link" to="/filter">
                      {hotelData.searchParameters.query}
                    </Link>
                    <p>/</p>
                    <Link className="link" to={`/rooms/${id}`}>
                      {hotelData.hotel.name ? hotelData.hotel.name : t("missingData", {
                        framework: "React",
                      })}
                    </Link>
                    <p>/</p>
                    <h5>{t("pagesSite.orderDetails", { framework: "React" })} </h5>
                  </>}
            </div>
          </div>
        </div>
        <div className="container mt-2">
          <div className="quick-book">
            <FaUserAlt size="15" color="#C59944" />
            <h4>{t("book.orderDetails.subTitle", { framework: "React" })} </h4>
          </div>
        </div>
        <OrderInfo />
        <div className="container">
          <div className="row mb-5">
            <div className="col-3">
              <RoomDetails />
              <OrderPrice />
              <HotelMessage />
              <CouponDiscount />
            </div>
            <div className="col">
              <CheckoutInfo />
              <HotelArrival />
              <div className="row">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <SwitchItem
                      value={accepted}
                      setValue={setAccepted}
                      trueImg={switchOn}
                      falseImg={switchOff}
                    />
                    <h6 className="accepted-text">{t("book.orderDetails.confirmTerms", { framework: "React" })}
                    </h6>
                  </div>
                </div>
                <div className="col">
                  <button className="to-payment">{t("book.orderDetails.buttonBeyondPayment", { framework: "React" })} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </> : <div style={{ textAlign: "center" }}>
        <Spin />
      </div>}
    </>
  );
}
