import React, { createContext, useState } from "react";

export const RoomContext = createContext();

const RoomContextProvider = (props) => {
  const [rooms, setRooms] = useState(1);

  return (
    <RoomContext.Provider value={{ rooms, setRooms }}>
      {props.children}
    </RoomContext.Provider>
  );
};

export default RoomContextProvider;
