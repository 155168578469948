import React from "react";
import { useTranslation } from "react-i18next";

export default function ReviewRate({ title, subTitle }) {
  const [t, i18next] = useTranslation("common");
  return (
    <div className="review-rate">
      <h5>{title}</h5>
      <div className="review-progress-indicator">
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: "0%" }}
          ></div>
        </div>
        <h6>0</h6>
      </div>
      <h6>
        {t("missingData", {
          framework: "React",
        })}
      </h6>
    </div>
  );
}
