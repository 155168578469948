import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { registerLocale } from "react-datepicker";
import he from "date-fns/locale/he";
import en from "date-fns/locale/en-US";
import SecureLS from "secure-ls";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import "moment/locale/he";
import { useHistory } from "react-router";

import RoomMenu, { SelectAge } from "./RoomMenu";

import store from "../utils/store";
import { actionTypes as hotelsActionTypes } from "../reducers/hotels";

import cancelFilled from "../images/cancel-filled.svg";
import roomIcon from "../images/room.svg";
import adultIcon from "../images/adult.svg";
import kidIcon from "../images/child.svg";
import arrowUp from "../images/grey-arrow-up.svg";
import arrowDown from "../images/grey-arrow-down.svg";

const ls = new SecureLS();
const startInputId = "your_unique_start_date_id";
export default function SearchDetails() {
  const [loader, setLoader] = useState(false);
  const [dateVacation, setDateVacation] = useState({
    startDate: null,
    endDate: null,
    focusedInput: false,
  });

  const [t, i18next] = useTranslation("common");
  const data = useSelector((state) => state);
  const [destination, setDestination] = useState("");
  const [nights, setNights] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [expand, setExpand] = useState(true);
  const [showDate, setShowDate] = useState(false);
  const [handlerError, setHandlerError] = useState({
    errorResidency: {
      status: false,
      idField: null,
    },
    errorRegion: {
      status: false,
      idField: null,
    },
    errorDate: {
      status: false,
      idField: null,
    },
    errorNumberTotalAdults: {
      status: false,
      idField: null,
    },
    errorNumberTotalChildren: {
      status: false,
      idField: null,
    },
    errorNumberRoomAdults: {
      status: false,
      idField: null,
    },
    errorNumberRoomChildren: {
      status: false,
      idField: null,
    },
    errorNumberTotalRooms: {
      status: false,
      idField: null,
    },
    errorSearch: {
      status: false,
      idField: null,
    },
    errorField: {
      status: false,
      idField: null,
    },
  });

  const [searchDetails, setSearchDetails] = useState({
    query: "",
    checkin: moment(dateVacation.startDate).format("YYYY-MM-DD") | "",
    checkout: moment(dateVacation.endDate).format("YYYY-MM-DD") | "",
    language: ls.get("lang") ? ls.get("lang") : "en",
    currency: ls.get("currency").currency ? ls.get("currency").currency : "ILS",
    residency: "vn",
  });

  const [guestsRoomsDetails, setGuestsRoomsDetails] = useState([
    {
      id: 0,
      adults: 2,
      children: [],
    },
  ]);

  const [guests, setGuests] = useState({
    adults: 0,
    children: 0,
  });

  const history = useHistory();

  if (ls.get("lang") == "he" || !ls.get("lang")) {
    moment.locale("he");
  } else {
    moment.locale("en");
  }

  if (ls.get("lang") == "he" || !ls.get("lang")) {
    registerLocale("he", he);
  } else {
    registerLocale("en", en);
  }

  // .toISOString().slice(0, 10)
  const toggleClass = guestsRoomsDetails.length <= 1 ? "disabled" : "active";
  const onDateUpdate = ({ startDate, endDate }) => {
    setDateVacation((prev) => ({
      ...prev,
      startDate: startDate,
      endDate: endDate,
    }));

    setSearchDetails((prev) => ({
      ...prev,
      checkin: moment(startDate).format("YYYY-MM-DD"),
      checkout: moment(endDate).format("YYYY-MM-DD"),
    }));
    let admission = moment(startDate);
    let discharge = moment(endDate);
    const diff = moment.duration(discharge.diff(admission)).asDays();
    setNights(Math.trunc(diff));
  };

  const searchHandler = async () => {
   
    const setCheck = ["query", "checkin", "checkout", "residency"];
    const checkFields = setCheck.find(
      (element) =>
        searchDetails[element] == null || searchDetails[element] == ""
    );
    const checkRooms = guestsRoomsDetails.find(
      (room) => room.adults == 0 && room.children.length == 0
    );

    if (checkFields || checkRooms) {
      setHandlerError((prev) => ({
        ...prev,
        errorField: {
          status: true,
          idField: null,
        },
      }));
    } else {
      guestsRoomsDetails.forEach(function (v) {
        delete v.id;
      });
      ls.set('searchParameters', {
        ...searchDetails,
        guests: guestsRoomsDetails,
      })
      store.dispatch({
        type: hotelsActionTypes.SET,
        payload: [],
      });
      history.push("/filter");
    }
  };

  const addGuestsRooms = (id) => {
    if (guestsRoomsDetails.length < 20) {
      setGuestsRoomsDetails([
        ...guestsRoomsDetails,
        { id: id, adults: 0, children: [] },
      ]);
    } else {
      handlerClientErrors("errorNumberTotalRooms");
    }
  };

  const removeGuestsRooms = () => {
    if (guestsRoomsDetails.length > 1) {
      const newState = [...guestsRoomsDetails];
      newState.pop();
      setGuestsRoomsDetails(newState);
    }
  };

  const addGuestsHandler = (key, id) => {
    switch (key) {
      case "adults":
        {
          if (guests.adults < 20 && guestsRoomsDetails[id].adults <= 5) {
            const newState = [...guestsRoomsDetails];
            const matchingIndex = newState.findIndex((item) => item.id == id);
            if (matchingIndex !== -1) {
              newState[matchingIndex] = {
                ...newState[matchingIndex],
                [key]: newState[matchingIndex][key] + 1,
              };
            }
            setGuestsRoomsDetails(newState);
            setGuests({ [key]: guests[key] + 1, children: guests.children });
          } else {
            if (guests.adults < 20) {
            } else {
              handlerClientErrors("errorNumberTotalAdults");
            }
            if (guestsRoomsDetails.adults <= 6) {
            } else {
              handlerClientErrors("errorNumberRoomAdults", id);
            }
          }
        }
        break;
      case "children":
        {
          if (
            guests.children < 20 &&
            guestsRoomsDetails[id].children.length <= 4
          ) {
            const newState = [...guestsRoomsDetails];
            const matchingIndex = newState.findIndex((item) => item.id == id);
            if (matchingIndex !== -1) {
              newState[matchingIndex] = {
                ...newState[matchingIndex],
                [key]: [...guestsRoomsDetails[matchingIndex][key], 1],
              };
            }
            setGuestsRoomsDetails(newState);
            setGuests({ [key]: guests[key] + 1, adults: guests.adults });
          } else {
            if (guests.children < 20) {
            } else {
              handlerClientErrors("errorNumberTotalChildren");
              console.log(handlerError.errorNumberGuestsChildren);
            }
            if (guestsRoomsDetails[id].children.length < 5) {
            } else {
              handlerClientErrors("errorNumberRoomChildren", id);
            }
          }
        }
        break;
      default:
        console.log("Maximum 20");
    }
  };

  const removeGuestsHandler = (key, id) => {
    if (key == "adults") {
      const newState = [...guestsRoomsDetails];
      const matchingIndex = newState.findIndex((item) => item.id == id);
      if (matchingIndex !== -1 && guestsRoomsDetails[id].adults >= 1) {
        newState[matchingIndex] = {
          ...newState[matchingIndex],
          [key]: newState[matchingIndex][key] - 1,
        };

        setGuestsRoomsDetails(newState);
        setGuests({ [key]: guests[key] - 1, children: guests.children });
      }
    } else {
      const newState = [...guestsRoomsDetails];
      const matchingIndex = newState.findIndex((item) => item.id == id);
      if (matchingIndex !== -1 && guestsRoomsDetails[id].children.length >= 1) {
        const numberChildren = guestsRoomsDetails[matchingIndex][key].slice(
          0,
          -1
        );
        newState[matchingIndex] = {
          ...newState[matchingIndex],
          [key]: numberChildren,
        };
        setGuestsRoomsDetails(newState);

        setGuests({ [key]: guests[key] - 1, adults: guests.adults });
      }
    }
  };

  const handleChangeInputQuery = (e) => {
    setSearchDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handlerClientErrors = (key, id) => {
    setHandlerError((prev) => ({
      ...prev,
      [key]: {
        status: true,
        idField: id,
      },
    }));
  };

  const handlerClientErrorsBeforeRequest = () => {
    const setCheck = ["query", "checkin", "checkout", "residency"];
    const checkFields = setCheck.find(
      (element) =>
        searchDetails[element] == null || searchDetails[element] == ""
    );
    if (checkFields) {
      setHandlerError((prev) => ({
        ...prev,
        errorField: {
          status: true,
          idField: null,
        },
      }));
    }
  };

  const handlerSelectAges = (event, id, idRoom) => {
    const newState = [...guestsRoomsDetails];
    const newAge = [...newState[idRoom]["children"]];
    newAge[id] = event.target.value;
    newState[idRoom].children = newAge;

    // changeAge(newState);
    setGuestsRoomsDetails(newState);
  };

  const changeAge = useCallback(
    (newState) => {
      setGuestsRoomsDetails(newState);
    },
    [guestsRoomsDetails]
  );

  const inputQuery = useRef(null);
  const inputPick = useRef(null);

  useEffect(() => {
    console.log(inputPick);
    inputQuery.current.focus();
  }, []);

  const handleKeyDown = (e) => {
    if (e.key == "Enter") {
      const startInput = document.getElementById(startInputId);
      startInput.focus();
    }
  };

  return (
    <>
      {!loader ? (
        <div className="search-section">
          <div className="container">
            <div className="row">
              <div className="col-7 col-sm-6 ">
                <h3>
                  {t("homePage.formSearch.setGoal", { framework: "React" })}
                </h3>
                <div className="destination-input">
                  <input
                    name={"query"}
                    value={searchDetails.query}
                    onChange={handleChangeInputQuery}
                    type="text"
                    className="destination-dropdown"
                    placeholder={t("homePage.formSearch.placeholder.setGoal", {
                      framework: "React",
                    })}
                    ref={inputQuery}
                    onKeyDown={handleKeyDown}
                  />

                  {destination !== "" && (
                    <img
                      className="destination-cancel"
                      src={cancelFilled}
                      alt="cancel filled"
                      onClick={() => setDestination("")}
                    />
                  )}
                </div>
              </div>
              <div className="col-5">
                <h3>
                  {t("homePage.formSearch.arrivalDeparture", {
                    framework: "React",
                  })}
                </h3>
                <DateRangePicker
                  ref={inputPick}
                  startDate={dateVacation.startDate}
                  startDatePlaceholderText={t(
                    "homePage.formSearch.placeholder.arriveData.start",
                    { framework: "React" }
                  )}
                  startDateId={startInputId}
                  endDate={dateVacation.endDate}
                  endDatePlaceholderText={t(
                    "homePage.formSearch.placeholder.arriveData.end",
                    { framework: "React" }
                  )}
                  endDateId="your_unique_end_date_id"
                  onDatesChange={({ startDate, endDate }) => {
                    onDateUpdate({ startDate, endDate });
                  }}
                  focusedInput={dateVacation.focusedInput}
                  onFocusChange={(focusedInput) => {
                    setDateVacation((prev) => ({
                      ...prev,
                      focusedInput: focusedInput,
                    }));
                  }}
                  isRTL={(ls.get("lang") == "he" || !ls.get("lang")) ? true : false}
                />
              </div>
              <div className="col-1 destination-time">
                <h5>
                  {nights === 0
                    ? ""
                    : nights === 1
                      ? `${nights} ${t("homePage.formSearch.rooms.nights", {
                        framework: "React",
                      })}`
                      : !nights
                        ? ""
                        : `${nights} ${t("homePage.formSearch.rooms.nights", {
                          framework: "React",
                        })}`}
                </h5>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-7 col-sm-8">
                <h3>
                  {t("homePage.formSearch.rooms.title", { framework: "React" })}
                </h3>
                <div className="destination-rooms">
                  <div className="rooms">
                    <img src={roomIcon} alt="room" />
                    <h5 className={"me-2"}>
                      {t("homePage.formSearch.rooms.title", {
                        framework: "React",
                      })}
                    </h5>
                    {guestsRoomsDetails.length <= 1 && (
                      <div
                        className="remove-icon"
                        onClick={removeGuestsRooms}
                      />
                    )}
                    <div>
                      <div className="counter">{guestsRoomsDetails.length}</div>
                      {handlerError.errorNumberTotalRooms.status ? (
                        <p style={{ color: "red", fontSize: "8px", margin: 0 }}>
                          {t("errors.maximumValue", {
                            framework: "React",
                          })}{" "}
                          - 20
                        </p>
                      ) : null}
                    </div>
                    {guestsRoomsDetails.length <= 1 && (
                      <div
                        className="add-icon"
                        onClick={() =>
                          addGuestsRooms(guestsRoomsDetails.length)
                        }
                      />
                    )}
                  </div>
                  <div className="adults">
                    <img src={adultIcon} alt="adult" />
                    <h5 className="me-2">
                      {t("homePage.formSearch.rooms.adults", {
                        framework: "React",
                      })}
                    </h5>
                    {guestsRoomsDetails.length <= 1 && (
                      <div
                        name={"adults"}
                        className="remove-icon"
                        onClick={() => removeGuestsHandler("adults", 0)}
                      />
                    )}
                    <div>
                      <div className="counter">
                        {guestsRoomsDetails.length <= 1
                          ? guestsRoomsDetails[0].adults
                          : guests.adults + 1}
                      </div>
                      {handlerError.errorNumberTotalAdults.status ? (
                        <p style={{ color: "red", fontSize: "8px", margin: 0 }}>
                          {t("errors.maximumValue", {
                            framework: "React",
                          })}{" "}
                          - 20
                        </p>
                      ) : null}
                    </div>
                    {guestsRoomsDetails.length <= 1 && (
                      <div
                        className="add-icon"
                        onClick={() => {
                          if (
                            guestsRoomsDetails.adults === 0 &&
                            guestsRoomsDetails[guestsRoomsDetails.length]
                              .children.length === 0 &&
                            guestsRoomsDetails.length === 0
                          ) {
                            addGuestsRooms(guestsRoomsDetails.length);
                          } else {
                            addGuestsHandler("adults", 0);
                          }
                        }}
                      />
                    )}
                  </div>
                  <div className="kids">
                    <img src={kidIcon} alt="child" />
                    <h5 className={"me-2"}>
                      {t("homePage.formSearch.rooms.children", {
                        framework: "React",
                      })}
                    </h5>
                    {guestsRoomsDetails.length <= 1 && (
                      <div
                        className="remove-icon"
                        onClick={() => removeGuestsHandler("children", 0)}
                      />
                    )}
                    <div>
                      <div className="counter">
                        {guestsRoomsDetails.length <= 1
                          ? guestsRoomsDetails[0].children.length
                          : guests.children}
                      </div>
                      {handlerError.errorNumberTotalChildren.status ? (
                        <p style={{ color: "red", fontSize: "8px", margin: 0 }}>
                          {t("errors.maximumValue", {
                            framework: "React",
                          })}{" "}
                          - 20
                        </p>
                      ) : null}
                    </div>

                    {guestsRoomsDetails.length <= 1 && (
                      <div
                        className="add-icon"
                        onClick={() => {
                          if (
                            guestsRoomsDetails[0].adults === 0 &&
                            guestsRoomsDetails[0].children.length === 0 &&
                            guestsRoomsDetails.length === 0
                          ) {
                            addGuestsRooms(0);
                          } else {
                            addGuestsHandler("children", 0);
                          }
                        }}
                      />
                    )}
                  </div>

                  <div
                    className={
                      ls.get("lang") == "he"
                        ? "dropLeft d-flex"
                        : "dropRight d-flex"
                    }
                  >
                    <img
                      className={toggleClass}
                      src={expand ? arrowUp : arrowDown}
                      alt="gau"
                      onClick={() => setExpand(!expand)}
                    />
                  </div>
                </div>
                {guestsRoomsDetails.length == 1 && (
                  <div className="row mt-2 ages-range">
                    {guestsRoomsDetails[0].children.map((element, index) => (
                      <SelectAge
                        index={index}
                        item={element}
                        handlerSelectAges={handlerSelectAges}
                        room={guestsRoomsDetails[0]}
                      />
                    ))}
                  </div>
                )}
                {guestsRoomsDetails.length !== 0 && (
                  <RoomMenu
                    rooms={guestsRoomsDetails}
                    addRoomHandler={addGuestsRooms}
                    handlerClientErrors={handlerClientErrors}
                    handlerError={handlerError}
                    addVisitorHandler={addGuestsHandler}
                    removeVisitorHandler={removeGuestsHandler}
                    removeRoomHandler={removeGuestsRooms}
                    handlerSelectAges={handlerSelectAges}
                    disabled={guestsRoomsDetails.length > 1 && expand}
                  />
                )}
              </div>
              <div className="col ps-0">
                <button className="col search-btn" onClick={searchHandler}>
                  {t("homePage.formSearch.buttonSearch", {
                    framework: "React",
                  })}
                </button>
                {handlerError.errorSearch.status ? (
                  <p>
                    {t("errors.noDataSearch", {
                      framework: "React",
                    })}{" "}
                  </p>
                ) : null}
                {handlerError.errorField.status ? (
                  <p>
                    {t("errors.allFields", {
                      framework: "React",
                    })}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Spin />
        </div>
      )}
    </>
  );
}
