import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Progress } from "antd";
import countdown from "countdown";
import SecureLS from "secure-ls";
import { useSelector } from "react-redux";

import iconShare from "../../../sources/images/shareWithFriends.png";

import styles from "../CardTimeLeft/styles.module.scss";
import { useParams } from "react-router-dom";

const setCurrency = {
  USD: "$",
  ILS: "₪",
  EUR: "€",
};

const ls = new SecureLS();

export const TimeLeftVacation = () => {
  const [t, i18next] = useTranslation("common");
  const data = useSelector((state) => state);
  const { id } = useParams();
  const diff = countdown(new Date(data.searchParameters.checkin));

  return (
    <div className={styles.timeLeftVacation}>
      <div className={styles.timeLeftVacationHeader}>
        <h2>{t("hotelPage.timeLeftVacation.title", { framework: "React" })}</h2>
      </div>
      <div className={cn(styles.timeLeftList)}>
        <div
          className={
            "me-2 ms-2  d-flex flex-column justify-content-center align-items-center"
          }
        >
          <Progress
            type="circle"
            percent={diff.seconds}
            format={(percent) => `${percent}`}
            width={72}
            strokeColor={"#085f7b"}
            strokeWidth={3}
          />
          <span>
            {t("hotelPage.timeLeftVacation.seconds", { framework: "React" })}
          </span>
        </div>
        <div
          className={
            "me-2 ms-2  d-flex flex-column justify-content-center align-items-center"
          }
        >
          <Progress
            type="circle"
            percent={diff.minutes}
            format={(percent) => `${percent}`}
            width={72}
            strokeColor={"#085f7b"}
            strokeWidth={3}
          />
          <span>
            {t("hotelPage.timeLeftVacation.minutes", { framework: "React" })}
          </span>
        </div>
        <div
          className={
            "me-2 ms-2  d-flex flex-column justify-content-center align-items-center"
          }
        >
          <Progress
            type="circle"
            percent={diff.hours}
            format={(percent) => `${percent}`}
            width={72}
            strokeColor={"#085f7b"}
            strokeWidth={3}
          />
          <span>
            {t("hotelPage.timeLeftVacation.hours", { framework: "React" })}
          </span>
        </div>
        <div
          className={
            "me-2 ms-2  d-flex flex-column justify-content-center align-items-center"
          }
        >
          <Progress
            type="circle"
            percent={diff.days}
            format={(percent) => `${percent}`}
            width={72}
            strokeColor={"#085f7b"}
            strokeWidth={3}
          />
          <span>
            {t("hotelPage.timeLeftVacation.days", { framework: "React" })}
          </span>
        </div>
        <div
          className={
            "me-2 ms-2 d-flex flex-column justify-content-center align-items-center"
          }
        >
          <Progress
            type="circle"
            percent={diff.months}
            format={(percent) => `${percent}`}
            width={72}
            strokeColor={"#085f7b"}
            strokeWidth={3}
          />
          <span>
            {t("hotelPage.timeLeftVacation.months", { framework: "React" })}
          </span>
        </div>
      </div>
      <div className={cn(styles.displayFLexRow, styles.setFunction)}>
        <div className={cn(styles.viewBlockSpaceBet)}>
          <div>
            {t("hotelPage.timeLeftVacation.freeCancellation", {
              framework: "React",
            })}
          </div>
          <div>
            <a>
              {t("hotelPage.timeLeftVacation.buttonDownloadInvoice", {
                framework: "React",
              })}
            </a>
            <a>
              {t("hotelPage.timeLeftVacation.buttonDownloadOrderConfirmation", {
                framework: "React",
              })}
            </a>
          </div>
        </div>
        <div className={cn(styles.viewBlockCenter)}>
          <div>
            {t("hotelPage.timeLeftVacation.freeSubTitle", {
              framework: "React",
            })}
          </div>
          <button className={styles.buttonPrice}>
            {ls.get("currency").currency
              ? setCurrency[ls.get("currency").currency] +
                Number(
                  data.hotels[id].rates[0].payment_options.payment_types[0]
                    .show_amount
                ).toFixed(0)
              : `${Number(
                  data.hotels[id].rates[0].payment_options.payment_types[0]
                    .show_amount
                ).toFixed(0)} ₪`}
          </button>
        </div>
      </div>
      <div className={cn(styles.displayFLexRow, styles.blockShare)}>
        <a style={{ fontSize: "24px" }}>
          <img src={iconShare} width={35} />
          {/* <i class="fas fa-share-alt"></i> */}
          {t("hotelPage.timeLeftVacation.buttonShareFriends", {
            framework: "React",
          })}
        </a>
        <ul className={cn(styles.displayFLexRow, styles.listIconSocial)}>
          <li style={{ backgroundColor: "#25a334" }}>
            <i class="fab fa-whatsapp"></i>
          </li>
          <li style={{ backgroundColor: "#a6a6a6" }}>
            <i class="fas fa-envelope"></i>
          </li>
          <li style={{ backgroundColor: "red" }}>
            <i class="fab fa-pinterest"></i>
          </li>
          <li style={{ backgroundColor: "#37b1e2" }}>
            <i class="fab fa-twitter"></i>
          </li>
          <li style={{ backgroundColor: "#4b69b1" }}>
            <i class="fab fa-facebook-f"></i>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TimeLeftVacation;
