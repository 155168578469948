import React, { useState } from "react";
import { RiCloseCircleLine } from "react-icons/ri";
import { IoBed } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SecureLS from "secure-ls";

const ls = new SecureLS();

export default function GuestCard({ index, setGuests }) {
  const [t, i18n] = useTranslation("common");
  const [nameValid, setNameValid] = useState(null);
  const [emailValid, setEmailValid] = useState(null);
  const dataHotel = useSelector((state)=>state);

  const checkName = (e) => {
    if (/\(?([A-z]{2})\)?([ ])([A-z]{2})/.test(e.target.value))
      setNameValid(true);
    else setNameValid(false);
  };

  const checkEmail = (e) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value
      )
    )
      setEmailValid(true);
    else setEmailValid(false);
  };

  return (
    <div className="container">
      <div className="float-start">
        <div className="d-flex">
          <h4 onClick={() => setGuests(index - 1)}>{ls.get('lang') == 'he' ? 'הסרה' : 'Remove'}</h4>
          <RiCloseCircleLine
            size="30"
            color="#8E443D"
            onClick={() => setGuests(index - 1)}
          />
        </div>
      </div>
      <div className="clearfix"></div>
      {ls.get('lang') != 'he' ?  <div className="d-flex justify-content-between mt-4">
      
      <h3>Guest Room  {index}</h3>
      <h4 className="text-reset fw-normal"> {dataHotel.book[index-1].room_data_trans.bedding_type
            ? dataHotel.book[index-1].room_data_trans.bedding_type
            : t("missingData", {
                framework: "React",
              })}{" "} </h4>
    </div>:  <div className="d-flex justify-content-between mt-4">
      
      <h3>אורח חדר {index}</h3>
      <h4 className="text-reset fw-normal">{dataHotel.book[index-1].room_data_trans.bedding_type
            ? dataHotel.book[index-1].room_data_trans.bedding_type
            : t("missingData", {
                framework: "React",
              })}{" "}  </h4>
    </div>}
     
      <hr />
      <h3 className="text-start">{dataHotel.book[index-1].room_data_trans.main_room_type}</h3>
      <div className="row">
        <div className="col-5">
          <div
            className={
              nameValid !== null ? (nameValid ? "valid" : "invalid") : ""
            }
          >
            <h6>{ls.get('lang') == 'he' ? 'שם מלא (באנגלית)' : 'Full name'}</h6>
            <input
              type="text"
              placeholder={ls.get('lang') == 'he' ? ' מלא שם' : 'full name'}
              name="fullName"
              onChange={(e) => checkName(e)}
            />
          </div>
        </div>
        <div className="col-5">
          <div
            className={
              emailValid !== null ? (emailValid ? "valid" : "invalid") : ""
            }
          >
            <h6>{ls.get('lang') == 'he' ?'אימייל לשליחת עותק הזמנה (לא חובה)' : 'Email to send an order copy (optional)'}</h6>
            <input
              type="text"
              placeholder={ls.get('lang') == 'he' ?'כתובת אימייל': 'email address'}
              name="email"
              onChange={(e) => checkEmail(e)}
            />
          </div>
        </div>
        <div className="col d-flex align-items-center justify-content-end">
          <IoBed size="80" color="#C59944" />
        </div>
      </div>
      <h6 className="mt- fw-normal d-inline">{ls.get('lang') == 'he' ?
        'הודעות אימייל ישמשו אך ורק לשליחת פרטי ההזמנה. בלי הודעות שיווקיות - ': 'Emails will only be used to send order details. No marketing messages -'}
      </h6>
      <h6 className="d-inline">{ls.get('lang') == 'he' ?'בהתחייבות' : 'In commitment'}</h6>
      <h3>{ls.get('lang') == 'he' ? 'בקשות מיוחדות' : 'Special Requests'}</h3>
      <textarea maxLength="150" className="mt-2" />
      <div className="d-flex align-items-center mt-2">
        <FaCheckCircle size="20" color="#C59944" />
        <h5 className="me-1">{ls.get('lang') == 'he' ?
          'בקשות מיוחדות אינן מובטחות - אך מקום האירוח יעשה כמיטב יכולתו למלא אחרצרכיכם.' : 'Special requests are not guaranteed - but the property will do its best to meet your needs.'}
        </h5>
      </div>
    </div>
  );
}
