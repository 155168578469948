import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SecureLS from "secure-ls";
import i18next from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import FilterDetails from "../components/FilterDetails";
import SwitchItem from "../components/SwitchItem";
import HotelCard from "../components/HotelCard";
import { FaCheckCircle } from "react-icons/fa";
import moment from "moment";
import { Pagination, Spin } from "antd";

/* Images */
import switchOn from "../images/switch-on.svg";
import switchOff from "../images/switch-off.svg";
import SearchDetails from "../components/SearchDetails";
import { dateFormat } from "../utils/helpers";
import { useSelector } from "react-redux";
import HotelCardLTR from "../components/HotelCardLTR";
import { getAllHotels } from "../actions/hotels";

const ls = new SecureLS();

export default function FilterScreen(props) {
  const [loading, setLoading] = useState(false);
  const [t, i18next] = useTranslation("common");
  const [cancelFree, setCancelFree] = useState(false);
  const [payAtArrive, setPayAtArrive] = useState(false);
  const [lowToHigh, setLowToHigh] = useState(false);
  const [HighToLow, setHighToLow] = useState(false);
  const [distanceFromCenter, setDistanceFromCenter] = useState(false);
  const [distanceFromBeach, setDistanceFromBeach] = useState(false);
  const [topChoice, setTopChoice] = useState(false);
  const [guestRate, setGuestRate] = useState(false);
  const [changeDestination, setChnageDestination] = useState(false);
  const [fixSort, setFixSort] = useState(false);
  const data = useSelector((state) => state);

  let totalNumberGuests = 0;
  let totalNumberGuestsAdults = 0;
  let totalNumberGuestsChildren = 0;
  let totalNumberNights = 0;
  const pageSize = 25;
  const [pagination, setPagination] = useState({
    data: data.hotels,
    totalPage: data.hotels.length / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });
  const handleChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    }));
    window.scrollTo(0, 0);
  };

  const checkNumberGuests = () => {
    if (data.searchParameters.guests) {
      data.searchParameters.guests.map((room) => {
        totalNumberGuestsChildren =
          totalNumberGuestsChildren + room.children.length;
        totalNumberGuestsAdults = totalNumberGuestsAdults + room.adults;
        totalNumberGuests =
          totalNumberGuests + room.adults + room.children.length;
      });
    }
    let admission = moment(data.searchParameters.checkin);
    let discharge = moment(data.searchParameters.checkout);
    const diff = moment.duration(discharge.diff(admission)).asDays();
    totalNumberNights = Math.trunc(diff);
  };
  checkNumberGuests();

  if (ls.get("lang") == "he") {
    moment.locale("he");
  } else {
    moment.locale("en");
  }
  const getData = async () => {
    await getAllHotels(
      ls.get('searchParameters')
    );


  }
  useEffect(() => {
    if (!data.hotels.length) {
      setLoading(true)
      getData()
      setLoading(false)
    }
    setPagination(() => ({
      data: data.hotels,
      totalPage: data.hotels.length / pageSize,
      current: 1,
      minIndex: 0,
      maxIndex: pageSize,
    }))
  }, [data.hotels])

  return (
    <>
      <div className="secondary-nav">
        <div className="container">
          <Link className="link" to="/">
            {t("pagesSite.home", { framework: "React" })}
          </Link>
          <p>/</p>
          <Link className="link" to="/">{data.searchParameters.query} </Link>
          <p>/</p>
          <h5>
          {t("pagesSite.searchResult", { framework: "React" })}
          </h5>
        </div>
      </div>
      <div className="mini-hero"></div>
      {changeDestination && <SearchDetails />}

      {!loading && data.hotels.length > 0 ?
        <>
          <div
            className={
              !fixSort ? "filter-search" : "filter-search fix-filter-search"
            }
          >
            <div className="orange-line"></div>

            <div className="container">
              <div className="result-count">
                {/* <img src={checkCircle} alt="check circle" /> */}
                <FaCheckCircle size="32" color="#C59944" />
                <h4 className={"me-1 ms-1"}>
                  {" "}
                  {data.hotels.length}{" "}
                  {t("filter.numberHotels", { framework: "React" })}{" "}
                </h4>
              </div>
              <div className="row mt-3">
                <div className={ls.get("lang") == "he" ? "col-1" : "col-3"}>
                  <h5> {t("filter.filterBy", { framework: "React" })}</h5>
                </div>
                <div
                  className={
                    ls.get("lang") == "he"
                      ? "col-8 your-choice"
                      : "col-6 your-choice"
                  }
                >
                  <div
                    className={
                      ls.get("lang") == "he"
                        ? "float-start w-75"
                        : "float-start w-75"
                    }
                  >
                    <div className={ls.get("lang") == "he" ? "right" : "left"}>
                      <FaCheckCircle size="18" color="#C59944" />
                    </div>

                    <h5>{t("filter.yourChoice", { framework: "React" })} </h5>
                    {ls.get("lang") == "he" ? (
                      <h4>
                        {`(${totalNumberNights})`}לילות:{" "}
                        {moment(data.searchParameters.checkin).format("LL", "he")}
                        {" - "}
                        {moment(data.searchParameters.checkout).format("LL", "he")},
                        עבור {totalNumberGuestsAdults} מבוגרים{" "}
                        {totalNumberGuestsChildren > 0
                          ? ` + ${totalNumberGuestsChildren} ילדים `
                          : null}
                      </h4>
                    ) : (
                      <h4>
                        {`(${totalNumberNights})`} Nights:{" "}
                        {moment(data.searchParameters.checkin).format("LL", "en")}
                        {" - "}
                        {moment(data.searchParameters.checkout).format("LL", "en")},
                        for {totalNumberGuestsAdults} adults{" "}
                        {totalNumberGuestsChildren > 0
                          ? ` + ${totalNumberGuestsChildren} children `
                          : null}
                      </h4>
                    )}
                  </div>
                </div>
                <div
                  className={
                    ls.get("lang") == "he"
                      ? "col-3 your-choice"
                      : "col-3 your-choice"
                  }
                >
                  <button
                    className="change-destination"
                    onClick={() => setChnageDestination(!changeDestination)}
                  >
                    {changeDestination
                      ? `${t("filter.targetChange.title", { framework: "React" })}`
                      : `${t("filter.targetChange.title", { framework: "React" })}`}
                  </button>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <div className="payment">
                    <h5>
                      {t("filter.targetChange.payment.title", {
                        framework: "React",
                      })}
                    </h5>
                    <SwitchItem
                      value={cancelFree}
                      setValue={setCancelFree}
                      title={t("filter.targetChange.payment.freeCancellation", {
                        framework: "React",
                      })}
                      trueImg={switchOn}
                      falseImg={switchOff}
                    />
                    <SwitchItem
                      value={payAtArrive}
                      setValue={setPayAtArrive}
                      title={t("filter.targetChange.payment.uponArrival", {
                        framework: "React",
                      })}
                      trueImg={switchOn}
                      falseImg={switchOff}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="star-rate">
                    <h5>
                      {t("filter.targetChange.starRating.title", {
                        framework: "React",
                      })}
                    </h5>
                    <SwitchItem
                      value={lowToHigh}
                      setValue={setLowToHigh}
                      title={t("filter.targetChange.starRating.starsFromLow", {
                        framework: "React",
                      })}
                      trueImg={switchOn}
                      falseImg={switchOff}
                    />
                    <SwitchItem
                      value={HighToLow}
                      setValue={setHighToLow}
                      title={t("filter.targetChange.starRating.starsFronHigh", {
                        framework: "React",
                      })}
                      trueImg={switchOn}
                      falseImg={switchOff}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="distance">
                    <h5>
                      {t("filter.targetChange.distance.title", {
                        framework: "React",
                      })}
                    </h5>
                    <SwitchItem
                      value={distanceFromCenter}
                      setValue={setDistanceFromCenter}
                      title={t("filter.targetChange.distance.distanceFromCenter", {
                        framework: "React",
                      })}
                      trueImg={switchOn}
                      falseImg={switchOff}
                    />
                    <SwitchItem
                      value={distanceFromBeach}
                      setValue={setDistanceFromBeach}
                      title={t("filter.targetChange.distance.distanceFromBeach", {
                        framework: "React",
                      })}
                      trueImg={switchOn}
                      falseImg={switchOff}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="rating">
                    <h5>
                      {t("filter.targetChange.rating.title", {
                        framework: "React",
                      })}
                    </h5>
                    <SwitchItem
                      value={topChoice}
                      setValue={setTopChoice}
                      title={t("filter.targetChange.rating.outTopPicks", {
                        framework: "React",
                      })}
                      trueImg={switchOn}
                      falseImg={switchOff}
                    />
                    <SwitchItem
                      value={guestRate}
                      setValue={setGuestRate}
                      title={t("filter.targetChange.rating.gueastRating", {
                        framework: "React",
                      })}
                      trueImg={switchOn}
                      falseImg={switchOff}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={"container"}
            id={"scrolledElement"}
          >
            <div className="filter-body">
              <div className="filterSidebar">
                <FilterDetails />
              </div>
              <div className="filterContainer">
                {ls.get("lang") == "he" && pagination.data.length > 0
                  ? pagination.data.map(
                    (item, index) =>
                      index >= pagination.minIndex &&
                      index < pagination.maxIndex && (
                        <HotelCard props={item} index={index} key={`card-${index}`} />
                      )
                  )
                  : pagination.data.map(
                    (item, index) =>
                      index >= pagination.minIndex &&
                      index < pagination.maxIndex && (
                        <HotelCardLTR props={item} index={index}  key={`cardRTL-${index}`}/>
                      )
                  )}
                <div className="clearfix"></div>
                <div style={{ bottom: "0px", margin:'0 0 108px 0'}}>
                {pagination.data.length > 6 && (
                  <Pagination
                    pageSize={pageSize}
                    current={pagination.current}
                    total={pagination.data.length}
                    onChange={handleChange}
                    style={{ bottom: "0px", margin: '0'}}
                    showSizeChanger={false}
                  />
                )}
                </div>
                
              </div>
            </div>
          </div></> : <div style={{ textAlign: "center" }}>
          <Spin />
        </div>}

    </>
  );
}
