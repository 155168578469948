import SecureLS from "secure-ls";

export const actionTypes = {
  SET: "USER_SET",
  UNSET: "USER_UNSET",
};

const ls = new SecureLS();
const init = ls.get("user");

export let initState = {
  name: "",
  id: 0,
};

export function reducer(
  state = init.name ?  init : initState,
  action
) {
  switch (action.type) {
    case actionTypes.SET:
      return { ...action.payload };
    case actionTypes.UNSET:
      return { ...initState };
    default:
      return state;
  }
}
