import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import RoomContextProvider from "./context/RoomContext";
import common_en from "./translations/en/common.json";
import common_heb from "./translations/heb/common.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "he",
  resources: {
    en: {
      common: common_en,
    },
    he: {
      common: common_heb,
    },
    ar: {
      common: common_heb,
    },
    bg: {
      common: common_heb,
    },
    de: {
      common: common_heb,
    },
    es: {
      common: common_heb,
    },
    fr: {
      common: common_heb,
    },
    it: {
      common: common_heb,
    },
    hu: {
      common: common_heb,
    },
    pl: {
      common: common_heb,
    },
    pt: {
      common: common_heb,
    },
    ro: {
      common: common_heb,
    },
    ru: {
      common: common_heb,
    },
    sr: {
      common: common_heb,
    },
    sq: {
      common: common_heb,
    },
    tr: {
      common: common_heb,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <RoomContextProvider>
        <App />
      </RoomContextProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
