import React from "react";
import { useTranslation } from "react-i18next";

import { RiCloseCircleLine } from "react-icons/ri";
import { MdAddCircleOutline } from "react-icons/md";
import { MdRemoveCircleOutline } from "react-icons/md";
import { FaBed } from "react-icons/fa";
import { useHistory } from "react-router";

export default function OrderSummary() {
  const history = useHistory();
  const [t, i18n] = useTranslation("common");
  const goToOrderHandler = () => {
    history.push("/book");
  };

  return (
    <div className="order-summary">
      <div className="container">
        <div className="row">
          <h4>סיכום הזמנה</h4>
        </div>
        <div>
          <div className="item row">
            <div className="col-3">
              <RiCloseCircleLine size="25" color="#B2B2B2" />
              <h6>הוסיפו עוד חדר</h6>
              <div>
                <MdRemoveCircleOutline size="20" color="#232029" />
                <div className="counter">2</div>
                <MdAddCircleOutline size="20" color="#232029" />
              </div>
            </div>
            <div className="col-3">
              <div>
                <FaBed size="25" />
                <h5>מיטה זוגית</h5>
              </div>
            </div>
            <div className="col-3">
              <h5>Standard Double room</h5>
            </div>
            <div className="col">
              <h5>מחיר ללילה ל- 2 אורחים</h5>
              <h3>294 ₪</h3>
            </div>
          </div>
          <div className="item row mt-1">
            <div className="col-3">
              <RiCloseCircleLine size="25" color="#B2B2B2" />
              <h6>הוסיפו עוד חדר</h6>
              <div>
                <MdRemoveCircleOutline size="20" color="#232029" />
                <div className="counter">2</div>
                <MdAddCircleOutline size="20" color="#232029" />
              </div>
            </div>
            <div className="col-3">
              <div>
                <FaBed size="25" />
                <FaBed className="me-1" size="25" />
                <h5>מיטה זוגית</h5>
              </div>
            </div>
            <div className="col-3">
              <h5>Standard Double room</h5>
            </div>
            <div className="col">
              <h5>מחיר ללילה ל- 2 אורחים</h5>
              <h3>226 ₪</h3>
            </div>
          </div>
          <div className="item row mt-1">
            <div className="col-3">
              <RiCloseCircleLine size="25" color="#B2B2B2" />
              <h6>הוסיפו עוד חדר</h6>
              <div>
                <MdRemoveCircleOutline size="20" color="#232029" />
                <div className="counter">2</div>
                <MdAddCircleOutline size="20" color="#232029" />
              </div>
            </div>
            <div className="col-3">
              <div>
                <FaBed size="25" />
                <FaBed className="me-1" size="25" />
                <FaBed className="me-1" size="25" />
                <h5>מיטה זוגית</h5>
              </div>
            </div>
            <div className="col-3">
              <h5>Standard Double room</h5>
            </div>
            <div className="col">
              <h5>מחיר ללילה ל- 2 אורחים</h5>
              <h3>305 ₪</h3>
            </div>
          </div>
        </div>
        <div className="order-total mt-1 row">
          <div className="col-8">
            <h4>אתם בחרתם 3 חדרים</h4>
            <h4 className="me-4">סה"כ לתשלום:</h4>
            <h3>2373 ₪</h3>
            <h2>3373 ₪</h2>
          </div>
          <div className="col">
            <button onClick={goToOrderHandler}>לפרטים ותשלום</button>
          </div>
        </div>
      </div>
    </div>
  );
}
