import React from "react";

export default function SwitchIcon({ value, title, trueIcon, falseIcon }) {
  return (
    <div>
      {value ? trueIcon : falseIcon}
      {title !== "" && <h4>{title}</h4>}
    </div>
  );
}
