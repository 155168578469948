import room25 from "./images/room25.svg";
import television from "./images/television.svg";
import shower from "./images/shower.svg";
import ac from "./images/ac.svg";
import creditCard from "./images/credit_card.svg";
import breakfast from "./images/breakfast.svg";
import coin from "./images/coin.svg";
import parking from "./images/parking.svg";
import babyBed from "./images/baby_bed.svg";
import doubleBed from "./images/double_bed.svg";
import safeBox from "./images/safe_box.svg";
import towels from "./images/towels.svg";
import heater from "./images/heater.svg";
import table from "./images/table.svg";
import telephone from "./images/telephone.svg";
import terrace from "./images/terrace.svg";
import toliet from "./images/toilet.svg";
import wifi from "./images/wifi.svg";
import hairDryer from "./images/hair_dryer.svg";
import miniBar from "./images/mini_bar.svg";
import window from "./images/window.svg";
import privateBath from "./images/private_bath.svg";
import noSmoke from "./images/no_smoke.svg";
import noImages from './sources/images/no_image.jpg';

const items = [
  {
    icon: parking,
    title: "חניה",
    id: 'parking'
    
  },
  {
    icon: wifi,
    title: "אינטרנט חינם",
    id: 'wifi'
  },
  {
    icon: breakfast,
    title: "ארוחת בוקר",
    id: 'meal'
  },
  {
    icon: coin,
    title: "ביטול חינם ",
    id: 'deposit'
  },
  {
    icon: creditCard,
    title: "תשלום במלון",
    id: 'visa'
  },
];

const devices = [
  {
    id: 0,
    icon: room25,
    title: "(2)m 25 חדר",
    titleEn: "Room 24"
  },
  {
    id: 1,
    icon: television,
    title: "טלויזיה",
    titleEn: "Television"
  },
  {
    id: 2,
    icon: shower,
    title: "מקלחת",
    titleEn: "Shower"
  },
  {
    id: 3,
    icon: ac,
    title: "מזגן",
    titleEn: "AC"
  },
  {
    id: 4,
    icon: babyBed,
    title: "מיטת תינוק",
    titleEn: "Baby bed"
  },
  {
    id: 5,
    icon: doubleBed,
    title: "מיטה זוגית",
    titleEn: "Double Bed"
  },
  {
    id: 6,
    icon: safeBox,
    title: "כספת",
    titleEn: "Safe Box"
  },
  {
    id: 7,
    icon: towels,
    title: "מגבות",
    titleEn: "Towels"
  },
  {
    id: 8,
    icon: heater,
    title: "חימום",
    titleEn: "Heater"
  },
  {
    id: 9,
    icon: table,
    title: "שולחן",
    titleEn: "Table"
  },
  {
    id: 10,
    icon: telephone,
    title: "טלפון",
    titleEn: "Telephone"
  },
  {
    id: 11,
    icon: terrace,
    title: "מרפסת",
    titleEn: "Terrace"
  },
  {
    id: 12,
    icon: toliet,
    title: "טואלט",
    titleEn: "Toliet"
  },
  {
    id: 13,
    icon: wifi,
    title: "חינם Wi-Fi",
    titleEn: "wi-fi"
  },
  {
    id: 14,
    icon: hairDryer,
    title: "מייבש שיער",
    titleEn: "Hair Dryer"
  },
  {
    id: 15,
    icon: miniBar,
    title: "מיני בר",
    titleEn: "Mini-Bar"
  },
  {
    id: 16,
    icon: window,
    title: "חלון",
    titleEn: "Window"
  },
  {
    id: 17,
    icon: privateBath,
    title: "מקלחת פרטית",
    titleEn: "Private Bath"
  },
  {
    id: 18,
    icon: noSmoke,
    title: "ללא עישון",
    titleEn: "No-smoke"
  }
];

export const sliderData = [
  {
    title: "Photo 1",
    img: noImages,
    id: 'no-image'
  },
  {
    title: "Photo 2",
    img: noImages,
    id: 'no-image'
  },
  {
    title: "Photo 3",
    img: noImages,
    id: 'no-image'
  }
];

export const typeRooms = ["Double Apartment", "Standard room", "Suite", "Double Suite", "Comfort Double room", "Comfort Single room", "Comfort Single Family room", "Premium Single room", "Standard Single room", "Standard Double room", "Single Suite", "Deluxe Single Suite"]
export const setCurrency = {
  USD: "$",
  ILS: "₪",
  EUR: "€",
};
export default devices;
export { items };
