import React from "react";
import facebook from "../images/facebook.svg";
import gmail from "../images/gmail.svg";
import { useTranslation, initReactI18next } from "react-i18next";

export default function LoginButton({ type }) {
  const [t, i18next] = useTranslation("common");
  return (
    <button className="btn-signin-alt">
      {type === "facebook" && (
        <>
                    <span className="d-block text-align-center w-100">{t("header.signUpForm.connectGoogle", { framework: "React" })}</span>
          <img src={facebook} alt="facebook" />
        </>
      )}
      {type === "gmail" && (
        <>            
         <span className="d-block text-align-center w-100"> {t("header.signUpForm.connectGoogle", { framework: "React" })}</span>
          <img src={gmail} alt="gmail" />
        </>
      )}
    </button>
  );
}
