import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { FaRegClock } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import SecureLS from "secure-ls";
const ls = new SecureLS();

export default function HotelArrival() {
  const [t, i18next] = useTranslation("common");
  const [time, setTime] = useState("12:00");
  const hotelData = useSelector((state)=> state)
  const doTime = (time) => {
    setTime(time);
    setHide(true);
  };
  const [hide, setHide] = useState(true);

  const dropdownClass = hide
    ? "time-input-container-dropdown hide"
    : "time-input-container-dropdown";
  const customArrowClass = hide ? "custom-arrow-down" : "custom-arrow-up";
  
ls.get('lang') == 'he' ? moment.lang('he') : moment.lang('en');
  return (
    <div className="hotel-arrival">
      <div className="title">
        <FaRegClock size="25" color="#085F7B" className={"me-2"}/>
        <h3>{t("book.arrivalExpected.title", { framework: "React" })}
        </h3>
      </div>
      <div className="content">
        <div className="d-flex align-items-center">
          <FaCheckCircle size="18" color="#C59944" />
          <h5>{t("book.arrivalExpected.subTitle", { framework: "React" })}</h5>
        </div>
        <div className="d-flex align-items-center">
          <FaCheckCircle size="18" color="#C59944" />
          {ls.get('lang') =='he'? <>
          <h5 className="fw-bolder">כניסה לחדרים:</h5>
          <h5>{t("book.arrivalExpected.notificationAboutReadyRoom", { framework: "React" })} </h5>
          <h5 className="fw-bolder">  14:00 החל משעה:</h5>
          </>:
          <>
          <h5 className="fw-bolder">Starting from:</h5>
          
          <h5>{t("book.arrivalExpected.notificationAboutReadyRoom", { framework: "React" })} </h5>
          <h5 className="fw-bolder">  Entrance to the rooms: 14:00 </h5></>
          
          }
          
        </div>
        <div className="d-flex align-items-center mt-5">
          <h5 className="fw-bolder">
          {t("book.arrivalExpected.estimatedArrivalTime", { framework: "React" })}: {moment(hotelData.searchParameters.checkin).format('dddd, MMMM DD YYYY, h:mm:ss')}
          </h5>

          <div className="time-input-container">
            <span>{time}</span>
            <button onClick={() => setHide(!hide)}>
              <div className={customArrowClass}></div>
            </button>
            <ul className={dropdownClass}>
              <li onClick={() => doTime("13:00")}>13:00</li>
              <li onClick={() => doTime("14:00")}>14:00</li>
              <li onClick={() => doTime("15:00")}>15:00</li>
              <li onClick={() => doTime("16:00")}>16:00</li>
              <li onClick={() => doTime("17:00")}>17:00</li>
              <li onClick={() => doTime("18:00")}>18:00</li>
              <li onClick={() => doTime("19:00")}>19:00</li>
              <li onClick={() => doTime("20:00")}>20:00</li>
            </ul>
          </div>
          {/* <div className="time-input">
                        <input className="me-3" type="time" value={date} onChange={(e) => setDate(e.target.value)} />
                        <span className="custom-arrow" ></span>
                    </div> */}
        </div>
      </div>
    </div>
  );
}
