import React, { useState } from "react";
import SecureLS from "secure-ls";
import { useTranslation } from "react-i18next";
import { FaBed } from "react-icons/fa";
import { IoIosBed } from "react-icons/io";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { SiTripadvisor } from "react-icons/si";
import { useHistory } from "react-router";
import StarRatings from "react-star-ratings";

import { getOneHotel } from "../actions/hotels";

import Breakfast from "../images/breakfast.svg";
import CancelPayment from "../images/cancel_payment.svg";
import HotelPay from "../images/hotel_pay.svg";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import noImages from "./../sources/images/no_image.jpg";
const setCurrency = {
  USD: "$",
  ILS: "₪",
  EUR: "€",
};
const ls = new SecureLS();

export default function HotelCardLTR({ props, index }) {
  const [t, i18next] = useTranslation("common");
  const [loader, setLoader] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const hotelData = useSelector((state) => state);

  const changeIndexHandler = (mode) => {
    if (mode === "prev") {
      if (activeIndex === 0) {
      } else {
        setActiveIndex(activeIndex - 1);
      }
    } else {
      if (activeIndex < props.rates[0].images.length - 1) {
        setActiveIndex(activeIndex + 1);
      }
    }
  };

  const history = useHistory();

  const showRoomHandler = () => {
    history.push({
      pathname: "/rooms",
      state: {
        destination: "Delphi Art Hotel",
      },
    });
  };
  function formatTitle(input) {
    var words = input.split(" ");
    for (var i = 0; i < words.length; i++) {
      words[i] = words[i].substr(0, 1).toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
  }

  const loadDataOneHotel = async () => {
    ls.set('hotelId', props.id)
    history.push(`/rooms/${index}`);
  };

  return (
    <div className="hotel-card">
      {loader ? (
        <div className={"d-flex justify-content-center"}>
          <Spin />
        </div>
      ) : (
        <>
          <div className="hotel-image">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  className={activeIndex === 2 ? "active" : ""}
                  aria-current="true"
                  aria-label="Slide 1"
                  onClick={() => setActiveIndex(2)}
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  className={activeIndex === 1 ? "active" : ""}
                  aria-label="Slide 2"
                  onClick={() => setActiveIndex(1)}
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  className={activeIndex === 0 ? "active" : ""}
                  aria-label="Slide 3"
                  onClick={() => setActiveIndex(0)}
                ></button>
              </div>
              <div className="carousel-inner">
                {!props.rates[0].images ? (
                  <>
                    <div
                      className={
                        activeIndex === 0
                          ? "carousel-item active"
                          : "carousel-item"
                      }
                    >
                      <img
                        src={noImages}
                        className="d-block w-100"
                        alt="img 1"
                      />
                    </div>
                    <div
                      className={
                        activeIndex === 1
                          ? "carousel-item active"
                          : "carousel-item"
                      }
                    >
                      <img
                        src={noImages}
                        className="d-block w-100"
                        alt="img 1"
                      />
                    </div>
                    <div
                      className={
                        activeIndex === 2
                          ? "carousel-item active"
                          : "carousel-item"
                      }
                    >
                      <img
                        src={noImages}
                        className="d-block w-100"
                        alt="img 1"
                      />
                    </div>
                  </>
                ) : (
                  props.rates[0].images.map((image, index) => (
                    <div
                      className={
                        activeIndex === index
                          ? "carousel-item active"
                          : "carousel-item"
                      }
                    >
                      <img
                        src={image.replace("{size}", "640x400")}
                        className="d-block w-100"
                        alt="img 1"
                      />
                    </div>
                  ))
                )}
              </div>
              {activeIndex > 0 && (
                <button
                  className="carousel-control-prev"
                  onClick={() => changeIndexHandler("prev")}
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
              )}
              {activeIndex < props.rates[0].images.length - 1 && (
                <button
                  className="carousel-control-next"
                  onClick={() => changeIndexHandler("next")}
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              )}
            </div>
            {favorite ? (
              <FaHeart
                size="38"
                color="#CB0808"
                onClick={() => setFavorite(!favorite)}
              />
            ) : (
              <FaRegHeart
                size="38"
                color="#CB0808"
                onClick={() => setFavorite(!favorite)}
              />
            )}
            <div className="bookmark">
              {props.rates[0].star_certificate
                ? props.rates[0].star_certificate
                : '0.0'}
            </div>
          </div>
          <div className="hotel-content">
            <div className="row">
              <div className="col">
                <div className="hotel-item hotel-heading">
                  <div className="row">
                    <div className="col-8 text-start">
                      <StarRatings
                        rating={
                          props.rates[0].star_rating
                            ? props.rates[0].star_rating
                            : 0
                        }
                        starRatedColor="#C59944"
                        numberOfStars={5}
                        starDimension={"16px"}
                        starSpacing={"1px"}
                      />
                      <h3 style={{ color: "#085F7B", fontSize: "25px" }}>
                        {props.rates[0].name
                          ? props.rates[0].name
                          : t("missingData", {
                              framework: "React",
                            })}
                      </h3>
                      <h4>{props.rates[0].address}</h4>
                    </div>
                    <div className="col text-end">
                      <StarRatings
                        rating={
                          props.rates[0].star_certificate
                            ? props.rates[0].star_certificate
                            : 0
                        }
                        starRatedColor="#00aa6c"
                        numberOfStars={5}
                        starDimension={"16px"}
                        starSpacing={"1px"}
                        svgIconPath={
                          "M21.21,0a21.21,21.21,0,1,0,21.2,21.21A21.23,21.23,0,0,0,21.21,0Z"
                        }
                      />
                      <SiTripadvisor size="20" className="me-1 mt-1" />
                      <h6>
                        {t("missingData", {
                          framework: "React",
                        })}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="hotel-item">
                  <div className="row mt-2  align-items-end">
                    <div className="col text-start">
                      <h4>
                        {t("missingData", {
                          framework: "React",
                        })}
                        {/* (309){" "}
                        {t("filter.cardHotel.distanceFromCenter", {
                          framework: "React",
                        })} */}
                      </h4>
                    </div>
                    <div className="col text-end">
                      <a href="/" className="location-map">
                        {t("filter.cardHotel.showMap", { framework: "React" })}
                      </a>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                    <IoIosBed style={{fontSize: '24px'}}/>
                      <h4 className="d-inline me-1">
                        {" "}
                        {props.rates[0].room_data_trans.main_name}
                      </h4>
                    </div>
                    {/* <div className="col-8 text-end">
                      <h4>Price per night for 2 guests</h4>
                    </div> */}
                  </div>
                  <hr className="hotel-seperator" />
                  <div className="row m-0 mt-3">
                    <div className="col ps-0">
                      <ul>
                      {props.rates[0].meal &&
                        !props.rates.find((room)=> room.meal == "nomeal")  ? (
                          <li>
                            <img src={Breakfast} alt="" />
                            {t("filter.cardHotel.breakfast", {
                              framework: "React",
                            })}
                          </li>
                        ) : <li>
                        <img src={Breakfast} alt="" />
                        {t("filter.cardHotel.roomOnly", {
                          framework: "React",
                        })}
                      </li>}
                        {props.rates[0].payment_options.payment_types[0]
                          .cancellation_penalties.free_cancellation_before ? (
                          <li>
                            <img src={CancelPayment} alt="" />
                            {t("filter.cardHotel.freeCancellation", {
                              framework: "React",
                            })}
                            {/* {props.rates[0].payment_options.payment_types[0].cancellation_penalties.free_cancellation_before} */}
                          </li>
                        ) : null}
                        {props.rates[0].payment_options.payment_types[0]
                          .type ? (
                          <li>
                            <img src={HotelPay} alt="" />
                            {t("filter.cardHotel.payment", {
                              framework: "React",
                            })}{" "}
                            {props.rates[0].payment_options.payment_types[0]
                              .type == "deposit"
                              ? "deposit"
                              : ""}
                          </li>
                        ) : null}
                      </ul>
                    </div>
                    <div className="col-5 hotel-extras p-0 text-end">
                      <h2>
                        <span>
                          {t("filter.cardHotel.from", {
                            framework: "React",
                          })}{" "}
                          -{" "}
                        </span>{" "}
                        {Number(
                          hotelData.hotels[index].rates[0].payment_options
                            .payment_types[0].show_amount
                        ).toFixed(0)}
                        {' '}
                        {setCurrency[ls.get("currency").currency]
                          ? setCurrency[ls.get("currency").currency]
                          : "  ₪  "}
                      </h2>
                      <button onClick={loadDataOneHotel}>
                        {" "}
                        {t("filter.cardHotel.btnShowRooms", {
                          framework: "React",
                        })}
                      </button>
                    </div>
                  </div>
                </div>
                <hr className="hotel-seperator" />
                <div>
                  {hotelData.hotels[index].breakfastAndCancellation ? (
                    <p>
                      {t("filter.cardHotel.bottomDescription", {
                        framework: "React",
                      })}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
