import { indexOf } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import icon from "../images/p_space_white.svg";
import GuestCard from "./GuestCard";
import MainGuestCard from "./MainGuestCard";

export default function CheckoutInfo() {
  const [guests, setGuests] = useState(1);

  const [t, i18n] = useTranslation("common");
  const hotelData = useSelector((state) => state);
  const [items, setItems] = useState([]);

  useEffect(() => {
    let tempItems = [];
    for (let i = 0; i < guests; i++) {
      tempItems.push(hotelData.book[i]);
    }
    setItems(tempItems);
  }, [guests]);

  return (
    <div className="checkout-info">
      <div className="title">
        <img src={icon} alt="icon" className={"me-2"} />
        <h4>{t("book.title", { framework: "React" })}</h4>
      </div>
      <div className="content">
        <div className="order-form mt-5">
          {items.map((item, index) => {
            if (index === 0) {
              return <MainGuestCard key={index} index={index} />;
            } else {
              return (
                <GuestCard key={index} index={index+1} setGuests={setGuests} />
              );
            }
          })}
          {items.length < hotelData.book.length && (
            <div className="container">
              <button
                className="add-guest"
                onClick={() => {
                  setGuests(guests + 1);
                }}
              >
                {t("book.orderDetails.guestCard.buttonRegisterAnotherGuest", {
                  framework: "React",
                })}
              </button>
            </div>
          )}
          {/* <MainGuestCard />
                    <GuestCard index="2" title="שתי מיטות" subTitle="Standard Double room" />
                    <GuestCard index="3" title="שלוש מיטות" subTitle="Standard Triple room" /> */}
        </div>
      </div>
    </div>
  );
}
