import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import bluePayment from "../images/blue_payment.svg";
import CurrencyInput from "react-currency-input-field";
import SecureLS from "secure-ls";
import { useSelector } from "react-redux";

const ls = new SecureLS();
export default function OrderPrice() {
  const [t, i18n] = useTranslation("common");
  const hotelData = useSelector((state) => state);
  const [orderSummary, setOrderSummary] = useState(0);
  let summ = 0;
  const getOrderSummary = () => {
    hotelData.book.map((room) => {
      summ = summ + room.payment_options.payment_types[0].show_amount;
    });

    setOrderSummary(summ);
  };
  useEffect(() => {
    getOrderSummary();
  }, []);
  const [amount /*, setAmount*/] = useState(100);

  return (
    <div className="order-price mt-4">
      <div className="title">
        <img src={bluePayment} alt="Blue Payment" className={"me-2"} />
        <h3>
          {" "}
          {t("book.orderDetails.orderSummary.title", { framework: "React" })}
        </h3>
      </div>
      <div className="content">
        <div className="item">
          <h5>
            {t("book.orderDetails.orderSummary.numberRooms", {
              framework: "React",
            })}{" "}
          </h5>
          <div className="counter">{hotelData.book.length}</div>
        </div>
        {ls.get("lang") == "he" ? <h5>לתשלום</h5> : <h5>For a fee</h5>}
        <button>{ls.get('lang') == 'he' ? 'מחיר': 'Price'} {' '}
           {orderSummary}{" "}
          {t("book.orderDetails.orderSummary.price", { framework: "React" })}{" "}
        </button>
        <h6 className="fw-normal text-start">
          {ls.get("lang") == "he" ? "(במטבע שלכם)" : "(In your currency)"}
        </h6>
        <div className="conversion mt-3">
          <h5>
            {t("book.orderDetails.orderSummary.conversationToLocalCurrency", {
              framework: "React",
            })}{" "}
          </h5>
          <div className={"currency-input mt-2"}>
            <CurrencyInput prefix="$" value={amount} disabled={true} />
            <select
              style={
                ls.get("lang") == "he" ? {} : { backgroundPosition: "75% 50%" }
              }
            >
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
            </select>
          </div>
          <h5 className="fw-bold mt-4">
            {t("book.orderDetails.orderSummary.paymentUpon.title", {
              framework: "React",
            })}
          </h5>
          <div>
            <h5 className="text-reset">
              {hotelData.book[0].payment_options.payment_types[0].tax_data.taxes ? hotelData.book[0].payment_options.payment_types[0].tax_data.taxes :

              t("missingData", {
                framework: "React",
              })
            }</h5>
            {/* <h5 className="text-reset">
              {" "}
              {t("missingData", {
                framework: "React",
              })}
            </h5> */}
          </div>
          <hr />
          <h6 className="fw-bold">
            {t("book.orderDetails.orderSummary.paymentUpon.note", {
              framework: "React",
            })}
            :{" "}
          </h6>
          <h6>
            {t("book.orderDetails.orderSummary.paymentUpon.subTitle", {
              framework: "React",
            })}
          </h6>
        </div>
      </div>
    </div>
  );
}
