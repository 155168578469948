import { combineReducers } from "redux";
import * as userReducer from "./user";
import * as hotelsReducer from "./hotels";
import * as hotelReducer from "./hotel";
import * as searchReducer from "./search";
import * as bookReducer from "./book";

export default combineReducers({
  user: userReducer.reducer,
  hotels: hotelsReducer.reducer,
  hotel: hotelReducer.reducer,
  searchParameters: searchReducer.reducer,
  book: bookReducer.reducer
});
