import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SecureLS from "secure-ls";
import { FaBed } from "react-icons/fa";
import hotel1 from "../images/hotel1.jpg";
import hotel2 from "../images/hotel2.jpg";
import hotel3 from "../images/hotel3.jpg";
import { useSelector } from "react-redux";
import moment from "moment";
import { sliderData } from "../data";

const ls = new SecureLS();

export default function RoomDetails() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [t, i18next] = useTranslation("common");
  const hotelData = useSelector((state) => state);
  let dataOneHotel = []
  const changeIndexHandler = (mode) => {
    if (mode === "prev") {
      if (activeIndex > 0) {
        setActiveIndex(activeIndex - 1);
      }
    } else {
      if (activeIndex < dataOneHotel.images.length - 1) {
        setActiveIndex(activeIndex + 1);
      }
    }
  };

  const [totalNumberGuests, setTotalNumberGuest] = useState({
    adults: 0,
    children: 0,
  });
  const [totalNumberNights, setTotalNumberNights] = useState(0);
  const [showSummary, setShowSummary] = useState(false);
  const checkNumberGuests = () => {
    if (hotelData.searchParameters.guests) {
      hotelData.searchParameters.guests.map((room) => {
        setTotalNumberGuest({
          adults: room.adults,
          children: room.children.length,
        });
      });
    }
    let admission = moment(hotelData.searchParameters.checkin);
    let discharge = moment(hotelData.searchParameters.checkout);
    const diff = moment.duration(discharge.diff(admission)).asDays();
    setTotalNumberNights(Math.trunc(diff));
  };
  useEffect(() => {
    checkNumberGuests();
   
  }, []);
 
  const findImageRoom = () => {
    dataOneHotel = hotelData.hotel.room_groups.find(
      (room) =>
        room.name_struct.main_name == hotelData.book[0].room_data_trans.main_name
    );
  };
 
  findImageRoom();
  return (
    <div className="room-details">
      <div className="title">
        <FaBed size="24" color="#085F7B" className={"me-2"} />
        <h3>
          {t("book.orderDetails.roomDetails.title", { framework: "React" })}{" "}
        </h3>
      </div>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {dataOneHotel.images
            ? dataOneHotel.images.map((image, index) => (
                <div
                  className={
                    activeIndex === index
                      ? "carousel-item active"
                      : "carousel-item"
                  }
                >
                  <img
                    src={image.replace("{size}", "640x400")}
                    className="d-block w-100"
                    alt="img 1"
                  />
                </div>
              ))
            : sliderData.map((image, index) => (
                <div
                  className={
                    activeIndex === index
                      ? "carousel-item active"
                      : "carousel-item"
                  }
                >
                  <img src={image.img} className="d-block w-100" alt="img 1" />
                </div>
              ))}
        </div>
        {activeIndex > 0 && (
          <button
            className="carousel-control-prev"
            onClick={() => changeIndexHandler("prev")}
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
        )}
        {activeIndex < dataOneHotel.images.length - 1 && (
          <button
            className="carousel-control-next"
            onClick={() => changeIndexHandler("next")}
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        )}
      </div>
      <div className="content">
        <h4>
          {t("book.orderDetails.roomDetails.yourChoice", {
            framework: "React",
          })}
        </h4>
        <hr />
        {hotelData.book.map((room) => (
          <div className="item">
            <div>
              <h6>
                {room.room_data_trans.bedding_type
                  ? room.room_data_trans.bedding_type
                  : t("missingData", {
                      framework: "React",
                    })}{" "}
              </h6>
              <h5>
                {room.room_data_trans.main_room_type
                  ? room.room_data_trans.main_room_type
                  : t("missingData", {
                      framework: "React",
                    })}
              </h5>
            </div>
            <div className="counter">1</div>
          </div>
        ))}
        <div className="item">
          <h5> {ls.get("lang") == "he" ? "מספר לילות" : "Number of nights"}</h5>
          <div className="counter">{totalNumberNights}</div>
        </div>
        <h4>
          {t("book.orderDetails.roomDetails.guests", { framework: "React" })}
        </h4>
        <hr />
        <div className="item">
          <h5>{ls.get("lang") == "he" ? "מבוגרים" : "Adults"}</h5>
          <div className="counter">{totalNumberGuests.adults}</div>
        </div>
        <div className="item">
          <h5>{ls.get("lang") == "he" ? "ילדים" : "Children"}</h5>
          <div className="counter">{totalNumberGuests.children}</div>
        </div>
      </div>
    </div>
  );
}
