import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import SecureLS from "secure-ls";

import coupon from "../images/coupon.svg";
import download from "../images/download.svg";
const ls = new SecureLS();
export default function CouponDiscount() {
  const [t, i18n] = useTranslation("common");
  const [isOpen, setIsOpen] = useState(false);
  const contentVisible = isOpen ? "content" : "content disabled";

  return (
    <div className="coupon-discount mt-4">
      <div className="title">
        <div>
          <img src={coupon} alt="coupon" className={"me-2"} />
          <h3>
            {t("book.orderDetails.discountCoupon.title", {
              framework: "React",
            })}{" "}
          </h3>
        </div>
        {isOpen ? (
          <FaChevronUp
            size="24"
            color="#085F7B"
            onClick={() => setIsOpen(false)}
          />
        ) : (
          <FaChevronDown
            size="24"
            color="#085F7B"
            onClick={() => setIsOpen(true)}
          />
        )}
      </div>
      <div className={contentVisible}>
        <h6>
          {" "}
          {t("missingData", {
            framework: "React",
          })}{" "}
        </h6>
        <div className={ls.get("lang") == "he" ? 'coupon-field': 'coupon-field placeholrerUniq'}>
          <img src={download} alt="coupon" />
          <input
            type="text"
            placeholder={
              ls.get("lang") == "he" ? "הכנס קופון" : "Insert coupon"
            }
            maxLength="8"
           
          />
        </div>
      </div>
    </div>
  );
}
