import { act } from "react-dom/cjs/react-dom-test-utils.production.min";
import SecureLS from "secure-ls";

export const actionTypes = {
  SET: "HOTELS_SET",
  UNSET: "HOTELS_UNSET",
};

const ls = new SecureLS();

export let initState = [

];

export function reducer(state = initState, action) {

  switch (action.type) {
    case actionTypes.SET:
      return [...action.payload];
    case actionTypes.UNSET:
      return { ...initState };
    default:
      return state;
  }
}
