import React, { useState } from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import Recaptcha from "react-recaptcha";
import { Link } from "react-router-dom";

import LoginButton from "./LoginButton";
import LoginToggle from "./LoginToggle";
import SwitchItem from "./SwitchItem";
import TextField from "./TextField";
import { signInAgent, signInUser, signUpUser } from "../actions/user";

import switchOn from "../images/switch-on.svg";
import switchOff from "../images/switch-off.svg";
import { Space, Spin } from "antd";
import SecureLS from "secure-ls";

const ls = new SecureLS();
const setFieldsSignUpClient = ["fullName", "email", "password", "phone"];
const setFieldsSigninClient = ["email", "password", "phone"];
const setFieldsSigninAgent = ["username", "password", "phone"];

export default function LoginForm() {
  const [t, i18next] = useTranslation("common");
  const [loader, setLoader] = useState(false);
  const [isSignUpMode, setIsSignUpMode] = useState(true);
  const [role, setRole] = useState(true);
  const [remenberMe, setRemenberMe] = useState(false);

  const [submit, setSubmit] = useState(false);
  const [user, setUser] = useState({
    email: ls.get('remmemberMeUser').email ? ls.get('remmemberMeUser').email : "",
    password: ls.get('remmemberMeUser').password ? ls.get('remmemberMeUser').password : "",
    "g-recaptcha-response": null,
  });

  const [agent, setAgent] = useState({
    username: ls.get('remmemberMeAgent').name ? ls.get('remmemberMeAgent').password : "",
    password: ls.get('remmemberMeAgent').name ? ls.get('remmemberMeAgent').password : "",
    "g-recaptcha-response": null,
  });

  const [userSignup, setUserSignup] = useState({
    fullName: "",
    email: "",
    password: "",
    phoneNumber: "",
    termsOfUse: true,
    mailingApproval: false,
  });
  const [handlerError, setHandlerError] = useState({
    errorField: {
      status: false,
      idField: null,
    },
    errorCheckbox: {
      status: false,
      idField: null,
    },
  });
  const handlerClientErrors = (key, id) => {
    setHandlerError((prev) => ({
      ...prev,
      [key]: {
        status: !handlerError[key].status,
        idField: id,
      },
    }));
  };

  const handlerErrors = (type) => {
    switch (type) {
      case "agent":
        {
          setFieldsSigninAgent.map((element) => {
            if (agent[element] === "") {
              console.log("All fields must be filled");
              setHandlerError((prev) => ({
                ...prev,
                [element]: !handlerError.errorField.status,
                idField: type,
              }));
            } else {
              if (!handlerError.errorField.status) {
                setSubmit(true);
              }
            }
          });
        }
        break;

      case "client":
        {
          setFieldsSigninClient.map((element) => {
            if (user[element] === "") {
              console.log("All fields must be filled");
              setHandlerError((prev) => ({
                ...prev,
                errorField: {
                  status: !handlerError.errorField.status,
                  idField: type,
                },
              }));
            } else {
              if (!handlerError.errorField.status) {
                setSubmit(true);
              }
            }
          });
        }
        break;

      case "signupClient":
        {
          setFieldsSignUpClient.map((element) => {
            if (userSignup[element] === "") {
              console.log("All fields must be filled");
              setHandlerError((prev) => ({
                ...prev,
                errorField: {
                  status: !handlerError.errorField.status,
                  idField: type,
                },
              }));
            } else {
              if (!handlerError.errorField.status) {
                setSubmit(true);
              }
            }
          });
        }
        break;
    }
  };

  const handleChangeInputUser = (e) => {
    setUser((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeInputAgent = (e) => {
    setAgent((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeInputUserSignup = (e) => {
    setUserSignup((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const changeStateSubmit = (e, type) => {
    e.preventDefault();
    handlerErrors(type);
  };

  const callbackLoginUser = async (res) => {
    setLoader(true);
    await signInUser({ ...user, "g-recaptcha-response": res }, remenberMe);
    setSubmit(false);
    setLoader(false);
  };

  const callbackLoginAgent = async (res) => {
    setLoader(true);
    await signInAgent({ ...agent }, remenberMe);
    setSubmit(false);
  };

  const callbackUserSignup = async (res) => {
    await signUpUser({ ...userSignup, "g-recaptcha-response": res }, remenberMe);
    setSubmit(false);
    setLoader(false);
    setUserSignup({
      fullName: "",
      email: "",
      password: "",
      phoneNumber: "",
      termsOfUse: true,
      mailingApproval: true,
    });
  };

  return (
    <>
      <div className="row">
        {!loader ? (
          <div className="login-toggle col-sm-6 mb-1">
            <LoginToggle
              isSignUp={isSignUpMode}
              signInHandler={() => setIsSignUpMode(false)}
              signUpHandler={() => setIsSignUpMode(true)}
            />
          </div>
        ) : (
          <Spin />
        )}
      </div>
      {isSignUpMode ? (
        <>
          <form>
            <div className="row signin">
              <div className="col">
                <h4>{t("header.signUpForm.name", { framework: "React" })} </h4>
                <TextField
                  type="privateName"
                  nameInput={"fullName"}
                  valueInput={userSignup.fullName}
                  handleChangeInput={handleChangeInputUserSignup}
                />
                <h4>{t("header.signUpForm.email", { framework: "React" })}</h4>
                <TextField
                  type="email"
                  nameInput={"email"}
                  valueInput={userSignup.email}
                  handleChangeInput={handleChangeInputUserSignup}
                />


                <div className="remember-me">
                  <SwitchItem
                    value={remenberMe}
                    setValue={setRemenberMe}
                    title={t("header.signUpForm.remmemberMe", {
                      framework: "React",
                    })}
                    trueImg={switchOn}
                    falseImg={switchOff}
                  />
                </div>
              </div>
              <div className="col">
                <h4>
                  {t("header.signUpForm.password", { framework: "React" })}
                </h4>
                <TextField
                  type="password"
                  nameInput={"password"}
                  valueInput={userSignup.password}
                  handleChangeInput={handleChangeInputUserSignup}
                />
                <h4>{t("header.signUpForm.phone", { framework: "React" })}</h4>
                <TextField
                  type="phone"
                  nameInput={"phoneNumber"}
                  valueInput={userSignup.phoneNumber}
                  handleChangeInput={handleChangeInputUserSignup}
                />
              </div>
              {/* <div className="col"> */}
              {/* <h4>
                  {t("header.signUpForm.password", { framework: "React" })}
                </h4>
                <TextField
                  type="password"
                  nameInput={"password"}
                  valueInput={userSignup.password}
                  handleChangeInput={handleChangeInputUserSignup}
                /> */}

              {/* <a href="/">
                  {t("header.signUpForm.buttonForgotPass", {
                    framework: "React",
                  })}
                </a> */}
              {/* </div> */}

              <div className="col">
                <button
                  className="btn-signup"
                  onClick={(e) => changeStateSubmit(e, "signupClient")}
                >
                  {t("header.signUpForm.buttonSignUp", { framework: "React" })}
                </button>
                {handlerError.errorField.status &&
                  handlerError.errorField.idField == "signupClient" ? (
                  <p>Please fill in all required fields</p>
                ) : null}
                {submit && (
                  <Recaptcha
                    sitekey="6LfGKJcaAAAAAJ9DAcI7zuYIy_lnJXiYWRICATf7"
                    render="explicit"
                    verifyCallback={callbackUserSignup}
                  />
                )}
              </div>
            </div>
          </form>
          <div className="row">
            <div className="col-sm-12">
              <h5>
                {" "}
                {t("header.signUpForm.canConnectAnotherWay", {
                  framework: "React",
                })}
              </h5>
              <div className="d-line"></div>
            </div>
          </div>
          <div className="signin-alt row">
            <div className="col-sm-4">
              <LoginButton type="facebook" />
            </div>
            <div className="col-sm-4">
              <LoginButton type="gmail" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row signup">
            {/* <div className={"switcherRole"}>
              {role ? (
                <a onClick={() => setRole(false)}>
                  {t("role.agent", { framework: "React" })}/
                  {t("role.freelancer", { framework: "React" })}
                </a>
              ) : (
                <span>
                  {t("role.agent", { framework: "React" })}/
                  {t("role.freelancer", { framework: "React" })}
                </span>
              )}
              {!role ? (
                <a onClick={() => setRole(true)}>
                  {t("role.client", { framework: "React" })}
                </a>
              ) : (
                <span>{t("role.client", { framework: "React" })}</span>
              )}
            </div> */}
            <div className="col align-middle">
              <h4>
                {role
                  ? t("header.signUpForm.email", { framework: "React" })
                  : t("header.signUpForm.name", { framework: "React" })}
              </h4>
              {role ? (
                <TextField
                  type="email"
                  nameInput={"email"}
                  valueInput={user.email}
                  handleChangeInput={handleChangeInputUser}
                />
              ) : (
                <TextField
                  type="privateName"
                  nameInput={"username"}
                  valueInput={agent.username}
                  handleChangeInput={handleChangeInputAgent}
                />
              )}
              <div className="remember-me">
                <SwitchItem
                  value={remenberMe}
                  setValue={setRemenberMe}
                  title="השאר אותי רשום"
                  trueImg={switchOn}
                  falseImg={switchOff}
                />
              </div>
            </div>
            <div className="col align-middle">
              <h4>
                {" "}
                {t("header.signUpForm.password", { framework: "React" })}
              </h4>
              <TextField
                type="password"
                nameInput={"password"}
                valueInput={role ? user.password : agent.password}
                handleChangeInput={
                  role ? handleChangeInputUser : handleChangeInputAgent
                }
              />
              <a href="/">
                {t("header.signUpForm.buttonForgotPass", {
                  framework: "React",
                })}
              </a>
            </div>
            <div className="col align-middle">
            <h4>
                {" "}
               
              </h4>
              <button
                className="btn-signin mb-1"
                onClick={(e) => changeStateSubmit(e, "client")}
              >
                {t("header.signUpForm.doublebuttonSign.up", {
                  framework: "React",
                })}
              </button>
              {handlerError.errorField.status &&
                handlerError.errorField.idField == "client" ? (
                <p>
                  {t("errors.allFields", {
                    framework: "React",
                  })}
                </p>
              ) : null}
              {submit && (
                <Recaptcha
                  sitekey="6LfGKJcaAAAAAJ9DAcI7zuYIy_lnJXiYWRICATf7"
                  render="explicit"
                  verifyCallback={role ? callbackLoginUser : callbackLoginAgent}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
