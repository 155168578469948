import React, { useState } from "react";
import Recaptcha from "react-recaptcha";
import SecureLS from "secure-ls";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { signUpAgent, signUpFreelancer } from "../../actions/user";

import account from "../../sources/Vector_Smart_Object.png";
import styles from "./styles.module.scss";
const ls = new SecureLS();
export default function SignUpAgentForm() {
  const [t, i18n] = useTranslation("common");
  const [submit, setSubmit] = useState(false);

  const [remmemberMe, setRemmemberMe] = useState(false);
  const [agent, setAgent] = useState({
    username: "",
    email: "",
    password: "",
    phoneNumber: "",

    contactName: "",
    address: {
      streetLine1: "",
      city: "",
      state: "",
      postcode: "",
    },
    termsOfUse: true,
    mailingApproval: true,
  });

  const handleChangeInputAgent = (e) => {
    setAgent((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeInputAddressAgent = (e) => {
    setAgent((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const registerAgent = (e) => {
    e.preventDefault();
    setSubmit(true);
  };

  const callbackAgent = async (res) => {
    await signUpAgent({ ...agent }, remmemberMe);
    setSubmit(false);
    setAgent({
      username: "",
      email: "",
      password: "",
      phoneNumber: "",
      contactName: "",
      address: {
        streetLine1: "",
        city: "",
        state: "",
        postcode: "",
      },
      termsOfUse: true,
      mailingApproval: true,
    });
  };

  return (
    <>
      <div className={cn(styles.headerSignup)}></div>

      <div className={styles.signUpForm}>
        <form>
          <div className={styles.displayFLexRow}>
            <div
              className={cn({ [styles.containerFormSignUp]: true, [styles.cardWithBorder]: true, [styles.cardWithBorderRTL]: ls.get('lang') == 'he', [styles.cardWithBorderLTR]: ls.get('lang') !== 'he' })}
            >
              <div className={cn(styles.displayFLexRow)}>
                <div className={styles.lineSignTop}></div>
                <div className={styles.circleSignUpImage}>
                  <img src={account} />
                </div>
                <div className={styles.lineSignTop}></div>
              </div>

              <h4 className="text-center" style={{transform: 'scaleY(1.4)'}}>
                {t("signUp.title", { framework: "React" })}{" "}
                {t("role.agent", { framework: "React" })}
              </h4>
              <div>
                <div className={styles.handlerForm}>
                  <span className={styles.displayBlock}>
                    {t("signUp.input.inputAgentName", { framework: "React" })}
                  </span>
                  <input
                    name={"username"}
                    value={agent.username}
                    onChange={handleChangeInputAgent}
                  />
                </div>
                <div className={styles.handlerForm}>
                  <span className={styles.displayBlock}>
                    {t("signUp.input.inputAgentPass", { framework: "React" })}
                  </span>
                  <input
                    name={"password"}
                    type={"password"}
                    value={agent.password}
                    onChange={handleChangeInputAgent}
                  />
                </div>
                <div className={styles.handlerForm}>
                  <span className={styles.displayBlock}>
                    {t("signUp.input.inputAgentEmail", {
                      framework: "React",
                    })}
                  </span>
                  <input
                    name={"email"}
                    type={"email"}
                    value={agent.email}
                    onChange={handleChangeInputAgent}
                  />
                </div>
                <div className={styles.handlerForm}>
                  <span className={styles.displayBlock}>
                    {t("signUp.input.inputAgentPhone", {
                      framework: "React",
                    })}
                  </span>
                  <input
                    name={"phoneNumber"}
                    type={"phone"}
                    value={agent.phoneNumber}
                    onChange={handleChangeInputAgent}
                  />
                </div>
                <div className={styles.handlerForm}>
                  <span className={styles.displayBlock}>
                    {t("signUp.input.inputAgentFirstName", {
                      framework: "React",
                    })}
                  </span>
                  <input
                    name={"contactName"}
                    value={agent.contactName}
                    onChange={handleChangeInputAgent}
                  />
                </div>
              </div>
              <div className={cn(styles.bottomLine, styles.trasformLine)}></div>
            </div>

            <div
              className={cn({ [styles.containerFormSignUp]: true, [styles.cardWithBorder]: true, [styles.cardWithBorderRTL]: ls.get('lang') == 'he', [styles.cardWithBorderLTR]: ls.get('lang') !== 'he' })}
            >
              <div>
                <div className={cn(styles.blockWithPaddingTop)}>
                  <span className={styles.displayBlock}>
                    {t("signUp.input.inputAgentCompany", {
                      framework: "React",
                    })}
                  </span>
                  <input
                  // name={"company"}
                  // value={agent.company}
                  // onChange={handleChangeInputAgent}
                  />

                  <div className={styles.handlerForm}>
                    <span className={styles.displayBlock}>
                      {t("signUp.input.inputAgentStreet", {
                        framework: "React",
                      })}
                    </span>
                    <input
                      name={"streetLine1"}
                      value={agent.address.streetLine1}
                      onChange={handleChangeInputAddressAgent}
                    />
                  </div>
                  <div className={styles.handlerForm}>
                    <span className={styles.displayBlock}>
                      {t("signUp.input.inputAgentCity", {
                        framework: "React",
                      })}
                    </span>
                    <input
                      name={"city"}
                      value={agent.address.city}
                      onChange={handleChangeInputAddressAgent}
                    />
                  </div>
                  <div className={styles.handlerForm}>
                    <span className={styles.displayBlock}>
                      {t("signUp.input.inputAgentState", {
                        framework: "React",
                      })}
                    </span>
                    <input
                      name={"state"}
                      value={agent.address.state}
                      onChange={handleChangeInputAddressAgent}
                    />
                  </div>
                  <div className={styles.handlerForm}>
                    <span className={styles.displayBlock}>
                      {t("signUp.input.inputAgentPostCode", {
                        framework: "React",
                      })}
                    </span>
                    <input
                      name={"postcode"}
                      type={"number"}
                      value={agent.address.postcode}
                      onChange={handleChangeInputAddressAgent}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div
                  className={cn(styles.displayFLexRow, styles.checkBoxSignUp)}
                >
                  <div
                    className={
                      "d-flex justify-content-start align-items-center"
                    }
                    style={{ width: "60%" }}
                  >
                    <input
                      type="checkbox"
                      id="scales"
                      name="scales"
                      // defaultChecked
                      style={{ maxWidth: 40 }}
                      className={"mb-0"}
                      value={remmemberMe}
                      setValue={setRemmemberMe}
                    />
                    <label for="scales" className={"me-2 ms-2 top-0"}>
                      {t("signUp.remmemberMe", { framework: "React" })}
                    </label>
                  </div>

                  <button
                    onClick={registerAgent}
                    className={styles.buttonRegisterAgent}
                  >
                    {t("signUp.createAccount", { framework: "React" })}
                  </button>
                </div>
                <div className={"d-flex justify-content-center mt-2"}>
                  {submit && (
                    <Recaptcha
                      sitekey="6LfGKJcaAAAAAJ9DAcI7zuYIy_lnJXiYWRICATf7"
                      render="explicit"
                      verifyCallback={callbackAgent}
                    />
                  )}
                </div>

                <div className={cn(styles.bottomLine)}></div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
