import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SecureLS from "secure-ls";

const ls = new SecureLS();

const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

export default function RoomMenu({
  rooms,
  addRoomHandler,
  removeRoomHandler,
  addVisitorHandler,
  removeVisitorHandler,
  handlerClientErrors,
  handlerSelectAges,
  handlerError,
  disabled,
}) {
  const [t, i18n] = useTranslation("common");
  const menuClass = disabled === true ? "rooms-menu" : "rooms-menu disabled";

  const [age, setAge] = useState(1);
  const [roomState, setRoomState] = useState(false);

  return (
    <div className={menuClass}>
      <div className="rooms-amount">
        <h5 className="ms-2">
          {t("homePage.formSearch.rooms.addRooms", {
            framework: "React",
          })}
        </h5>
        <div className="remove-icon" onClick={removeRoomHandler} />
        <div className="counter">{rooms.length}</div>
        <div
          className="add-icon"
          onClick={() => addRoomHandler(rooms.length)}
        />
      </div>
      {handlerError.errorRooms ? (
        <p>
          {" "}
          {t("errors.maximumValue", {
            framework: "React",
          })}
        </p>
      ) : null}
      {rooms.length > 1 && (
        <>
          {rooms.map((room, index) => (
            <div key={`room-id${index}`} className="container pb-3">
              <div className="row mt-1 pt-2 pb-2">
                <div className="col">
                  <h5>
                    {`${t("homePage.formSearch.rooms.room", {
                      framework: "React",
                    })} ${index + 1}`}
                  </h5>
                </div>
                <div className="col">
                  <h5>
                    {t("homePage.formSearch.rooms.adults", {
                      framework: "React",
                    })}
                  </h5>
                </div>
                <div className="col">
                  <div className="adults-controller">
                    <div
                      className={
                        "d-flex flex-direction-row justify-content-center align-items-center"
                      }
                    >
                      <div
                        className="remove-icon"
                        onClick={() => removeVisitorHandler("adults", room.id)}
                      />
                      <div>
                        <div className="counter">{room.adults}</div>
                      </div>
                      <div
                        className="add-icon"
                        onClick={() => addVisitorHandler("adults", room.id)}
                      />
                    </div>
                    <div>
                      {handlerError.errorNumberRoomAdults.status &&
                      handlerError.errorNumberRoomAdults.idField == room.id ? (
                        <p style={{ color: "red", fontSize: "8px", margin: 0 }}>
                          {t("errors.maximumValue", {
                            framework: "React",
                          })}{" "}
                          - 6
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h5>
                    {t("homePage.formSearch.rooms.children", {
                      framework: "React",
                    })}
                  </h5>
                </div>
                <div className="col">
                  <div className="kids-controller">
                    <div
                      className={
                        "d-flex flex-direction-row justify-content-center align-items-center"
                      }
                    >
                      <div
                        className="remove-icon"
                        onClick={() =>
                          removeVisitorHandler("children", room.id)
                        }
                      />
                      <div>
                        <div className="counter">{room.children.length}</div>
                      </div>
                      <div
                        className="add-icon"
                        onClick={() => addVisitorHandler("children", room.id)}
                      />
                    </div>
                    <div>
                      {handlerError.errorNumberRoomChildren.status &&
                      handlerError.errorNumberRoomChildren.idField ==
                        room.id ? (
                        <p style={{ color: "red", fontSize: "8px", margin: 0 }}>
                          {t("errors.maximumValue", {
                            framework: "React",
                          })}{" "}
                          - 5
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2 ages-range">
                {room.children.map((element, index) => (
                  <SelectAge
                    index={index}
                    item={element}
                    handlerSelectAges={handlerSelectAges}
                    room={room}
                  />
                ))}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export const SelectAge = ({ item, index, handlerSelectAges, room }) => {
  return (
    <div key={`${item} - select age`} className="col-2">
      <select
        value={item}
        style={
          ls.get("lang") == "he"
            ? {
                backgroundPosition: "5% 50%",
                fontWeight: 400,
              }
            : {
                backgroundPosition: "85% 50%",
                fontWeight: 400,
              }
        }
        onChange={(event) => handlerSelectAges(event, index, room.id)}
      >
        {items.map((age, indexOption) => (
          <option value={age} key={`ages-option${indexOption}`}>
            {age}
          </option>
        ))}
      </select>
    </div>
  );
};
