import { api } from "../config";
import SecureLS from "secure-ls";

import store from "../utils/store";
import { actionTypes as usersActionTypes } from "../reducers/user";

const ls = new SecureLS();

export const signUpAgent = async (user, remenberMe) => {
  try {
    const response = await api.post("river/auth/signup/agent", user);
    if (response.status == 200) {
      store.dispatch({
        type: usersActionTypes.SET,
        payload: user,
      });
      if (remenberMe) {
        ls.set("remmemberMeAgent", { name: user.username, password: user.password });
      }
    }
  } catch (e) {
    console.log("Error:", e);
  }
};

export const signUpFreelancer = async (user, remenberMe) => {
  
  try {
    const response = await api.post("river/auth/signup/freelancer", user);
    
    if (response.status == 200) {
      store.dispatch({
        type: usersActionTypes.SET,
        payload: user,
      });
      if (remenberMe) {
        ls.set("remmemberMeAgent", { name: user.username, password: user.password });
      }
    }
  } catch (e) {
    console.log("Error:", e);
  }
};

export const signUpUser = async (user, remenberMe) => {
  try {
    const res = await api.post("river/auth/signup/client", user);
    if (res.status !== 200) {
      console.log(`Can't fetch user ${user.username}`);
    } else {
      ls.set("user", res.data);
      if (remenberMe){
      ls.set("remmemberMeUser", { email: user.email, password: user.password });
      }
    }
  } catch (e) {
    console.log("Error:", e);
  }
};

export const signInUser = async (user, remenberMe) => { 
  try {
    const res = await api.post("river/auth/signin", user);

    if (res.status !== 200) {
      console.log(`Can't fetch user ${user.username}`);
    } else {
      if (remenberMe) {
      ls.set("remmemberMeUser", { email: user.email, password: user.password });
     
      }
      const { accessToken, tokenType } = res.data;
      api.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
      ls.set("user", res.data);
      store.dispatch({ type: usersActionTypes.SET, payload: res.data });
    }
  } catch (e) {
    console.log("Error:", e);
  }
};

export const signInAgent = async (user, remenberMe) => {
  try {
    const res = await api.post("river/auth/signin", user);
    if (res.status !== 200) {
      console.log(`Can't fetch user ${user.username}`);
    } else {
      if (remenberMe) {
        ls.set("remmemberMeAgent", { name: user.username, password: user.password });
       
      }
      api.defaults.headers.common.Authorization = `Basic ${res.data.accessToken}`;
      store.dispatch({ type: usersActionTypes.SET, payload: res.data });
      ls.set("user", res.data);
    }
  } catch (e) {
    console.log("error", e);
  }
};

export const getUser = async (userInfo) => {
  try {
    const res = await api.post("river/auth/signin");

    if (res.status !== 200) {
      console.log(`Can't fetch user ${userInfo.name}`);
    } else {
      ls.set("user", res.data.accessToken);
    }
  } catch (error) {
    console.log("Error:", error);
  }
};

export const unsetUser = () => {
  store.dispatch({ type: usersActionTypes.UNSET, payload: {} });
  ls.set("user", {});
};

export const checkUser = () => {
  const userStr = ls.get("user");
  if (userStr.id) {
    store.dispatch({ type: usersActionTypes.SET, payload: userStr });
  } else return null;
};

export const checkRemmemberUser = () => {
  const userStr = ls.get("userRemmember");
  if (userStr.id) {
    store.dispatch({ type: usersActionTypes.SET, payload: userStr });
  } else return null;
};

export const ressetPasswordUser = async (email) => {
  try {
    const res = await api.get(`/river/auth/resetPassword/${email}`);
    if (res.status !== 200) {
      console.log(`Can't fetch`);
    } else {
      //if (remenberMe) {
      //  ls.set("remmemberMeAgent", { name: user.username, password: user.password });
      
      }
      // api.defaults.headers.common.Authorization = `Basic ${res.data.accessToken}`;
      // store.dispatch({ type: usersActionTypes.SET, payload: res.data });
      // ls.set("user", res.data);
  // }
  } catch (e) {
    console.log("error", e);
  }
}

export const checkingDigits = async (parameters) => {
  try {
    const res = await api.get(`/river/auth/getDigits/${parameters}`);
    if (res.status !== 200) {
      console.log(`Can't fetch parameters ${parameters}`);
    } else {
    }
  } catch (e) {
    console.log("error", e);
  }
}