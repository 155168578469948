import React from "react";
import { useTranslation } from "react-i18next";

import Breakfast from "../images/breakfast.svg";
import { FaCheckCircle } from "react-icons/fa";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SecureLS from "secure-ls";
import moment from "moment";
import { setCurrency } from "../data";
import { addHotelToOrder } from "../actions/book";
import { identity } from "lodash";
const ls = new SecureLS();
export default function RoomExtra({ inSale, setShowSummary, room }) {
  const [t, i18next] = useTranslation("common");
  const roomExtraClass = inSale ? "room-extra is-sale" : "room-extra";
  const orderNowClass = inSale ? "order-now sale" : "order-now";
  const { id } = useParams();
  const hotelData = useSelector((state) => state);
  const history = useHistory();

  const addToOrder = () => {
    addHotelToOrder(room);
    ls.set('rooms', room )
    history.push(`/book/${id}`);
  };
  return (
    <div className="row mt-3">
      <div className="col-4">
        <div className={roomExtraClass}>
          {inSale && (
            <div className="sale">
              <h5>{ls.get('lang')== 'he' ? 'מבצע' : 'Best price'}</h5>
            </div>
          )}
          <img src={Breakfast} alt="breakfast" />
          <h4>
            {room.meal
              ? t(`meal.${room.meal}`, {
                  framework: "React",
                })
              : t("missingData", {
                  framework: "React",
                })}{" "}
          </h4>
        </div>
      </div>
      <div className="col-4">
        <div className="description">
          <div>
            <FaCheckCircle size="18" color="#C59944" style={{margin: '0px 4px'}}/>
            <h4>
              {room.payment_options.payment_types[0]
                .cancellation_penalties.free_cancellation_before
                ? `${t("freeCancel", {
                    framework: "React",
                  })} ${moment(
                    room.payment_options
                      .payment_types[0].cancellation_penalties
                      .free_cancellation_before
                  ).format("DD/MM/YYYY")}`
                : t("none", {
                    framework: "React",
                  })}
            </h4>
          </div>
          {room.payment_options.payment_types[0]
            .cancellation_penalties.free_cancellation_before ? null : (
            <div>
              <FaCheckCircle size="18" color="#C59944" style={{margin: '0px 4px'}}/>
              <h4>
                {t("payOnline", {
                  framework: "React",
                })}
              </h4>
            </div>
          )}
        </div>
      </div>
      <div className="col-4">
        <div className={orderNowClass}>
          <div>
            <h6>
              {" "}
              {/* {ls.get("lang") == "he" ? (
                <small>מחיר ללילה עבור 2 אורחים</small>
              ) : (
                <small></small>
              )} */}
            </h6>
            <h2>
              {ls.get("currency").currency == "ILS"
                ? Number(
                    room.payment_options.payment_types[0].show_amount
                  ).toFixed(0)
                : `${Number(
                    room.payment_options.payment_types[0].show_amount
                  ).toFixed(0)}`}
                  {' '}
              {setCurrency[ls.get("currency").currency]
                ? setCurrency[ls.get("currency").currency]
                : "  ₪"}
            </h2>
          </div>
          <button
            // onClick={() => {
            //   if (rooms === 1) {
            //     goToOrderHandler();
            //   } else {
            //     setShowSummary(true);
            //   }
            // }}
            onClick={addToOrder}
          >
            {t("rooms.buttonBookNow", { framework: "React" })}
          </button>
        </div>
      </div>
    </div>
  );
}
