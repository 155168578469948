import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { RiCloseCircleLine } from "react-icons/ri";
import { IoBed } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import SecureLS from "secure-ls";
import { useSelector } from "react-redux";

const ls = new SecureLS();

export default function MainGuestCard({ index }) {
  const [t, i18next] = useTranslation("common");
  const [firstNameValid, setFirstNameValid] = useState(null);
  const [lastNameValid, setLastNameValid] = useState(null);
  const [phoneValid, setPhoneValid] = useState(null);
  const [EmailValid, setEmailValid] = useState(null);
  const dataHotel = useSelector((state) => state);

  const checkFirstName = (e) => {
    if (e.target.value.length > 0) {
      if (e.target.value.length >= 2 && /^[a-z]+$/i.test(e.target.value))
        setFirstNameValid(true);
      else setFirstNameValid(false);
    } else {
      setFirstNameValid("");
    }
  };

  const checkLastName = (e) => {
    if (e.target.value.length >= 2 && /^[a-z]+$/i.test(e.target.value))
      setLastNameValid(true);
    else setLastNameValid(false);
  };

  const checkEmail = (e) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value
      )
    )
      setEmailValid(true);
    else setEmailValid(false);
  };

  const checkPhone = (e) => {
    if (/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(e.target.value))
      setPhoneValid(true);
    else setPhoneValid(false);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between mt-2">
        <h5>{t("book.contactForm.title", { framework: "React" })}</h5>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <h3>
          {" "}
          {t("book.contactForm.guestRoom", { framework: "React" })} {index + 1}
        </h3>
        <h4 className="text-reset fw-normal">
          {" "}
          {dataHotel.book[index].room_data_trans.bedding_type
            ? dataHotel.book[index].room_data_trans.bedding_type
            : t("missingData", {
                framework: "React",
              })}{" "}
        </h4>
      </div>
      <hr />
      <h3 className="text-start">
        {dataHotel.book[index].room_data_trans.main_room_type}
      </h3>
      <div className="row mb-2">
        <div className="col-5">
          <div
            className={
              firstNameValid !== null
                ? firstNameValid
                  ? "valid"
                  : "invalid"
                : ""
            }
          >
            <h6>{t("book.contactForm.firstName", { framework: "React" })}</h6>
            <input
              type="text"
              placeholder={t("book.contactForm.placeholderFirstName", {
                framework: "React",
              })}
              name="firstName"
              onChange={(e) => checkFirstName(e)}
            />
          </div>
          <div
            className={
              phoneValid !== null
                ? phoneValid
                  ? "valid mt-2"
                  : "invalid mt-2"
                : "mt-2"
            }
          >
            <div className="d-flex justify-content-between">
              <h6>
                {t("book.contactForm.mobilePhone", { framework: "React" })}
              </h6>
              <h6>{t("book.contactForm.areaCode", { framework: "React" })}</h6>
            </div>
            <div className="phone-input">
              {ls.get("lang") == "he" ? (
                <>
                  
                  <input
                    type="phone"
                    placeholder={t("book.contactForm.mobilePhone", {
                      framework: "React",
                    })}
                    name="phone"
                    onChange={(e) => checkPhone(e)}
                    style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                  />
                  <select style={{borderBottomLeftRadius: '5px', borderTopLeftRadius: '5px', borderBottomRightRadius: 0, borderTopRightRadius: 0, padding: '0px 15px'}}>
                    <option value="+972">972+</option>
                  </select>{" "}
                </>
              ) : (
                <>
                   <select style={{borderBottomRightRadius: 0, borderTopRightRadius: 0, padding: '0px 15px 0px 30px'}}>
                    <option value="+972">+972</option>
                  </select>
                  <input
                    type="phone"
                    placeholder={t("book.contactForm.mobilePhone", {
                      framework: "React",
                    })}
                    name="phone"
                    onChange={(e) => checkPhone(e)}
                    style={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                  />
                 
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-5">
          <div
            className={
              lastNameValid !== null
                ? lastNameValid
                  ? "valid"
                  : "invalid"
                : ""
            }
          >
            <h6>{t("book.contactForm.lastName", { framework: "React" })}</h6>
            <input
              type="text"
              placeholder={t("book.contactForm.placeholderLastName", {
                framework: "React",
              })}
              name="lastName"
              onChange={(e) => checkLastName(e)}
            />
          </div>
          <div
            className={
              EmailValid !== null
                ? EmailValid
                  ? "valid mt-2"
                  : "invalid mt-2"
                : "mt-2"
            }
          >
            <h6>{t("book.contactForm.email", { framework: "React" })}</h6>
            <input
              type="email"
              placeholder={t("book.contactForm.email", { framework: "React" })}
              name="email"
              onChange={(e) => checkEmail(e)}
            />
          </div>
        </div>
        <div className="col d-flex align-items-center justify-content-center">
          <IoBed size="80" color="#C59944" />
        </div>
      </div>
      <h6 className="mt- fw-normal d-inline">
        {t("book.contactForm.subTitle", { framework: "React" })}
      </h6>
      <h3 className="mt-5">
        {" "}
        {t("book.contactForm.specialRequest", { framework: "React" })}
      </h3>
      <textarea maxLength="150" className="mt-2" />
      <div className="d-flex align-items-center mt-2">
        <FaCheckCircle size="20" color="#C59944" />
        <h5 className="me-1">
          {t("book.contactForm.spSubTitle", { framework: "React" })}
        </h5>
      </div>
    </div>
  );
}
