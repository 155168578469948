import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { browserHistory } from 'react-router';
import SecureLS from "secure-ls";
import i18next from "i18next";
import history from './history';


import HomeScreen from "./screens/HomeScreen";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import FilterScreen from "./screens/FilterScreen";
import RoomsScreen from "./screens/RoomsScreen";
import BookScreen from "./screens/BookScreen";
import ScrollToTop from "./utils/ScrollToTop";

import store from "./utils/store";
import { Provider } from "react-redux";
import SignUpAgent from "./screens/SighUpAgentScreen";
import { useEffect, useState } from "react";
import { HotelScreen } from "./screens/HotelScreen";
import { checkUser } from "./actions/user";
import SignUpAgentForm from "./components/SignUpForm/SignUpForm";
import SignUpFreelancerForm from "./components/SignUpFreelancer/SignUpForm";

const ls = new SecureLS();

const App = () => {
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    i18next.changeLanguage(ls.get("lang"));
    if (ls.get("lang") == "he" || !ls.get("lang")) {
      ls.set('lang', 'he');
      document.getElementsByTagName("div")[0].setAttribute("dir", "rtl");
     
    } else {
      document.getElementsByTagName("div")[0].setAttribute("dir", "ltr");
    }
  }, []);

  useEffect(() => {
    checkUser();
  }, []);

  return (
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path="/" component={HomeScreen} exact />
          <Route path="/signup/agent" component={SignUpAgentForm} />
          <Route path="/signup/freelancer" component={SignUpFreelancerForm} />
          <Route path="/filter" component={FilterScreen} />
          <Route path="/rooms/:id" component={RoomsScreen} />
          <Route path="/book/:id" component={BookScreen} />
          <Route path="/hotel/:id" component={HotelScreen} />
        </Switch>
        <Footer />
      </Router>
    </Provider>
  );
};

export default App;
