import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import HotelDescription from "../components/HotelDescription";
import HotelDevices from "../components/HotelDevices";
import HotelLocation from "../components/HotelLocation";
import HotelPolicy from "../components/HotelPolicy";
import {getAllHotels, getOneHotel} from "../actions/hotels"

import RoomFilter from "../components/RoomFilter";
import RoomInfo from "../components/RoomInfo";
import SearchDetails from "../components/SearchDetails";
import SecureLS from "secure-ls";
import { useSelector } from "react-redux";
import { Spin } from "antd";

const ls = new SecureLS();

export default function RoomsScreen(props) {
  console.log(props)
  const hotelData = useSelector((state)=> state);
  const [t, i18next] = useTranslation("common");
  const [changeDestination, setChangeDestination] = useState(false);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true)
    if(!hotelData.hotels.length){
      await getAllHotels(ls.get('searchParameters'))
    }
    await getOneHotel(
     ls.get('hotelId')
    );
   
    setLoading(false)   
    
  }
  useEffect(() => {
    if(ls.get('hotelId') !== hotelData.hotel.id || !hotelData.hotel.name ){
      getData()
    }
    
  }, [])
  return (
    <>{
     !loading && hotelData.hotel.name && hotelData.hotels.length && ls.get('hotelId') == hotelData.hotel.id
    ?<>
      <div>
        <div className="secondary-nav">
          <div className="container">
            <Link className="link" to="/">
            {t("pagesSite.home", { framework: "React" })}
            </Link>
            <p>/</p>
            <Link className="link" to="/filter">
              {hotelData.searchParameters.query}
            </Link>
            <p>/</p>
            <h5> {hotelData.hotel.name? hotelData.hotel.name: '' }</h5>
          </div>
        </div>
      </div>
      <RoomInfo />
      {changeDestination && <SearchDetails />}
      <RoomFilter
        changeDestination={changeDestination}
        setChangeDestination={setChangeDestination}
      />
      <HotelLocation />
      <HotelDescription />
      <HotelDevices />
      <HotelPolicy />
      </>: <div style={{ textAlign: "center" }}>
          <Spin />
        </div>}
    </>
  );
}
