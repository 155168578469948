import React, { useState } from "react";
import i18next from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import LocationAccordion from "./LocationAccordion";
import MapContainer from "./MapContainer";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function HotelLocation() {
  const [t, i18next] = useTranslation("common");
  const [placeOpen, setPlaceOpen] = useState(true);
  const [nearbyOpen, setNearbyOpen] = useState(false);
  const [airportsOpen, setAirportsOpen] = useState(false);
  const dataHotel = useSelector((state)=> state);
  const {id} = useParams();

  const placeHandler = () => {
    if (!placeOpen) {
      setPlaceOpen(true);
      setNearbyOpen(false);
      setAirportsOpen(false);
    } else {
      setPlaceOpen(false);
    }
  };
  const nearbyHandler = () => {
    if (!nearbyOpen) {
      setNearbyOpen(true);
      setPlaceOpen(false);
      setAirportsOpen(false);
    } else {
      setNearbyOpen(false);
    }
  };
  const airportsHandler = () => {
    if (!airportsOpen) {
      setAirportsOpen(true);
      setPlaceOpen(false);
      setNearbyOpen(false);
    } else {
      setAirportsOpen(false);
    }
  };

  return (
    <div className="hotel-location">
      <div className="container">
        <div className="title">
          <h3>{t("rooms.location.locationOnMap", { framework: "React" })} </h3>
          <h3>{t("rooms.location.title", { framework: "React" })}</h3>
        </div>
        <div className="address">
          <h5>{t("rooms.location.subTitle", { framework: "React" })}</h5>
          <h5>{dataHotel.hotels[id].rates[0].address != undefined
                      ? dataHotel.hotels[id].rates[0].address
                      : t("missingData", {
                          framework: "React",
                        })}</h5>
        </div>
        <div className="row">
          <div className="col-7">
            <div className="w-100">
              <MapContainer height="450px" />
            </div>
          </div>
          <div className="col">
            <div className="accordion">
              <LocationAccordion
                title={t("rooms.location.placesNearly", { framework: "React" })}
                isOpen={placeOpen}
                setIsOpen={placeHandler}
              >
                <div className="content">
                  <p>
                    <span style={{ color: "#C59944" }}>
                    {t("missingData", {
                framework: "React",
              })}
                    </span>
                    <br />
                    {t("missingData", {
                framework: "React",
              })}
                  </p>
                </div>
              </LocationAccordion>
              <LocationAccordion
                title={t("rooms.location.sightsNearby", { framework: "React" })}
                isOpen={nearbyOpen}
                setIsOpen={nearbyHandler}
              >
                <div className="content">
                  <p>
                  {t("missingData", {
                framework: "React",
              })}
                  </p>
                </div>
              </LocationAccordion>
              <LocationAccordion
                title={t("rooms.location.airports", { framework: "React" })}
                isOpen={airportsOpen}
                setIsOpen={airportsHandler}
              >
                <div className="content">
                  <p>
                  {t("missingData", {
                framework: "React",
              })}
                  </p>
                </div>
              </LocationAccordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
