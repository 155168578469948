import React from "react";
import { useTranslation } from "react-i18next";
import SecureLS from "secure-ls";
import { useSelector } from "react-redux";

import devices from "../data";
const ls = new SecureLS();
export default function HotelDevices() {
  const [t, i18n] = useTranslation("common");
  const dataHotel = useSelector((state) => state);
  return (
    <div className="hotel-devices">
      <div className="container">
        <h2>
          {t("rooms.hotelDevices.title", {
            framework: "React",
          })}
        </h2>
        <hr />
        <h3>
          {t("rooms.hotelDevices.subTitle", {
            framework: "React",
          })}
        </h3>
        <div className="devices-grid row">
          {dataHotel.hotel.amenity_groups.map((device) => (

            device.amenities.map((amenity, index) => (
              <div key={`device.id-${index}`} className="col-2">

                <h4 className="me-2">{amenity}</h4></div>
            ))
          ))}
        </div>
      </div>
    </div>
  );
}
