import React from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import security from "../images/security.svg";
import partner6 from "../images/partner6.svg";
import partner7 from "../images/partner7.svg";
import partner8 from "../images/partner8.svg";
import partner9 from "../images/partner9.svg";
import partner10 from "../images/partner10.svg";
import partner11 from "../images/partner11.svg";
import logoAlt from "../images/logo-alt.svg";
import logoLi from "../images/logo-li.svg";
import logoM from "../images/logo-m.svg";
import logoI from "../images/logo-i.svg";
import logoW from "../images/logo-w.svg";
import logoFB from "../images/logo-fb.svg";

export default function Footer() {
  const [t, i18next] = useTranslation("common");

  return (
    <footer>
      <div className="security-section">
        <div className="container">
          <div className="row">
            <div className="col-1">
              <img src={security} alt="security" />
            </div>
            <div className="col">
              <h3> {t("footer.title", { framework: "React" })}</h3>
              <p>{t("footer.subTitle1", { framework: "React" })}</p>
              <p>{t("footer.subTitle2", { framework: "React" })}</p>
              <div className="partners">
                <img src={partner6} alt="partner6" />
                <img src={partner7} alt="partner7" />
                <img src={partner8} alt="partner8" />
                <img src={partner9} alt="partner9" />
                <img src={partner10} alt="partner10" />
                <img src={partner11} alt="partner11" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-footer">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="footer-nav">
                <div className="footer-nav-col">
                  <h3> {t("footer.company.title", { framework: "React" })}</h3>
                  <h4>{t("footer.company.hotels", { framework: "React" })}</h4>
                  <h4> {t("footer.company.about", { framework: "React" })}</h4>
                  <h4>
                    {t("footer.company.contactUs", { framework: "React" })}{" "}
                  </h4>
                </div>
                <div className="footer-nav-col" style={{margin: '0 8px 0 8px'}}>
                  <h3>{t("footer.terms.title", { framework: "React" })}</h3>
                  <h4>{t("footer.terms.common", { framework: "React" })}</h4>
                  <h4>
                    {t("footer.terms.privacyStatement", { framework: "React" })}
                  </h4>
                  <h4>
                    {t("footer.terms.privacyStatementCookies", {
                      framework: "React",
                    })}
                  </h4>
                </div>

                <div className="footer-nav-col">
                  <h4>
                    <h3> {t("header.signUpForm.doublebuttonSign.in", { framework: "React" })}</h3>
                    <Link to={"/signup/agent"}>

                      {t("role.agent", { framework: "React" })}
                    </Link>
                  </h4>
                  <h4>
                    <Link to={"/signup/freelancer"}>
                      {t("role.freelancer", { framework: "React" })}
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col text-start">
              <div className="footer-logo float-start">
                <img src={logoAlt} alt="logo" />
                <div>
                  <img src={logoLi} alt="logo" />
                  <img src={logoM} alt="logo" />
                  <img src={logoI} alt="logo" />
                  <img src={logoW} alt="logo" />
                  <img src={logoFB} alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="secondary-footer">
        <div className="container text-center">
          <h4>{t("header.title", { framework: "React" })} </h4>
        </div>
      </div>
    </footer>
  );
}
