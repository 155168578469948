import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { FaRegClock } from "react-icons/fa";
import { SiTripadvisor } from "react-icons/si";
import blueHotel from "../images/blue-hotel.svg";
import dateRange from "../images/date-range.svg";
import hotel1 from "../images/hotel1.jpg";
import hotel2 from "../images/hotel2.jpg";
import hotel3 from "../images/hotel3.jpg";
import hotelPay from "../images/hotel_pay.svg";
import coin from "../images/coin.svg";
import breakfast from "../images/breakfast.svg";
import Breakfast from "../images/breakfast.svg";
import CancelPayment from "../images/cancel_payment.svg";
import HotelPay from "../images/hotel_pay.svg";
// import hotel3 from '../images/hotel3.jpg';
import StarRatings from "react-star-ratings";
import SecureLS from "secure-ls";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { sliderData } from "../data";

const ls = new SecureLS();
export default function OrderInfo() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [t, i18n] = useTranslation("common");
  const hotelData = useSelector((state) => state);
  const { id } = useParams();
  const changeIndexHandler = (mode) => {
    if (mode === "prev") {
      if (activeIndex === 0) {
      } else {
        setActiveIndex(activeIndex - 1);
      }
    } else {
      if (activeIndex < hotelData.hotel.images.length - 1) {
        setActiveIndex(activeIndex + 1);
      }
    }
  };

  return (
    <div className="order-info">
      <div className="container">
        <div className="title">
          <h4>{t("book.orderDetails.title", { framework: "React" })} </h4>
        </div>
        <div className="row">
          <div className="col-3">
            <div className="reception-hours">
              <FaRegClock size="24" color="#085F7B" className={"me-2"} />
              <h4>
                {t("book.orderDetails.receptionHours.title", {
                  framework: "React",
                })}
              </h4>
              <hr />
            </div>
            <div className="order-check">
              <div className="item">
                <h5>
                  {" "}
                  {t("book.orderDetails.receptionHours.checkIn", {
                    framework: "React",
                  })}
                </h5>
                <div className="date">
                  <img
                    src={dateRange}
                    alt="date range"
                    className={ls.get("lang") != "he" ? "me-2" : ""}
                  />
                  <h5>{hotelData.searchParameters.checkin}</h5>
                </div>
                <h6 className="mt-1">
                  {" "}
                  {t("book.afterHour", {
                    framework: "React",
                  })}{" "}
                  {hotelData.hotel.check_in_time.substring(0, 5)} PM
                </h6>
              </div>
              <hr />
              <div className="item">
                <h5>
                  {" "}
                  {t("book.orderDetails.receptionHours.checkOut", {
                    framework: "React",
                  })}
                </h5>
                <div className="date">
                  <img
                    src={dateRange}
                    alt="date range"
                    className={ls.get("lang") != "he" ? "me-2" : ""}
                  />
                  <h5>{hotelData.searchParameters.checkout}</h5>
                </div>
                <h6 className="mt-1">
                  {" "}
                  {t("book.afterHour", {
                    framework: "React",
                  })}{" "}
                  {hotelData.hotel.check_out_time.substring(0, 5)} AM
                </h6>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="order-hotel">
              <img src={blueHotel} alt="blue hotel" />
              <h4>
                {t("book.orderDetails.hotel.title", { framework: "React" })}
              </h4>
              <hr />
            </div>
            <div className="hotel-info">
              <div className="content">
                <div className="row">
                  <div className="col-7 ms-2">
                    <div className="rating">
                      <div>
                        <StarRatings
                          rating={
                            hotelData.hotel.star_certificate
                              ? hotelData.hotel.star_certificate
                              : 0
                          }
                          starRatedColor="#00aa6c"
                          numberOfStars={5}
                          starDimension={"17px"}
                          starSpacing={"1px"}
                          svgIconPath={
                            "M21.21,0a21.21,21.21,0,1,0,21.2,21.21A21.23,21.23,0,0,0,21.21,0Z"
                          }
                        />
                        <SiTripadvisor size="20" className="me-1 mt-1" />
                        <h6>
                          {t("missingData", {
                            framework: "React",
                          })}{" "}
                        </h6>
                      </div>
                      <StarRatings
                        rating={hotelData.hotel.star_rating}
                        starRatedColor="#C59944"
                        numberOfStars={5}
                        starDimension={"17px"}
                        starSpacing={"1px"}
                      />
                    </div>
                    <h3 className={ls.get("lang") == "he" ? "end-0" : ""}>
                      {" "}
                      {hotelData.hotel.name
                        ? hotelData.hotel.name
                        : t("missingData", {
                          framework: "React",
                        })}
                    </h3>
                    <h4>
                      {hotelData.hotel.address
                        ? hotelData.hotel.address
                        : t("missingData", {
                          framework: "React",
                        })}
                    </h4>
                    <div className="extras mt-2">
                      {/* <div>
                        <h4>תשלום עכשיו</h4>
                        <img src={hotelPay} alt="Hotel Pay" />
                      </div>
                      <div>
                        <h4>ביטול חינם</h4>
                        <img src={coin} alt="Coin" />
                      </div>
                      <div>
                        <h4>ארוחת בוקר</h4>
                        <img src={breakfast} alt="breakfast" />
                      </div> */}

                      {hotelData.hotels[id].rates[0].meal &&
                        !hotelData.hotels[id].rates.find((room) => room.meal == "nomeal") ? (
                        <div>
                          <img src={Breakfast} alt="" />
                          <h5>
                            {t("filter.cardHotel.breakfast", {
                              framework: "React",
                            })}
                          </h5>

                        </div>
                      ) : <div>
                        <h5>
                          <img src={Breakfast} alt="" />
                          {t("filter.cardHotel.roomOnly", {
                            framework: "React",
                          })}
                        </h5>
                      </div>}
                      {hotelData.hotels[id].rates[0].payment_options.payment_types[0]
                        .cancellation_penalties.free_cancellation_before ? (
                        <div>
                          <img src={CancelPayment} alt="" />
                          <h5>
                            {t("filter.cardHotel.freeCancellation", {
                              framework: "React",
                            })}
                          </h5>
                        </div>
                      ) : null}
                      {hotelData.hotels[id].rates[0].payment_options.payment_types[0]
                        .type ? (
                        <div>
                          <img src={HotelPay} alt="" />
                          <h5>
                            {` ${hotelData.hotels[id].rates[0].payment_options.payment_types[0]
                              .type == "deposit"
                              ? "אשראי לפקדון"
                              : t("filter.cardHotel.payment", {
                                framework: "React",
                              }) + hotelData.hotels[id].rates[0].payment_options.payment_types[0]
                                .type
                              }`}
                          </h5>
                        </div>
                      ) : null}
                    </div>
                    <hr />
                    <h4 className="fw-bolder mt-1">
                      {t("book.yourChoice", { framework: "React" })}{" "}
                      {hotelData.book.length}{" "}
                      {t("book.rooms", { framework: "React" })}
                    </h4>
                  </div>
                  <div className="col">
                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner">
                        {hotelData.hotel.images
                          ? hotelData.hotel.images.map((image, index) => (
                            <div
                              className={
                                activeIndex === index
                                  ? "carousel-item active"
                                  : "carousel-item"
                              }
                            >
                              <img
                                src={image.replace("{size}", "640x400")}
                                className="d-block w-100"
                                alt="img 1"
                              />
                            </div>
                          ))
                          : hotelData.hotel.images.map((image, index) => (
                            <div
                              className={
                                activeIndex === index
                                  ? "carousel-item active"
                                  : "carousel-item"
                              }
                            >
                              <img
                                src={image.img}
                                className="d-block w-100"
                                alt="img 1"
                              />
                            </div>
                          ))}
                      </div>
                      {activeIndex > 0 && (
                        <button
                          className="carousel-control-prev"
                          onClick={() => changeIndexHandler("prev")}
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                      )}
                      {activeIndex < hotelData.hotel.images.length - 1 && (
                        <button
                          className="carousel-control-next"
                          onClick={() => changeIndexHandler("next")}
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      )}
                      <div className="bookmark">0.0</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
