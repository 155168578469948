import React from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import { signUpAgent } from "../actions/user";

export default function LoginToggle({
  isSignUp,
  signInHandler,
  signUpHandler
}) {
  const [t, i18next] = useTranslation("common");
  const signupClass = `toggle-btn ${isSignUp ? "active" : ""}`;
  const signinClass = `toggle-btn ${isSignUp ? "" : "active"}`;
  const signupAgentClass = `long-button`;

  const signUpAgentHandler =() =>{
    
  }
  return (
    <>
      <button className={signupClass} onClick={signUpHandler}>
      {t("header.signUpForm.doublebuttonSign.in", { framework: "React" })}
      </button>
      <button className={signinClass} onClick={signInHandler}>
      {t("header.signUpForm.doublebuttonSign.up", { framework: "React" })}
      </button>
    </>
  );
}
