import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import moment from "moment";

import SwitchItem from "../components/SwitchItem";
import { FaCheckCircle } from "react-icons/fa";
import switchOn from "../images/switch-on.svg";
import switchOff from "../images/switch-off.svg";
import RoomItem from "./RoomItem";
import OrderSummary from "./OrderSummary";
import SecureLS from "secure-ls";
import { useSelector } from "react-redux";
import { typeRooms } from "../data";
import { useParams } from "react-router-dom";

const ls = new SecureLS();
export default function RoomFilter({
  changeDestination,
  setChangeDestination,
}) {
  const [t, i18next] = useTranslation("common");
  const [couplesBed, setCouplesBed] = useState(false);
  const [breakfast, setBreakfast] = useState(false);
  const [payment, setPayment] = useState(false);
  const [freeCancel, setFreeCancel] = useState(false);
  const data = useSelector((state) => state);
  const { id } = useParams();

  const [totalNumberGuests, setTotalNumberGuest] = useState(0)
  const [totalNumberNights, setTotalNumberNights] = useState(0)
  const [showSummary, setShowSummary] = useState(false);
  const checkNumberGuests = () => {
    if (data.searchParameters.guests) {
      data.searchParameters.guests.map((room) => {
        const newTotalNumberGuests =
          totalNumberGuests + room.adults + room.children.length;
        setTotalNumberGuest(newTotalNumberGuests)
      });

    }
    let admission = moment(data.searchParameters.checkin);
    let discharge = moment(data.searchParameters.checkout);
    const diff = moment.duration(discharge.diff(admission)).asDays();
    setTotalNumberNights(Math.trunc(diff));
  };

  if (ls.get("lang") == "he") {
    moment.locale("he");
  } else {
    moment.locale("en");
  }

  useEffect(() => {
    checkNumberGuests();
  }, [])

  let uniqueListRooms = []
  const groupedRoomsItem = () => {
    const listTypeRooms = data.hotels[id].rates.map((el) => { return el.room_name })
    uniqueListRooms = listTypeRooms.filter((v, i, a) => a.indexOf(v) === i);
  }
  groupedRoomsItem()
  return (
    <>
      {showSummary && <OrderSummary />}
      <div className="room-filter">
        <hr />
        <div className="container">
          <div className="row mt-3">
            <div className="col-5 col-sm-4">
              <h3>{t("rooms.roomFiltration.mainTitle", { framework: "React" })}</h3>
            </div>
            <div className="col col-sm-8 your-choice">
              <FaCheckCircle size="16" color="#C59944" />
              <h5>{t("rooms.roomFiltration.yourChoice", { framework: "React" })}</h5>
              {" "}
              {ls.get("lang") == "he" ? (
                <h4 style={{ fontSize: "20px" }}>
                  {`(${totalNumberNights})`}לילות:{" "}
                  {moment(data.searchParameters.checkin).format("LL", "he")}
                  {" - "}
                  {moment(data.searchParameters.checkout).format("LL")},
                  עבור {totalNumberGuests}{" "}
                  {totalNumberGuests > 1 ? "אורחים" : "אורחים"}
                </h4>
              ) : (
                <h4 style={{ fontSize: "20px" }}>
                  {" "}
                  {`(${totalNumberNights})`} Nights:{" "}
                  {moment(data.searchParameters.checkin).format("LL", "en")}
                  {" - "}
                  {moment(data.searchParameters.checkout).format("LL")}, for{" "}
                  {totalNumberGuests}{" "}
                  {totalNumberGuests > 1 ? "guests" : "guest"}
                </h4>
              )}
              <button
                className="change-destination"
                onClick={() => setChangeDestination(!changeDestination)}
              >
                {changeDestination ? t("rooms.topButton.close", { framework: "React" }) : t("rooms.topButton.open", { framework: "React" })}
              </button>
            </div>
          </div>
          <div className="row mt-2 p-0">
            <div className="col-3">
              <div className="filters">
                <h5>{t("rooms.roomFiltration.cancellationPolicy.title", { framework: "React" })}</h5>
                <SwitchItem
                  value={couplesBed}
                  setValue={setCouplesBed}
                  title={t("rooms.roomFiltration.cancellationPolicy.option", { framework: "React" })}
                  trueImg={switchOn}
                  falseImg={switchOff}
                />
              </div>
            </div>
            <div className="col">
              <div className="filters">
                <h5>{t("rooms.roomFiltration.payment.title", { framework: "React" })}</h5>
                <SwitchItem
                  value={breakfast}
                  setValue={setBreakfast}
                  title={t("rooms.roomFiltration.payment.option", { framework: "React" })}
                  trueImg={switchOn}
                  falseImg={switchOff}
                />
              </div>
            </div>
            <div className="col">
              <div className="filters">
                <h5>{t("rooms.roomFiltration.meals.title", { framework: "React" })}</h5>
                <SwitchItem
                  value={payment}
                  setValue={setPayment}
                  title={t("rooms.roomFiltration.meals.option", { framework: "React" })}
                  trueImg={switchOn}
                  falseImg={switchOff}
                />
              </div>
            </div>
            <div className="col">
              <div className="filters">
                <h5>{t("rooms.roomFiltration.beds.title", { framework: "React" })}</h5>
                <SwitchItem
                  value={freeCancel}
                  setValue={setFreeCancel}
                  title={t("rooms.roomFiltration.beds.option", { framework: "React" })}
                  trueImg={switchOn}
                  falseImg={switchOff}
                />
              </div>
            </div>
          </div>
          {
            uniqueListRooms.map((type, index) => (
              (data.hotels[id].rates.filter((room) => room.room_name === type)).length != 0 ? (
                <RoomItem key={`roomItem-${index}`} setShowSummary={setShowSummary} oneRoom={data.hotels[id].rates.filter((room) => room.room_name === type)} />) : null
            ))
          }
        </div>
      </div>
    </>
  );
}
