import React from "react";

export default function SwitchItem({
  value,
  setValue,
  title,
  trueImg,
  falseImg,
}) {
  return (
    <div className={'switchPostition'}>
      {value ? (
        <img src={trueImg} alt="img 1" onClick={() => setValue(false)} />
      ) : (
        <img src={falseImg} alt="img 2" onClick={() => setValue(true)} />
      )}
      {title !== "" && <label>{title}</label>}
    </div>
  );
}
