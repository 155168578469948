import React, { useEffect, useState } from "react";
import Recaptcha from "react-recaptcha";
import SecureLS from "secure-ls";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { signUpAgent, signUpFreelancer } from "../../actions/user";

import styles from "./styles.module.scss";

const ls = new SecureLS();

export default function SignUpFreelancerForm() {
  const [t, i18n] = useTranslation("common");
  const [submit, setSubmit] = useState(false);

  const [remmemberMe, setRemmemberMe] = useState(false);


  const [freeelancer, setFreelancer] = useState({
    username: "",
    email: "",
    password: "",
    phoneNumber: "",
    contactName: "",
    address: {
      streetLine1: "",
      city: "",
      state: "",
      postcode: "",
    },
    termsOfUse: true,
    mailingApproval: true,
  });


  const handleChangeInputFreelancer = (e) => {
    setFreelancer((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };


  const handleChangeInputAddressFreelancer = (e) => {
    setFreelancer((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const registerAgent = (e) => {
    e.preventDefault();
    setSubmit(true);
  };


  const callbackFreelancer = async (res) => {
    await signUpFreelancer(
      { ...freeelancer, "g-recaptcha-response": res },
      remmemberMe
    );
    setSubmit(false);
    setFreelancer({
      username: "",
      email: "",
      password: "",
      phoneNumber: "",
      contactName: "",
      address: {
        streetLine1: "",
        city: "",
        state: "",
        postcode: "",
      },
      termsOfUse: true,
      mailingApproval: true,
    });
  };

  return (
    <>
      <div className={cn(styles.headerSignup)}></div>

      <div className={styles.signUpForm}>
        <form>
          <div className={styles.displayFLexRow}>
            <div
              className={cn({ [styles.containerFormSignUp]: true, [styles.cardWithBorder]: true, [styles.cardWithBorderRTL]: ls.get('lang') == 'he', [styles.cardWithBorderLTR]: ls.get('lang') !== 'he' })}
            >
              <div className={cn(styles.displayFLexRow)}>
                <div className={styles.lineSignTop}></div>
                <div className={styles.circleSignUpImage}>
                  <i
                    class="far fa-user"
                    style={{ color: "white", fontSize: "34px" }}
                  ></i>
                </div>
                <div className={styles.lineSignTop}></div>
              </div>

              <h4 className="text-center" style={{transform: 'scaleY(1.4)'}}>
                {t("signUp.title", { framework: "React" })}{" "}
                {t("role.freelancer", { framework: "React" })}
              </h4>
              {/* <div className={styles.inputFields}> */}
              <div className={styles.handlerForm}>
                <span className={styles.displayBlock}>
                  {t("signUp.input.inputAgentName", { framework: "React" })}
                </span>
                <input
                  name={"username"}
                  value={freeelancer.username}
                  onChange={handleChangeInputFreelancer}
                />
              </div>
              <div className={styles.handlerForm}>
                <span className={styles.displayBlock}>
                  {t("signUp.input.inputAgentPass", { framework: "React" })}
                </span>
                <input
                  name={"password"}
                  type={"password"}
                  value={freeelancer.password}
                  onChange={handleChangeInputFreelancer}
                />
              </div>
              <div className={styles.handlerForm}>
                <span className={styles.displayBlock}>
                  {t("signUp.input.inputAgentEmail", {
                    framework: "React",
                  })}
                </span>
                <input
                  name={"email"}
                  type={"email"}
                  value={freeelancer.email}
                  onChange={handleChangeInputFreelancer}
                />
              </div>
              <div className={styles.handlerForm}>
                <span className={styles.displayBlock}>
                  {t("signUp.input.inputAgentPhone", {
                    framework: "React",
                  })}
                </span>
                <input
                  name={"phoneNumber"}
                  type={"phone"}
                  value={freeelancer.phoneNumber}
                  onChange={handleChangeInputFreelancer}
                />
              </div>
              <div className={styles.handlerForm}>
                <span className={styles.displayBlock}>
                  {t("signUp.input.inputAgentFirstName", {
                    framework: "React",
                  })}
                </span>
                <input
                  name={"contactName"}
                  value={freeelancer.contactName}
                  onChange={handleChangeInputFreelancer}
                />
              </div>
              {/* </div> */}
              <div
                className={cn(styles.bottomLine, styles.trasformLine)}
              ></div>
            </div>

            <div
              className={cn({ [styles.containerFormSignUp]: true, [styles.cardWithBorder]: true, [styles.cardWithBorderRTL]: ls.get('lang') == 'he', [styles.cardWithBorderLTR]: ls.get('lang') !== 'he' })}
            >
              {/* <div className={styles.inputFields}> */}
              <div className={cn(styles.blockWithPaddingTop)}>
                <span className={styles.displayBlock}>
                  {t("signUp.input.inputAgentCompany", {
                    framework: "React",
                  })}
                </span>
                <input
                // name={"company"}
                // value={freeelancer.company}
                // onChange={handleChangeInputFreelancer}
                />

                <div className={styles.handlerForm}>
                  <span className={styles.displayBlock}>
                    {t("signUp.input.inputAgentStreet", {
                      framework: "React",
                    })}
                  </span>
                  <input
                    name={"streetLine1"}
                    value={freeelancer.address.streetLine1}
                    onChange={handleChangeInputAddressFreelancer}
                  />
                </div>
                <div className={styles.handlerForm}>
                  <span className={styles.displayBlock}>
                    {t("signUp.input.inputAgentCity", {
                      framework: "React",
                    })}
                  </span>
                  <input
                    name={"city"}
                    value={freeelancer.address.city}
                    onChange={handleChangeInputAddressFreelancer}
                  />
                </div>
                <div className={styles.handlerForm}>
                  <span className={styles.displayBlock}>
                    {t("signUp.input.inputAgentState", {
                      framework: "React",
                    })}
                  </span>
                  <input
                    name={"state"}
                    value={freeelancer.address.state}
                    onChange={handleChangeInputAddressFreelancer}
                  />
                </div>
                <div className={styles.handlerForm}>
                  <span className={styles.displayBlock}>
                    {t("signUp.input.inputAgentPostCode", {
                      framework: "React",
                    })}
                  </span>
                  <input
                    name={"postcode"}
                    type={"number"}
                    value={freeelancer.address.postcode}
                    onChange={handleChangeInputAddressFreelancer}
                  />
                </div>
              </div>
              {/* </div> */}
              <div>
                <div
                  className={cn(
                    styles.displayFLexRow,

                    styles.checkBoxSignUp
                  )}
                >
                  <input
                    type="checkbox"
                    id="scales"
                    name="scales"
                    // defaultChecked
                    style={{ maxWidth: 40 }}
                    className={"mb-0"}
                    value={remmemberMe}
                    setValue={setRemmemberMe}
                  />
                  <label for="scales">
                    {t("signUp.remmemberMe", { framework: "React" })}
                  </label>
                  <button
                    onClick={registerAgent}
                    className={styles.buttonRegisterAgent}
                  >
                    {t("signUp.createAccount", { framework: "React" })}
                  </button>
                </div>
                {submit && (
                  <div className={"d-flex justify-content-center mt-2"}>
                    <Recaptcha
                      sitekey="6LfGKJcaAAAAAJ9DAcI7zuYIy_lnJXiYWRICATf7"
                      render="explicit"
                      verifyCallback={callbackFreelancer}
                    />
                  </div>
                )}
                <div className={cn(styles.bottomLine)}></div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
