import { useState } from "react";
import SecureLS from "secure-ls";
import cn from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import StarRatings from "react-star-ratings";
import { useParams } from "react-router-dom";
import styles from "../MobileSlider/styles.module.scss";
import noImage from './../../../sources/images/no_image.jpg'

const ls = new SecureLS();

export const MobileSlider = () => {
  const {id} = useParams();
  const [t, i18next] = useTranslation("common");
  const [slider, setSlider] = useState(0);
  const hotelData = useSelector((state) => state);

  const changeSlidePrev = () => {
    if (slider > 0) {
      setSlider(slider - 1);
    }
  };

  const changeSlideNext = () => {
    if (slider < sliderData.length) {
      setSlider(slider + 1);
    }
  };

  return (
    <div className={styles.sliderMobile}>
      <div className={cn(styles.sliderGeneralImage, styles.displayFLexRow)}>
        {ls.get("lang") == "heb" ? (
          <div className={cn(styles.mobileSlider, styles.hebDirection)}>
            <a onClick={changeSlidePrev}>
              <i class="fas fa-chevron-right" style={{ size: 9 }}></i>
            </a>
            <div class={styles.containerSlider}>
              <img src={!hotelData.hotel.images ? sliderData[slider].img : hotelData.hotel.images[slider].replace(
                    "{size}",
                    "640x400"
                  )} alt="Snow" width="100%" />
              <div class={styles.centered}>
                <div class={styles.textBlock}>
                  <h3>{hotelData.hotel.name
                  ? hotelData.hotel.name
                  : t("missingData", {
                      framework: "React",
                    })}</h3>
                  <p>{hotelData.hotel.address
                  ? hotelData.hotel.address
                  : t("missingData", {
                      framework: "React",
                    })}</p>
                  <StarRatings
                    rating={1.5}
                    starRatedColor="#C59944"
                    numberOfStars={5}
                    starDimension={"26px"}
                    starSpacing={"1px"}
                  />
                </div>
              </div>
            </div>
            <a onClick={changeSlideNext}>
              <i class="fas fa-chevron-left" style={{ size: 9 }}></i>
            </a>
          </div>
        ) : (
          <div className={cn(styles.mobileSlider, styles.enDirection)}>
            <a onClick={changeSlidePrev}>
              <i class="fas fa-chevron-left" style={{ size: 9 }}></i>
            </a>
            <div class={styles.containerSlider}>
              <img src={sliderData[slider].img} alt="Snow" width="100%" />
              <div class={styles.centered}>
                <div class={styles.textBlock}>
                  <h3>{hotelData.hotel.name
                  ? hotelData.hotel.name
                  : t("missingData", {
                      framework: "React",
                    })}</h3>
                  <p>{hotelData.hotel.address
                  ? hotelData.hotel.address
                  : t("missingData", {
                      framework: "React",
                    })}</p>
                  <StarRatings
                    rating={1.5}
                    starRatedColor="#C59944"
                    numberOfStars={5}
                    starDimension={"26px"}
                    starSpacing={"1px"}
                  />
                </div>
              </div>
            </div>
            <a onClick={changeSlideNext}>
              <i class="fas fa-chevron-right" style={{ size: 9 }}></i>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileSlider;

const sliderData = [
  {
    title: "Photo 1",
    img: noImage,
  },
  {
    title: "Photo 2",
    img: noImage
  }
];
