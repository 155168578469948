import React, { useState } from "react";
import SecureLS from "secure-ls";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import {Spin} from 'antd';

import CurrencyTile from "./CurrencyTile";
import LoginForm from "./LoginForm";
import pSpace from "../images/p-space.svg";
import logoExchnage from "../images/logo-exchange.svg";
import logoLan from "../images/logo-lan.svg";
import logo from "../images/logo.svg";
import { checkUser, unsetUser } from "../actions/user";
import { Link, useParams } from "react-router-dom";
import { getChangeCurrency } from "../actions/hotels";

const ls = new SecureLS();

const setLanguages = [
  {
    code: "en",
    name: "שפה אנגלית"
  },
  {
    code: "he",
  name: " שפה עברית"
  }
 
];

const setCurrency = {
  currency1: [
    {
      code: "USD",
      name: "דולר ארהב",
      symbol: "$",
    },
    {
      code: "EUR",
      name: "אירו",
      symbol: "€",
    },
    {
      code: "ILS",
      name: "שקל חדש",
      symbol: "₪",
    },
  ],

  currency2: [
    {
      code: "USD",
      name: "דולר ארהב",
      symbol: "$",
    },
    {
      code: "EUR",
      name: "אירו",
      symbol: "€",
    },
    {
      code: "ILS",
      name: "שקל חדש",
      symbol: "₽",
    },
  ],
};

export const Navbar = () => {
  const history = useHistory();
  const user = useSelector((state) => state);
  const [t, i18next] = useTranslation("common");
  const [lang, setLang] = useState("he");
  const [currency, setCurrencySetting] = useState("ILS");
  document.body.dir = i18next.dir();
  const [loader, setLoader] = useState(false);
  const {id} = useParams();

  const data = useSelector((state)=> state)
  const [dataHotels, setDataHotels] = useState(data)

  const changeLang = (language) => {
    i18next.changeLanguage(language);
    ls.set("lang", language);
    setLang(language);
    if (language == "he") {
      document.getElementsByTagName("div")[0].setAttribute("dir", "rtl");
    } else {
      document.getElementsByTagName("div")[0].setAttribute("dir", "ltr");
    }
  };

  const changeCurrency = (currentCurrency, symbol) => {
    ls.set("currency", { currency: currentCurrency, symbol: symbol });
    setLoader(true)
    handlerChangeCurrency(currentCurrency)

  };

  const handlerChangeCurrency = async (currentCurrency) => {
    await getChangeCurrency({...data.searchParameters, currency:currentCurrency  }, id)
    setLoader(false)
    setCurrencySetting(currentCurrency);
    setDataHotels(data)
  }

  function handleLogoClick() {
    history.push("/");
  }
  useEffect(() => {
    checkUser();
  }, []);

  return (
    <nav>
      <div className="container-fluid">
        <ul className="nav-links">
          <li>
            <div className="item">
              <img src={pSpace} alt="p_space" />
              {!user.user.id ? (
                <a href="/">{t("header.menu.login", { framework: "React" })}</a>
              ) : (
                <span
                  onClick={unsetUser}
                  style={{
                    cursor: "pointer",
                    margin: "0 4px",
                    fontWeight: 500,
                  }}
                >
                  {t("header.menu.logout", { framework: "React" })}
                </span>
              )}
            </div>
            {!user.user.id ? (
              <div className="mega-menu">
                <div className="container">
                  <LoginForm />
                </div>
              </div>
            ) : null}
          </li>
          <li>
            <div className="item">
              <img src={logoExchnage} alt="exchange" />
              <a href="/">{t("header.menu.valuta", { framework: "React" })}</a>
            </div>
            <div className="drop-menu">
              <h3>{t("header.menu.valuta", { framework: "React" })}</h3>
              <div className="content">
                {loader ?<div className={"d-flex justify-content-center"}> <Spin/></div> : <>
                {/* <h6></h6>
                <input type="text" placeholder="שם מטבע" /> */}
                <h5>
                  {t("header.menu.listCurrency.title1", { framework: "React" })}{" "}
                </h5>
                <ul>
                  {setCurrency.currency1.map((element) => (
                    <CurrencyTile
                    key={`currency-${element.name}`}
                      name={element.name}
                      code={element.code}
                      symbol={element.symbol}
                      onClick={changeCurrency}
                      currentValue={
                        currency
                          ? ls.get("currency").currency
                          : "ILS"
                      }
                    />
                  ))}
                </ul>
    
                </>}
              </div>
            </div>
          </li>
          <li>
            <div className="item">
              <img src={logoLan} alt="lan" />
              <a href="/">
                {t("header.menu.languages", { framework: "React" })}
              </a>
            </div>
            <div className="drop-menu">
              <h3>{t("header.menu.languages", { framework: "React" })}</h3>
              <div className="content">
                <ul>
                  {setLanguages.map((lang) => (
                    <CurrencyTile
                    key={`lang-${lang.name}`}
                      name={lang.name}
                      code={lang.code}
                      onClick={changeLang}
                      currentValue={ls.get("lang") ? ls.get("lang") : "he"}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </li>
          <li className="big-item">
            <Link to="/filter">
              {t("header.menu.hotels", { framework: "React" })}
            </Link>
          </li>
          <li className="big-item">
            <Link to="/">{t("header.menu.about", { framework: "React" })}</Link>
          </li>
          <li className="big-item">
            <Link to="/">
              {t("header.menu.contactUs", { framework: "React" })}
            </Link>
          </li>
        </ul>
        <img src={logo} alt="logo" className="logo" onClick={handleLogoClick} />
      </div>
    </nav>
  );
};

export default Navbar;
