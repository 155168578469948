import React from "react";
import SearchDetails from "../components/SearchDetails";
import Carousel from "react-elastic-carousel";
import { useSelector } from "react-redux";
import SecureLS from "secure-ls";

import i18next from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import blueHotel from "../images/blue-hotel.svg";
import partner1 from "../images/partner1.svg";
import partner2 from "../images/partner2.svg";
import partner3 from "../images/partner3.svg";
import partner4 from "../images/partner4.svg";
import partner5 from "../images/partner5.svg";

const ls = new SecureLS();

export default function HomeScreen() {
  const [t, i18next] = useTranslation("common");
  
  const breakPoints = [
    { width: 996, itemsToShow: 5 },
    { width: 1200, itemsToShow: 5 },
  ];
  const user = useSelector((state) => state);

  return (
    <>
      <div className="home-banner">
        <div className="overlay-bg" />
        <div className="container">
          <h3 className={ls.get("lang")== 'heb'? 'dark': 'light'}>{t("homePage.header", { framework: "React" })}</h3>
          <h4 className={ls.get("lang")== 'heb'? 'dark': 'light'}>{t("homePage.subTitle", { framework: "React" })}</h4>
        </div>
      </div>
      <div className="find-us-section">
        <div className="container">
          <img src={blueHotel} alt="blue hotel" />
          <p>
          {t("homePage.search.title", { framework: "React" })}
            <span>{t("homePage.search.subTitle", { framework: "React" })}</span>
          </p>
        </div>
      </div>
      <SearchDetails />
      <div className="partners-section">
        <div className="container">
          <h2>{t("homePage.partners.title", { framework: "React" })}</h2>
          <hr />
          <h3>{t("homePage.partners.subTitle", { framework: "React" })} </h3>
          <Carousel
            breakPoints={breakPoints}
            className="home"
            enableAutoPlay={true}
            isRTL={true}
          >
            <img src={partner1} alt="partner1" />
            <img src={partner2} alt="partner2" />
            <img src={partner3} alt="partner3" />
            <img src={partner4} alt="partner4" />
            <img src={partner5} alt="partner5" />
            <img src={partner1} alt="partner1" />
            <img src={partner2} alt="partner2" />
            <img src={partner3} alt="partner3" />
            <img src={partner4} alt="partner4" />
            <img src={partner5} alt="partner5" />
          </Carousel>
        </div>
      </div>
    </>
  );
}
