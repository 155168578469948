import React, { useState, useRef, useEffect } from "react";
import ReviewRate from "../components/ReviewRate";
import Tripadvisor from "../images/tripadvisor.png";
import StarRatings from "react-star-ratings";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { FaShareAlt } from "react-icons/fa";
import { SiTripadvisor } from "react-icons/si";

import { items, setCurrency, sliderData } from "../data";
import { useTranslation, initReactI18next } from "react-i18next";

import Breakfast from "../images/breakfast.svg";
import CancelPayment from "../images/cancel_payment.svg";
import HotelPay from "../images/hotel_pay.svg";
import MapContainer from "./MapContainer";

import Carousel from "react-elastic-carousel";
import SecureLS from "secure-ls";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ls = new SecureLS();

export default function RoomInfo() {
  const breakPoints = [
    { width: 996, itemsToShow: 6 },
    { width: 1200, itemsToShow: 6 },
  ];
  const [t, i18next] = useTranslation("common");
  const [favorite, setFavorite] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const hotelData = useSelector((state) => state);
  const [amenities, setAminities] = useState([]);
  const [isGeneralAmenities, setIsGeneralAmenities] = useState(false);
  const { id } = useParams();

  const changeIndexHandler = (mode) => {
    if (mode === "prev") {
      if (activeIndex == 0) {
      } else {
        setActiveIndex(activeIndex - 1);
      }
    } else {
      if (activeIndex < hotelData.hotel.images.length - 1) {
        setActiveIndex(activeIndex + 1);
      }
    }
  };
  const setAmenitiesGroup = [
    {
      name: "Swimming pool",
      heb: "בריכת שחייה",
      icon: "",
    },
    {
      name: "Beach",
      heb: "חוף ים ",
      icon: "",
    },
    {
      name: "Air conditioning",
      heb: "מיזוג אוויר ",
      icon: "",
    },
    {
      name: "Family/Kid Friendly",
      heb: "מתאים למשפחות וילדים ",
      icon: "",
    },
    {
      name: "Free cancellation",
      heb: "ביטול חינם",
      icon: "",
    },
  ];
  const getActualAmenityGroup = () => {
    let amenity = setAmenitiesGroup.map((type) =>
      hotelData.hotel.amenity_groups.filter((amen) =>
        amen.amenities.includes(type.name)
      )
    );
    amenity = amenity.filter((amen) => amen.length > 0);
    if (amenity.length == 5) {
      setIsGeneralAmenities(true);
      setAminities(amenity);
    } else {
      const amenitiesAll = hotelData.hotel.amenity_groups.slice(0, 5);
      setIsGeneralAmenities(false);
      setAminities(amenitiesAll);
    }
  };
  const changeImageHandler = (value) => {
    setActiveIndex(value);
  };

  const ViewAll = () => {
    window.scrollTo(0, document.querySelector(".hotel-devices").offsetTop - 50);
  };

  const ViewRooms = () => {
    window.scrollTo(0, document.querySelector(".room-filter").offsetTop - 50);
  };

  const itemsPerPage = 6;
  const carouselRef = useRef(null);
  let images = hotelData.hotel.images;
  const totalPages = Math.ceil(images.length / itemsPerPage);
  let resetTimeout;

  useEffect(() => {
    getActualAmenityGroup();
  }, []);

  return (
    <div className="room-info">
      <div className="container">
        <div className="row">
          <div className="col-4">
            <div className="choose-room">
              <div>
                {ls.get('lang') == 'he' ? <h2>
                  <span>
                    {t("filter.cardHotel.from", { framework: "React" })} -{" "}
                  </span>

                  {hotelData.hotels[id].rates[0].payment_options
                    .payment_types[0].show_amount
                    ? hotelData.hotels[id].rates[0].payment_options
                      .payment_types[0].show_amount
                    : t("missingData", {
                      framework: "React",
                    })}
                  {' '}
                  {ls.get("currency").currency
                    ? setCurrency[ls.get("currency").currency]
                    : "₪"}

                </h2> :
                  <h2>
                    <span>
                      {t("filter.cardHotel.from", { framework: "React" })} -{" "}
                    </span>
                    {hotelData.hotels[id].rates[0].payment_options
                      .payment_types[0].show_amount
                      ? hotelData.hotels[id].rates[0].payment_options
                        .payment_types[0].show_amount
                      : t("missingData", {
                        framework: "React",
                      })}
                    {ls.get("currency").currency
                      ? setCurrency[ls.get("currency").currency]
                      : "₪"}
                  </h2>}
                <button onClick={ViewRooms}>
                  {" "}
                  {t("rooms.buttonSelectRoom", { framework: "React" })}
                </button>
              </div>

            </div>
          </div>
          <div className="col">
            <div className="sec-info">
              <div className="info-item">
                <div>
                  <h6>
                    {" "}
                    {hotelData.hotel.star_certificate
                      ? hotelData.hotel.star_certificate
                      : t("missingData", {
                        framework: "React",
                      })}{" "}
                  </h6>
                  <StarRatings
                    rating={
                      hotelData.hotel.star_certificate
                        ? hotelData.hotel.star_certificate
                        : 0
                    }
                    starRatedColor="#00aa6c"
                    numberOfStars={5}
                    starDimension={"16px"}
                    starSpacing={"1px"}
                    svgIconPath={
                      "M21.21,0a21.21,21.21,0,1,0,21.2,21.21A21.23,21.23,0,0,0,21.21,0Z"
                    }
                  />
                  <SiTripadvisor size="20" className="me-1 mt-1" />
                </div>
                <StarRatings
                  rating={
                    hotelData.hotel.star_rating
                      ? hotelData.hotel.star_rating
                      : 0
                  }
                  starRatedColor="#C59944"
                  numberOfStars={5}
                  starDimension={"26px"}
                  starSpacing={"1px"}
                />
              </div>
              <div className="info-item mt-3">
                <div>
                  <div className="icon">
                    <FaShareAlt size="24" color="#085F7B" />
                  </div>
                  <div className="icon">
                    {favorite ? (
                      <FaHeart
                        size="24"
                        color="#CB0808"
                        onClick={() => setFavorite(!favorite)}
                      />
                    ) : (
                      <FaRegHeart
                        size="24"
                        color="#CB0808"
                        onClick={() => setFavorite(!favorite)}
                      />
                    )}
                  </div>
                </div>
                <h3 style={{ color: "#085f7b" }}>
                  {hotelData.hotel.name
                    ? hotelData.hotel.name
                    : t("missingData", {
                      framework: "React",
                    })}
                </h3>
              </div>
              <div className="info-item mt-3">
                <h4>
                  {" "}
                  {hotelData.hotel.distanceFromCenter
                    ? `${hotelData.hotel.distanceFromCenter}  ${t(
                      "rooms.kmFromCenter",
                      { framework: "React" }
                    )}`
                    : t("missingData", {
                      framework: "React",
                    })}{" "}
                </h4>
                <h4>
                  {hotelData.hotels[id].rates[0].address != undefined
                    ? hotelData.hotels[id].rates[0].address
                    : t("missingData", {
                      framework: "React",
                    })}
                </h4>
              </div>
              <hr className="info-hr" />
              <div className="info-item mt-2">
                <button onClick={ViewAll}>
                  {t("rooms.buttonCompleteList", { framework: "React" })}{" "}
                </button>
                <div className="extras">



                {amenities.map((amenity) => (
                    <div className="d-flex align-items-center me-2">
                      {isGeneralAmenities ? (
                        <h5>
                          {ls.get("lang") == "he" ? amenity.heb : amenity.name}
                        </h5>
                      ) : (
                        <h5>{amenity.amenities[0]}</h5>
                      )}
                    </div>
                  ))}



                  {/* {hotelData.hotels[id].rates[0].meal &&
                    !hotelData.hotels[id].rates.find((room) => room.meal == "nomeal") ? (
                    <div className="d-flex align-items-center me-2">
                      <img src={Breakfast} alt="" />
                      <h5>
                        {t("filter.cardHotel.breakfast", {
                          framework: "React",
                        })}
                      </h5>
                    </div>
                  ) : <div>
                    <img src={Breakfast} alt="" />
                    <h5>

                      {t("filter.cardHotel.roomOnly", {
                        framework: "React",
                      })}
                    </h5>

                  </div>}
                  {hotelData.hotels[id].rates[0].payment_options.payment_types[0]
                    .cancellation_penalties.free_cancellation_before ? (
                    <div className="d-flex align-items-center me-2">
                      <img src={CancelPayment} alt="" />
                      <h5>

                        {t("filter.cardHotel.freeCancellation", {
                          framework: "React",
                        })}
                      </h5>
                    </div>
                  ) : null}
                  {hotelData.hotels[id].rates[0].payment_options.payment_types[0]
                    .type ? (
                    <div className="d-flex align-items-center me-2">
                      <img src={HotelPay} alt="" />
                      <h5>

                        {t("filter.cardHotel.payment", {
                          framework: "React",
                        })}{" "}
                        {hotelData.hotels[id].rates[0].payment_options.payment_types[0]
                          .type == "deposit" && ls.get('lang') !== 'he'
                          ? "deposit"
                          : null}
                        {hotelData.hotels[id].rates[0].payment_options.payment_types[0]
                          .type == "deposit" && ls.get('lang') == 'he'
                          ? " לפקדון"
                          : null}
                      </h5>
                    </div>
                  ) : null} */}

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-4">
            <div className="room-review">
              <h4>
                {/* {ls.get('lang') == 'he'? 'טוב מאוד': 'Very good'} */}
                {t("missingData", {
                  framework: "React",
                })}
              </h4>
              <h5> {t("rooms.markerGuests", { framework: "React" })} </h5>
              <div
                className={
                  ls.get("lang") !== "he"
                    ? "bookmark rightDir"
                    : "bookmark leftDir"
                }
              >
                0
              </div>
            </div>
            <div className="room-awards">
              <img src={Tripadvisor} alt="Tripadvisor" />
              <h4 className="fw-medium">
                {ls.get("lang") == "he" ? "זוכת הפרס" : "Winner of the award"}
              </h4>
              <span>
                {" "}
                {t("missingData", {
                  framework: "React",
                })}
              </span>
            </div>
            <div className="room-guest-review">
              <h4>{t("rooms.guestReview.title", { framework: "React" })} </h4>
              <ReviewRate
                title={t("rooms.guestReview.breakfast", { framework: "React" })}
                subTitle="ארוחת בוקר טעימה"
              />
              <ReviewRate
                title={t("rooms.guestReview.service", { framework: "React" })}
                subTitle="שירות מעולה"
              />
            </div>
            <div className="mt-2 d-flex">
              <div className="w-100 map-container">
                <MapContainer height="265px" />
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="info-images">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                {/* <div className="carousel-indicators">
                  {hotelData.hotel.images
                    ? hotelData.hotel.images.map((image, index) =>
                        // index <= 6 ? (
                          <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to={index}
                            className={activeIndex === index ? "active" : ""}
                            aria-current="true"
                            aria-label="Slide 1"
                            onClick={() => setActiveIndex(2)}
                          ></button>
                        // ) : null
                      )
                    : sliderData.map((image, index) => (
                        <button
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={index}
                          className={activeIndex === index ? "active" : ""}
                          aria-current="true"
                          aria-label="Slide 1"
                          onClick={() => setActiveIndex(2)}
                        ></button>
                      ))} */}
                {/* </div> */}
                <div className="carousel-inner">
                  {hotelData.hotel.images
                    ? hotelData.hotel.images.map((image, index) => (
                      <div
                      key={`carImages-${index}`}
                        className={
                          activeIndex === index
                            ? "carousel-item active"
                            : "carousel-item"
                        }
                      >
                        <img
                          src={image.replace("{size}", "640x400")}
                          className="d-block w-100"
                          alt="img"
                        />
                      </div>
                    ))
                    : sliderData.map((image, index) => (
                      <div
                      key={`carImagesNo-${index}`}
                        className={
                          activeIndex === index
                            ? "carousel-item active"
                            : "carousel-item"
                        }
                      >
                        <img
                          src={image.replace("{size}", "640x400")}
                          className="d-block w-100"
                          alt="img"
                        />
                      </div>
                    ))}
                </div>
                {activeIndex > 0 && (
                  <button
                    className="carousel-control-prev"
                    onClick={() => changeIndexHandler("prev")}
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                )}
                {activeIndex < hotelData.hotel.images.length - 1 && (
                  <button
                    className="carousel-control-next"
                    onClick={() => changeIndexHandler("next")}
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                )}
              </div>
              <div className="row">
                <Carousel
                  ref={carouselRef}
                  breakPoints={breakPoints}
                  className="mini-slider"
                  showArrows={false}
                  isRTL={true}
                  enableTilt={false}
                  onNextEnd={({ index }) => {
                    clearTimeout(resetTimeout);
                    if (index + 1 === totalPages) {
                      resetTimeout = setTimeout(() => {
                        carouselRef.current.goTo(0);
                      }, 1500); // same time
                    }
                  }}
                  itemsToShow={itemsPerPage}
                >
                  {hotelData.hotel.images
                    ? hotelData.hotel.images.map((image, index) => (
                      // <div key={index} className="col px-md-0 mt-1 ps-3" onClick={() => changeImageHandler(image.substring(14,20).toString())}>

                      <div
                      key={`carImagesSmall-${index}`}
                        onClick={() => changeImageHandler(index)}
                        style={{
                          background:
                            `url(` +
                            image.replace("{size}", "640x400") +
                            `) no-repeat`,
                          width: "100%",
                          height: "120px",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    ))
                    : sliderData.map((image, index) => (
                      // <div key={index} className="col px-md-0 mt-1 ps-3" onClick={() => changeImageHandler(image.substring(14,20).toString())}>
                      <div
                      key={`carImagesSmallNo-${index}`}
                        key={`romItem-${index}`}
                        onClick={() => changeImageHandler(index)}
                        style={{
                          background: `url(` + image + `) no-repeat`,
                          width: "100%",
                          height: "120px",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
