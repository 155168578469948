import styles from "../../DataPicker/styles.module.scss";
import cn from "classnames";
import { useState } from "react";
import { useSelector } from "react-redux";

export const InputNumber = ({ type }) => {
  const data = useSelector((state)=>state)
  let totalNumberGuests = 0;
  const checkNumberGuests = () => {
    if (data.searchParameters.guests) {
      data.searchParameters.guests.map((room) => {
        totalNumberGuests =
          totalNumberGuests + room.adults + room.children.length;
      });
    }
  };
  checkNumberGuests();
  
  const [details, setDetails] = useState({
    date: {},
    rooms: data.searchParameters.guests.length,
    persons: totalNumberGuests,
  });
  

  const handlerRooms = (key, action) => {
    if (details[key] > 0) {
      setDetails((prev) => ({
        ...prev,
        [key]: action == "plus" ? details[key] + 1 : details[key] - 1,
      }));
    }
  };
  
  const handlerPersons = (key, action) => {
    if (details[key] > 0) {
      setDetails((prev) => ({
        ...prev,
        [key]: action == "plus" ? details[key] + 1 : details[key] - 1,
      }));
    }
  };
  return (
    <div className={cn(styles.selectNumber)}>
      <div>
        <i
          class="fas fa-caret-up"
          style={{ transform: "translateY(7px)", color: "silver" }}
          onClick={
            type == "bed"
              ? () => handlerRooms("rooms", "plus")
              : () => handlerPersons("persons", "plus")
          }
        ></i>
        <div></div>
        <div>
          <i
            class="fas fa-caret-down"
            style={{ transform: "translateY(-7px)", color: "silver" }}
            onClick={
              type == "bed"
                ? () => handlerRooms("rooms", "minus")
                : () => handlerPersons("persons", "minus")
            }
          ></i>
        </div>
      </div>
      <div>
        <span className={styles.numberDetails}>
          {type == "bed" ? details.rooms : details.persons}
        </span>
        {type == "bed" ? (
          <span className={styles.numberDetails}>Persons</span>
        ) : (
          <span>Rooms</span>
        )}
      </div>
      {type == "bed" ? (
        <i class="fas fa-bed"></i>
      ) : (
        <i class="fas fa-user-friends"></i>
      )}
    </div>
  );
};
