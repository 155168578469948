import { api } from "../config";
import SecureLS from "secure-ls";

import store from "../utils/store";
import { actionTypes as hotelsActionTypes } from "../reducers/hotels";
import { actionTypes as hotelActionTypes } from "../reducers/hotel";
import { actionTypes as searchActionTypes } from "../reducers/search";
const ls = new SecureLS();

export const getAllHotels = async (searchParametrs) => {
  let result;
  try {
    const response = await api.post("river/api/regionSearch", searchParametrs);
    if (response.status == 200) {
      ls.set('searchParameters', searchParametrs)
      store.dispatch({
        type: hotelsActionTypes.SET,
        payload: response.data.hotels,
      });
      store.dispatch({ type: searchActionTypes.SET, payload: searchParametrs });
      result = response.data.hotels;
    }
  } catch (e) {
    console.log("Error:", e);
  }
  return result;
};

export const getOneHotel = async (id) => {
  const request = ls.get('searchParameters');
  delete request["query"];

  const params = { ...request, id: ls.get('hotelId') }
  try {
    const response = await api.post("river/api/hotelPage", { ...params });
   
    if (response.status == 200) {
      ls.set('searchOneHotel', id)
      store.dispatch({
        type: hotelActionTypes.SET,
        payload: response.data.hotel,
      });
    }
  } catch (e) {
    console.log("Error:", e);
  }
};

export const getChangeCurrency = async (searchParametrs, id) => {

  try {
    const response = await api.post("river/api/regionSearch", searchParametrs);

    if (response.status == 200) {
      store.dispatch({
        type: hotelsActionTypes.SET,
        payload: response.data.hotels,
      });
      store.dispatch({ type: searchActionTypes.SET, payload: searchParametrs });
    }
  } catch (e) {
    console.log("Error:", e);
  }
};
