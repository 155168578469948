import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import clock from "../images/clock.svg";

export default function HotelPolicy() {
  const [t, i18n] = useTranslation("common");
  const hotelData = useSelector((state) => state);
  return (
    <div className="hotel-policy">
      <div className="container">
        <h2>
          {t("rooms.hotelPolicy.title", {
            framework: "React",
          })}{" "}
        </h2>
        <hr />
        <h3>
          {" "}
          {t("rooms.hotelPolicy.subTitle", {
            framework: "React",
          })}
        </h3>
        <div className="row receive-room  mt-5">
          <div className="col-3">
            <div>
              <img src={clock} alt="clock" className={"ms-1 me-1"} />
              <h5 className="me-1">
                {t("rooms.hotelPolicy.receiveRoom", {
                  framework: "React",
                })}{" "}
              </h5>
            </div>
            <h6>
              {t("rooms.hotelPolicy.after", {
                framework: "React",
              })}{" "}
              {(hotelData.hotel.check_in_time).substring(0, 5)} PM
            </h6>
          </div>
          <div className="col">
            <div className="progress-container">
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "85%" }}
                ></div>
              </div>
              <h6 className="mt-2">{(hotelData.hotel.check_in_time).substring(0, 5)} PM </h6>
            </div>
          </div>
        </div>
        <div className="row return-room ">
          <div className="col-3">
            <div>
              <img src={clock} alt="clock" className={"ms-1 me-1"} />
              <h5 className="me-1">
                {t("rooms.hotelPolicy.returnRoom", {
                  framework: "React",
                })}{" "}
              </h5>
            </div>
            <h6>
              {t("rooms.hotelPolicy.after", {
                framework: "React",
              })}{" "}
              {(hotelData.hotel.check_out_time).substring(0, 5)}  AM
            </h6>
          </div>
          <div className="col">
            <div className="progress-container">
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "45%" }}
                ></div>
              </div>
              <h6 className="mt-2"> {(hotelData.hotel.check_out_time).substring(0, 5)} AM</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
