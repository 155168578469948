import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import pSpace from "../images/p-space.svg";
import visibleIcon from "../images/logo-visible.svg";
import notVisibleIcon from "../images/logo-not-visible.svg";

export default function TextField({ type, valueInput, nameInput, handleChangeInput }) {
  const [t, i18n] = useTranslation("common");
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="text-field">
      {type === "privateName" && (
         <div className="email-field">
         {/* <img src={pSpace} alt="אימייל" /> */}
        <input
        value={valueInput}
        name={nameInput}
        onChange={handleChangeInput}
          type="text"
          placeholder={t("header.signUpForm.placeholderName", {
            framework: "React",
          })}
        />
        </div>
      )}
      {type === "email" && (
        <div className="email-field">
          <img src={pSpace} alt="אימייל" />
          <input
           value={valueInput}
           name={nameInput}
           onChange={handleChangeInput}
            type="email"
            placeholder={t("header.signUpForm.placeholderEmail", {
              framework: "React",
            })}
          />
        </div>
      )}
      {type === "phone" && (
      <div className="email-field">
          {/* <img src={pSpace} alt="אימייל" /> */}
          <input
           value={valueInput}
           name={nameInput}
           onChange={handleChangeInput}
            type="phone"
            placeholder={t("header.signUpForm.phone", {
              framework: "React",
            })}
          />
        </div>
      )}
      {type === "password" && (
        <>
          <div className="password-field">
            <div className="icon">
              {isVisible ? (
                <img
                  src={visibleIcon}
                  alt="visible"
                  onClick={() => setIsVisible(false)}
                />
              ) : (
                <img
                  src={notVisibleIcon}
                  alt="not visible"
                  onClick={() => setIsVisible(true)}
                />
              )}
            </div>
            <input
             value={valueInput}
             name={nameInput}
             onChange={handleChangeInput}
              type={isVisible ? "text" : "password"}
              placeholder={t("header.signUpForm.placeholderPass", {
                framework: "React",
              })}
            />
          </div>
        </>
      )}
    </div>
  );
}
