import { useState } from "react";
import cn from "classnames";
import StarRatings from "react-star-ratings";
import { useTranslation } from "react-i18next";
import SecureLS from "secure-ls";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "../Slider/styles.module.scss";
import noImages from './../../../sources/images/no_image.jpg'

const ls = new SecureLS();

export const SliderHotel = () => {
  const [t, i18next] = useTranslation("common");
  const { id } = useParams();
  const [slider, setSlider] = useState(0);
  const hotelData = useSelector((state) => state);

  return (
    <div className={styles.slider}>
      <div className={cn(styles.sliderGeneralImage, styles.displayFLexRow)}>
        <div class={styles.containerSlider}>
          <img
            src={
              !hotelData.hotel.images
                ? sliderData[slider].img
                : hotelData.hotel.images[slider].replace(
                    "{size}",
                    "640x400"
                  )
            }
            alt="Snow"
            width="100%"
          />
          <div
            class={styles.centered}
            style={
              ls.get("lang") == "he"
                ? { transform: "translate( -3%, -50%)" }
                : { transform: "translate( 3%, -50%)" }
            }
          >
            <div class={styles.textBlock}>
              <h3>
                {hotelData.hotel.name
                  ? hotelData.hotel.name
                  : t("missingData", {
                      framework: "React",
                    })}
              </h3>
              <p>
               
                {hotelData.hotel.address
                  ? hotelData.hotel.address
                  : t("missingData", {
                      framework: "React",
                    })}
              </p>
              <StarRatings
                rating={
                  hotelData.hotel.star_rating
                    ? hotelData.hotel.star_rating
                    : 0
                }
                starRatedColor="#C59944"
                numberOfStars={5}
                starDimension={"26px"}
                starSpacing={"1px"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={cn(styles.displayFLexRow, styles.setSliderButton)}>
        {hotelData.hotel.images
          ? hotelData.hotel.images.map((element, index) => (
              <div className={cn(styles.sliderButton)} key={`slider-element${index}`}>
                <img
                  src={element.replace("{size}", "640x400")}
                  onClick={() => setSlider(index)}
                />
              </div>
            ))
          : sliderData.map((element, index) => (
              <div className={cn(styles.sliderButton)}>
                <img
                  src={element.img}
                  alt={element.title}
                  onClick={() => setSlider(index)}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

export default SliderHotel;

const sliderData = [
  {
    title: "Photo 1",
    img: noImages,
  },
  {
    title: "Photo 2",
    img: noImages
  }
];
