import React, { useState } from "react";
import SwitchItem from "./SwitchItem";
import navArrowUp from "../images/nav-arrow-up.svg";
import navArrowDown from "../images/nav-arrow-down.svg";

export default function FilterAccordion({ children, title }) {
  const [isOpen, setIsOpen] = useState(true);
  const contentClass = isOpen
    ? "content accordion-collapse show"
    : " accordion-collapse content collapse";
  return (
    <div className="filter-accordion mt-2">
      <div className="item">
        <h4>{title}</h4>
        <SwitchItem
          value={isOpen}
          setValue={setIsOpen}
          trueImg={navArrowUp}
          falseImg={navArrowDown}
          title=""
        />
      </div>
      <div className={contentClass}>{children}</div>
    </div>
  );
}
