import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import SecureLS from "secure-ls";
import AsDone from "../images/as_done.svg";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";

const ls = new SecureLS();

export default function HotelMessage() {
  const [isOpen, setIsOpen] = useState(false);
  const contentVisible = isOpen ? "content" : "content disabled";
  const [t, i18n] = useTranslation("common");
  const dataHotel = useSelector((state) => state);
  return (
    <div className="hotel-message mt-4">
      <div className="title">
        <div>
          <img src={AsDone} alt="As Done" className={"me-2"} />
          <h3>
            {t("book.orderDetails.informationFromHotel.title", {
              framework: "React",
            })}{" "}
          </h3>
        </div>
        {isOpen ? (
          <FaChevronUp
            size="24"
            color="#085F7B"
            onClick={() => setIsOpen(false)}
          />
        ) : (
          <FaChevronDown
            size="24"
            color="#085F7B"
            onClick={() => setIsOpen(true)}
          />
        )}
      </div>
      <div className={contentVisible}>
        {dataHotel.hotel.description_struct.map((element) => (
          <>
            <h6>
              {element.title
                ? element.title
                : t("missingData", {
                  framework: "React",
                })}{" "}
            </h6>
            <p>
              {element.paragraphs
                ? element.paragraphs
                : t("missingData", {
                  framework: "React",
                })}{" "}
            </p>
          </>
        ))}
      </div>
    </div>
  );
}
