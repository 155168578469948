import React, { useState } from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SecureLS from "secure-ls";

const ls = new SecureLS();

export default function HotelDescription() {
  const [t, i18next] = useTranslation("common");
  const { id } = useParams();
  const hotelData = useSelector((state) => state);

  const checkInternet = () => {
    const isHasInternet = hotelData.hotel.amenity_groups.find(
      (amenity) => amenity.group_name == "Internet"
    );
    return isHasInternet.amenities.includes("Free Wi-Fi") ? true : false;
  };
  return (
    <div className="hotel-description">
      <div className="container">
        <div className="row title">
          <div className="col-8">
            <button>
              {t("rooms.hotelDescription.buttonTranslation", {
                framework: "React",
              })}{" "}
            </button>
          </div>
          <div className="col">
            <h2 className={ls.get('lang')== 'he' ? "text-end" : "text-start"}>
              {t("rooms.hotelDescription.title", { framework: "React" })}
            </h2>
          </div>
        </div>
        <div  className={ls.get('lang')== 'he' ? "text-end row mt-3 content" : "text-start row mt-3 content"}>
          <div className="col-8">
            <h4>
              {t("rooms.hotelDescription.hotelInfo.propertyLocation", {
                framework: "React",
              })}
            </h4>
            <p>
              {hotelData.hotel.address
                ? hotelData.hotel.address
                : t("missingData", {
                    framework: "React",
                  })}
            </p>
            <h4>
              {t("rooms.hotelDescription.hotelInfo.description", {
                framework: "React",
              })}
            </h4>
            <p>
              {hotelData.hotel.description_struct
                ? hotelData.hotel.description_struct.map((descr, index) => (
                    <div key={`decsription-${index}`}>
                      <h4></h4>
                      <p>{descr.paragraphs}</p>
                    </div>
                  ))
                : t("missingData", {
                    framework: "React",
                  })}
            </p>
          </div>
          <div className="col">
            <h4>
              {t("rooms.hotelDescription.hotelInfo.title", {
                framework: "React",
              })}
            </h4>
            <p>
              {t("rooms.hotelDescription.hotelInfo.yearsConstruction", {
                framework: "React",
              })}
              :{" "}
              <span>
                {hotelData.hotel.facts.year_built
                  ? hotelData.hotel.facts.year_built
                  : t("missingData", {
                      framework: "React",
                    })}
              </span>{" "}
              <br />
              {t("rooms.hotelDescription.hotelInfo.yearRenovation", {
                framework: "React",
              })}
              :{" "}
              <span>
                {hotelData.hotel.facts.year_renovated
                  ? hotelData.hotel.facts.year_renovated
                  : t("missingData", {
                      framework: "React",
                    })}
              </span>
              <br />
              <br />
              {t("rooms.hotelDescription.hotelInfo.socketType", {
                framework: "React",
              })}
              :{" "}
              <span>
                {t("missingData", {
                  framework: "React",
                })}
              </span>{" "}
              <br />
              <span>
                {hotelData.hotel.facts.electricity.voltage
                  ? `${hotelData.hotel.facts.electricity.voltage} V / ${hotelData.hotel.facts.electricity.voltage} Hz`
                  : t("missingData", {
                      framework: "React",
                    })}
              </span>{" "}
              <br />
              <span>
                {" "}
                {t("missingData", {
                  framework: "React",
                })}
              </span>
              <br />
              <br />
              {t("rooms.hotelDescription.hotelInfo.roomsNumber", {
                framework: "React",
              })}
              :{" "}
              <span>
                {hotelData.hotel.facts.rooms_number
                  ? hotelData.hotel.facts.rooms_number
                  : t("missingData", {
                      framework: "React",
                    })}{" "}
                {ls.get('lang')  == 'he' ? 'חדרים': 'rooms'}
              </span>{" "}
              <br />
              {t("rooms.hotelDescription.hotelInfo.internet", {
                framework: "React",
              })}
              : <span>{checkInternet() ? "Free Wi-Fi" : "-"}</span>
            </p>
            <img
              src={hotelData.hotel.images[1].replace("{size}", "640x400")}
              alt="hotel1"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
