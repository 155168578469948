import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import SecureLS from "secure-ls";
import { useSelector } from "react-redux";
import iconTitle from "../../sources/images/iconTitle.png";
import iconRecTitle from "../../sources/images/rectangleTitle.png";
import { useParams } from "react-router-dom";
import { Spin } from "antd";

import styles from "./styles.module.scss";
import SliderHotel from "./Slider";
import TimeLeftVacation from "./CardTimeLeft";
import DatePickerHotel from "./DataPicker";
import devices from "../../data";
import MobileSlider from "./MobileSlider";

const ls = new SecureLS();

export const HotelPage = () => {
  const { id } = useParams();
  const hotelData = useSelector((state) => state);
  const [loader, setLoader] = useState(false);
  const [t, i18next] = useTranslation("common");
  let newDevices = [];
  function formatTitle(input) {
    var words = input.split(" ");
    for (var i = 0; i < words.length; i++) {
      words[i] = words[i].substr(0, 1).toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
  }

  const checkDevices = () => {
    newDevices = devices.filter((element) =>
      hotelData.hotel.serp_filters.includes(element.idName)
    );
  };
  checkDevices();

  return (
    <>
      {loader ? (
        <div className={cn(styles.headerBlockHotelPage)}>
          <Spin />
        </div>
      ) : (
        <>
          <div className={cn(styles.headerBlockHotelPage)}>
            <div>
              <h2>
                {t("hotelPage.orderDetails", { framework: "React" })} |{" "}
                {hotelData.hotel.name
                  ? hotelData.hotel.name
                  : t("missingData", {
                      framework: "React",
                    })}
              </h2>
            </div>
            <div className={cn(styles.displayFLexRow, styles.contentBlock)}>
              <SliderHotel />
              <MobileSlider />
              <div>
                <div
                  className={
                    ls.get("lang") == "he"
                      ? styles.descriptionCardHotel
                      : styles.descriptionCardHotelLTR
                  }
                >
                  <h5>
                    
                    <img
                      src={iconTitle}
                      className={"me-1 ms-1"}
                      style={{ width: 35 }}
                    />{" "}
                    
                    {hotelData.hotel.name != undefined
                      ? hotelData.hotel.name
                      : t("missingData", {
                          framework: "React",
                        })}
                  </h5>
                  <p>
                    {t("address", {
                      framework: "React",
                    })}{" "}
                    {hotelData.hotels[id].rates[0].address != undefined
                      ? hotelData.hotels[id].rates[0].address
                      : t("missingData", {
                          framework: "React",
                        })}
                    {", "}
                    {t("phone", {
                      framework: "React",
                    })}{" "}
                    {hotelData.hotel.phone != undefined
                      ? hotelData.hotel.phone
                      : t("missingData", {
                          framework: "React",
                        })}
                  </p>
                  <p>
                    <img
                      src={iconRecTitle}
                      className={"me-1 ms-1"}
                      style={{ width: 25 }}
                    />{" "}
                    {t("hotelPage.orderStatus", { framework: "React" })}
                  </p>
                </div>
                <div className={cn(styles.datepickerHotel)}>
                  <DatePickerHotel />
                </div>
              </div>
            </div>
          </div>
          <div
            className={cn(
              styles.displayFLexRow,
              styles.contentBlock,
              styles.transformBlock
            )}
          >
            <div className={cn(styles.displayFLexColumn)}>
              <div></div>
              <div className={styles.hotelDevicesGrid}>
                <div className="hotel-devices">
                  <div className="container">
                    <h2>
                      {t("rooms.hotelDevices.title", {
                        framework: "React",
                      })}
                    </h2>
                    <hr />
                    <h3>
                      {t("rooms.hotelDevices.subTitle", {
                        framework: "React",
                      })}
                    </h3>
                    <div className="devices-grid row">
                      {devices.map((device) => (
                        <div key={device.id} className="col-2">
                          {device.icon ? (
                            <img
                              src={device.icon}
                              alt=""
                              className={"me-2 ms-2"}
                            />
                          ) : (
                            <i
                              className={device.title}
                              style={{ color: "#c59944", fontSize: "24px" }}
                            />
                          )}
                          <h4 className="me-2">
                            {ls.get("lang") == "he"
                              ? device.title
                              : device.titleEn}
                          </h4>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cn(styles.displayFLexColumn)}>
              <div className={styles.hotelDevices}>
                <TimeLeftVacation />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
