import React, { useState } from "react";
import i18next from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import SecureLS from "secure-ls";

import FilterAccordion from "./FilterAccordion";
import SwitchItem from "./SwitchItem";
import { FaStar, FaRegHeart, FaHeart } from "react-icons/fa";
import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import { Slider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import favorite from '../images/favorite.svg';
import switchOn from "../images/switch-on.svg";
import switchOff from "../images/switch-off.svg";

const ls = new SecureLS();

export default function FilterDetails() {
  const [t, i18next] = useTranslation("common");

  const [filterParametrs, setFilterParametrs] = useState({
    hotelName: "",
    price: "",
    location: 0,
    meal: {
      sleepOnly: false,
      breakfast: false,
      allMeals: false,
      allInclusive: false,
      morningEvening: false,
      starRating: 0,
      review: 0,
    },

    roomFacilities: {
      airConditioning: false,
      privateBath: false,
      windowInRoom: false,
      kitchenette: false,
      viewFromWindow: false,
      terrace: false,
    },
    landmarks: {
      temple: false,
      hall: false,
      stadium: false,
    },
    typeAccomodation: {
      hotels: false,
      hostels: false,
      apartaments: false,
      guestHouse: false,
      villas: false,
      ruralHosting: false,
    },
  });

  const [filterFavorite, setFilterFavorite] = useState(false);
  const [distanceFromCenter, setDistanceFromCenter] = useState(1);
  const [minPrice, setMinPrice] = useState(1);
  const [maxPrice, setMaxPrice] = useState(50);
  /* Star Rating */
  const [starRating, setStarRating] = useState(5);
  /* Review */
  const [review, setReview] = useState("+9");

  const useStyles = makeStyles({
    root: {
      color: "#C59944",
    },
  });

  const classes = useStyles();

  const updatePricesHandler = (newValue) => {
    if (newValue[0] <= newValue[1] - 1) {
      setMinPrice(newValue[0]);
      setMaxPrice(newValue[1]);
    }
  };

  const setStateTypeAccomodation = (key) => {
    setFilterParametrs({
      ...filterParametrs,
      typeAccomodation: {
        ...filterParametrs.typeAccomodation,
        [key]: !filterParametrs.typeAccomodation[key],
      },
    });
  };

  const setStateLandmarks = (key) => {
    setFilterParametrs({
      ...filterParametrs,
      landmarks: {
        ...filterParametrs.landmarks,
        [key]: !filterParametrs.landmarks[key],
      },
    });
  };

  const setStateRoomFacilities = (key) => {
    setFilterParametrs({
      ...filterParametrs,
      roomFacilities: {
        ...filterParametrs.roomFacilities,
        [key]: !filterParametrs.roomFacilities[key],
      },
    });
  };

  const setStateMeal = (key) => {
   
    setFilterParametrs({
      ...filterParametrs,
      meal: {
        ...filterParametrs.meal,
        [key]: !filterParametrs.meal[key],
      },
    });
  };

  return (
    <div className="filter-details">
      <h4>
        {t("filter.filterSearchResult.filterExcesses.title", {
          framework: "React",
        })}
      </h4>
      <div className="filter-favorite">
        <h4>
          {t("filter.filterSearchResult.filterExcesses.title", {
            framework: "React",
          })}{" "}
        </h4>
        {filterFavorite ? (
          <FaHeart
            color="red"
            onClick={() => setFilterFavorite(!filterFavorite)}
          />
        ) : (
          <FaRegHeart
            color="red"
            onClick={() => setFilterFavorite(!filterFavorite)}
          />
        )}
      </div>
      <div className="accordion">
        <FilterAccordion
          title={t("filter.filterSearchResult.filterByName.title", {
            framework: "React",
          })}
        >
          <div className="hotel-name">
            <h5>
              {t("filter.filterSearchResult.filterByName.hotelName", {
                framework: "React",
              })}{" "}
            </h5>
            <input
              type="text"
              placeholder={t(
                "filter.filterSearchResult.filterByName.hotelName",
                { framework: "React" }
              )}
            />
          </div>
        </FilterAccordion>
        <FilterAccordion
          title={t("filter.filterSearchResult.filterByPrice", {
            framework: "React",
          })}
        >
          <div className="night-price">
            {/* <div className="min-cost">
                            <input type="number"
                                min="1"
                                max="99"
                                value={minPrice}
                                onChange={(e) => setMinPrice(e.target.value)}
                            />
                            <div>
                                <h6>ILS</h6>
                            </div>
                        </div> */}
            <div className="min-cost">
              <div className="custom-arrows">
                <BiUpArrow
                  size="14"
                  color="#40404C"
                  onClick={() => setMinPrice(minPrice + 1)}
                />
                <BiDownArrow
                  size="14"
                  color="#40404C"
                  onClick={() => {
                    if (minPrice !== 1) {
                      setMinPrice(minPrice - 1);
                    }
                  }}
                />
              </div>
              <input
                type="number"
                min="1"
                max="99"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
              />
              <div className="currency">ILS</div>
            </div>
            <Slider
              className={classes.root}
              min={1}
              max={100}
              value={[minPrice, maxPrice]}
              onChange={(e, newValue) => updatePricesHandler(newValue)}
            />
            <div className="row max-cost">
              <div className="custom-arrows">
                <BiUpArrow
                  size="14"
                  color="#40404C"
                  onClick={() => {
                    if (maxPrice !== 100) {
                      setMaxPrice(maxPrice + 1);
                    }
                  }}
                />
                <BiDownArrow
                  size="14"
                  color="#40404C"
                  onClick={() => {
                    if (maxPrice !== 1) {
                      setMaxPrice(maxPrice - 1);
                    }
                  }}
                />
              </div>
              <input
                type="number"
                min="1"
                max="100"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
              <div className="currency">ILS</div>
            </div>
          </div>
        </FilterAccordion>
        <FilterAccordion
          title={t("filter.filterSearchResult.filterByLocation.title", {
            framework: "React",
          })}
        >
          <div className="location">
            <h4>
              {t("filter.filterSearchResult.filterByLocation.description", {
                framework: "React",
              })}
            </h4>
            <div className="slide-container">
              <Slider
                className={classes.root}
                min={0}
                max={100}
                value={distanceFromCenter}
                onChange={(e, newValue) => setDistanceFromCenter(newValue)}
                color="secondary"
              />
              <div
                className={
                  ls.get("lang") == "he"
                    ? "location-controller me-2 ms-1"
                    : "location-controller ms-4"
                }
              >
                {ls.get("lang") == "he" ? (
                  <>
                    <div className="distance">
                      <h6>KM</h6>
                    </div>
                    <input
                      type="number"
                      min="1"
                      max="100"
                      value={distanceFromCenter}
                      onChange={(e) => setDistanceFromCenter(e.target.value)}
                    />
                    <div className="custom-arrows">
                      <BiUpArrow
                        size="14"
                        color="#40404C"
                        onClick={() => {
                          if (distanceFromCenter !== 100) {
                            setDistanceFromCenter(distanceFromCenter + 1);
                          }
                        }}
                      />
                      <BiDownArrow
                        size="14"
                        color="#40404C"
                        onClick={() => {
                          if (distanceFromCenter !== 1) {
                            setDistanceFromCenter(distanceFromCenter - 1);
                          }
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      type="number"
                      min="1"
                      max="100"
                      style={{ textAlign: "left" }}
                      value={distanceFromCenter}
                      onChange={(e) => setDistanceFromCenter(e.target.value)}
                    />
                    <div className="custom-arrows" style={{ left: 30 }}>
                      <BiUpArrow
                        size="14"
                        color="#40404C"
                        onClick={() => {
                          if (distanceFromCenter !== 100) {
                            setDistanceFromCenter(distanceFromCenter + 1);
                          }
                        }}
                      />
                      <BiDownArrow
                        size="14"
                        color="#40404C"
                        onClick={() => {
                          if (distanceFromCenter !== 1) {
                            setDistanceFromCenter(distanceFromCenter - 1);
                          }
                        }}
                      />
                    </div>
                    <div className="distance">
                      <h6>KM</h6>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </FilterAccordion>
        <FilterAccordion
          title={t("filter.filterSearchResult.filterByMeal.title", {
            framework: "React",
          })}
        >
          <div className="meals">
            <SwitchItem
              value={filterParametrs.meal.sleepOnly}
              setValue={() => setStateMeal("sleepOnly")}
              title={t("filter.filterSearchResult.filterByMeal.sleepOnly", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.meal.breakfast}
              setValue={() => setStateMeal("breakfast")}
              title={t("filter.filterSearchResult.filterByMeal.breakfast", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.meal.allMeals}
              setValue={() => setStateMeal("allMeals")}
              title={t("filter.filterSearchResult.filterByMeal.allMeals", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.meal.allInclusive}
              setValue={() => setStateMeal("allInclusive")}
              title={t("filter.filterSearchResult.filterByMeal.allInclusive", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.meal.morningEvening}
              setValue={() => setStateMeal("morningEvening")}
              title={t("filter.filterSearchResult.filterByMeal.morning", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
          </div>
        </FilterAccordion>
        <FilterAccordion
          title={t("filter.filterSearchResult.starRating", {
            framework: "React",
          })}
        >
          <div className="star-rating">
            {[5, 4, 3, 2, 1].map((item) => (
              <div
                key={item}
                className={
                  starRating === item ? "star-item active" : "star-item"
                }
                onClick={(e) => setStarRating(item)}
              >
                <h4>{item}</h4>
                {starRating === item ? (
                  <FaStar color="white" size="11" />
                ) : (
                  <FaStar size="11" color="#C59944" />
                )}
              </div>
            ))}
          </div>
        </FilterAccordion>
        <FilterAccordion
          title={t("filter.filterSearchResult.review.title", {
            framework: "React",
          })}
        >
          <div className="review">
            {["+9", "+8", "+7", "+6", "+5"].map((item) => (
              <div
                key={item}
                className={
                  review === item ? "review-item active" : "review-item"
                }
                onClick={(e) => setReview(item)}
              >
                <h4>{item}</h4>
                {item === "+9" && (
                  <h5>
                    {t("filter.filterSearchResult.review.excellent", {
                      framework: "React",
                    })}
                  </h5>
                )}
                {item === "+8" && (
                  <h5>
                    {t("filter.filterSearchResult.review.magnificient", {
                      framework: "React",
                    })}
                  </h5>
                )}
                {item === "+7" && (
                  <h5>
                    {t("filter.filterSearchResult.review.vetyGood", {
                      framework: "React",
                    })}
                  </h5>
                )}
                {item === "+6" && (
                  <h5>
                    {t("filter.filterSearchResult.review.good", {
                      framework: "React",
                    })}
                  </h5>
                )}
                {item === "+5" && (
                  <h5>
                    {t("filter.filterSearchResult.review.prettyGood", {
                      framework: "React",
                    })}
                  </h5>
                )}
              </div>
            ))}
          </div>
        </FilterAccordion>
        <FilterAccordion
          title={t("filter.filterSearchResult.roomFacilities.title", {
            framework: "React",
          })}
        >
          <div className="room-equiment">
            <SwitchItem
              value={filterParametrs.roomFacilities.airConditioning}
              setValue={() => setStateRoomFacilities("airConditioning")}
              title={t(
                "filter.filterSearchResult.roomFacilities.airConditioning",
                { framework: "React" }
              )}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.roomFacilities.privateBath}
              setValue={() => setStateRoomFacilities("privateBath")}
              title={t("filter.filterSearchResult.roomFacilities.privateBath", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.roomFacilities.windowInRoom}
              setValue={() => setStateRoomFacilities("windowInRoom")}
              title={t(
                "filter.filterSearchResult.roomFacilities.windowInRoom",
                { framework: "React" }
              )}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.roomFacilities.kitchenette}
              setValue={() => setStateRoomFacilities("kitchenette")}
              title={t("filter.filterSearchResult.roomFacilities.kitchenette", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.roomFacilities.viewFromWindow}
              setValue={() => setStateRoomFacilities("viewFromWindow")}
              title={t("filter.filterSearchResult.roomFacilities.viewWind", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.roomFacilities.terrace}
              setValue={() => setStateRoomFacilities("terrace")}
              title={t("filter.filterSearchResult.roomFacilities.terrace", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
          </div>
        </FilterAccordion>
        <FilterAccordion
          title={t("filter.filterSearchResult.landmarks.title", {
            framework: "React",
          })}
        >
          <div className="landmark">
            <SwitchItem
              value={filterParametrs.landmarks.temple}
              setValue={() => setStateLandmarks("temple")}
              title={t("filter.filterSearchResult.landmarks.temple", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.landmarks.hall}
              setValue={() => setStateLandmarks("hall")}
              title={t("filter.filterSearchResult.landmarks.hall", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.landmarks.stadium}
              setValue={() => setStateLandmarks("stadium")}
              title={t("filter.filterSearchResult.landmarks.stadium", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
          </div>
        </FilterAccordion>
        <FilterAccordion
          title={t("filter.filterSearchResult.accomodation.title", {
            framework: "React",
          })}
        >
          <div className="visit-type">
            <SwitchItem
              value={filterParametrs.typeAccomodation.hotels}
              setValue={() => setStateTypeAccomodation("hotels")}
              title={t("filter.filterSearchResult.accomodation.hotels", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.typeAccomodation.hostels}
              setValue={() => setStateTypeAccomodation("hostels")}
              title={t("filter.filterSearchResult.accomodation.hostels", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.typeAccomodation.apartaments}
              setValue={() => setStateTypeAccomodation("apartaments")}
              title={t("filter.filterSearchResult.accomodation.apartaments", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.typeAccomodation.guestHouse}
              setValue={() => setStateTypeAccomodation("guestHouse")}
              title={t("filter.filterSearchResult.accomodation.houses", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.typeAccomodation.villas}
              setValue={() => setStateTypeAccomodation("villas")}
              title={t("filter.filterSearchResult.accomodation.villa", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
            <SwitchItem
              value={filterParametrs.typeAccomodation.ruralHosting}
              setValue={() => setStateTypeAccomodation("ruralHosting")}
              title={t("filter.filterSearchResult.accomodation.rural", {
                framework: "React",
              })}
              trueImg={switchOn}
              falseImg={switchOff}
            />
          </div>
        </FilterAccordion>
      </div>
    </div>
  );
}
